var FormService = class FormService {
    constructor(enpointUrl) {
        this.enpointUrl = enpointUrl;
    }

    loadForm(formComponent, success, error) {
        let url = this.enpointUrl + "/formbuilder/" + formComponent.props.formName + "/" + formComponent.props.culture + "/" + formComponent.props.siteName;

        //console.log('fetching data from ' + url);
        return fetch(url, {
            method: 'get',
            mode: "cors",
            headers: {'Content-Type':'application/json'},
        }).then(response => {return response.json()})
            .then(responseJson => {
                success(responseJson, formComponent);
            })
            .catch(err => error(err));
    }

    postForm(formPayload, formComponent, success, error) {
        let url = this.enpointUrl + '/formbuilder';

        //console.log('posting data from ' + url);
        return fetch(url, {
            method: 'post',
            body: formPayload//JSON.stringify(formPayload)
        }).then(response => response.json())
            .then(responseJson => {
                success(responseJson, formComponent);
            })
            .catch(err => error(err, formComponent));
    }
}

export default FormService;