import { handleResponse, handleError } from "./apiUtils";
const baseUrl = "/api/pdsearch";

export function search(searchRequest) {
    const url = baseUrl;
    return fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(searchRequest)
    }).then(handleResponse).catch(handleError);
}

export function getPdDetails(masterCode, searchRequest=null) {
    const url = baseUrl + "/" + masterCode;

    return fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(searchRequest)
    }).then(handleResponse).catch(handleError);
}