
import * as moment from "moment";

export function getListLocationIds(array, property) {
    const arrayList = [];
    for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i][property].length; j++) {
            arrayList.push(array[i][property][j]);
        }
    }

    
    return arrayList; 
}

export function getListIds(array, property) {
    const arrayList = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i][property] !== "") {
            arrayList.push(array[i][property]);
        }
    }
    
    return arrayList;
}

export function getSimpleListIds(array, property) {
    let arrayList = [];
    let finalArrayList = [];

    for (let i = 0; i < array.length; i++) {
        if (array[i][property] !== "") {
            arrayList.push(array[i][property]);
        }
    }

    if (arrayList.length > 0) {
        arrayList = arrayList.sort((a, b) => a - b);
        finalArrayList = arrayList.map(function (e) { return e.toString() });
    }

    return finalArrayList;
}

export function isItemSelected(object, propName, value) {
    const arrayList = [];
    for (let i = 0; i < object.length; i++) {
        arrayList.push(object[i][propName]);
    }

    if (arrayList.includes(value)) {
        return true;
    }
    else { return false }
}

export function getHashList(array) {
    let arrayHash = '';
    for (var i = 0; i < array.length; i++) {
        arrayHash = arrayHash.concat(array[i]);
        if (i + 1 < array.length) {
            arrayHash = arrayHash.concat('|');
        }
    }
    return arrayHash;
}


export function getListFromQueryWithChildren(arrayFromUrl, allArray, propId, propName) {
    let listSubjects = [];
    for (var i = 0; i < arrayFromUrl.length; i++) {
        let code = '';
        for (var j = 0; j < allArray.length; j++) {
            for (var k = 0; k < allArray[j].Children.length; k++) {
                if (arrayFromUrl[i] === allArray[j].Children[k][propName]) {
                    code = allArray[j].Children[k][propId]
                }
            }
            
        }
        listSubjects.push({
            [propId]: code,
            [propName]: arrayFromUrl[i]
        });
    }
    return listSubjects;
}


export function getListFromQuery(arrayFromUrl, allArray, propId, propName) {
    let listSubjects = [];
    if (arrayFromUrl == "Any or All") {
        listSubjects.push({
            [propId]: 'allorany',
            [propName]: 'Any or All'
        });
    }
    else {
        for (var i = 0; i < arrayFromUrl.length; i++) {
            let subjectCode = '';
            for (var j = 0; j < allArray.length; j++) {
                if (arrayFromUrl[i] == allArray[j][propName]) {
                    subjectCode = allArray[j][propId]
                }
            }
            listSubjects.push({
                [propId]: subjectCode,
                [propName]: arrayFromUrl[i]
            });
        }
    }
    return listSubjects;
}

export function getListFromQuerySemLeader(arrayFromUrl, allArray, propId) {
    let listSubjects = [];
    for (var i = 0; i < arrayFromUrl.length; i++) {
        let subjectCode = '';
        for (var j = 0; j < allArray.length; j++) {
            let fullName = allArray[j].FirstName + ' ' + allArray[j].LastName
            if (arrayFromUrl[i] == fullName) {
                subjectCode = allArray[j].LeaderId
            }
        }
        listSubjects.push({
            LeaderId: subjectCode,
            Name: arrayFromUrl[i]
        });
    }
    return listSubjects;
}

			
export function getEmptySearchRequest() {	
// okay the calendar Needs a start and end date.
                    // make sure we have start and end dates
                    // check for null date
                    var startDate2 = new Date();

                    // check for date that isn't first of the month
                    // really, just set day to '1'
                    // but setDate returns seconds so we won't use that
                    startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

                    // lets set the end date as well
                    var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

                    //console.log("search button start date2: " + startDate2);
                    //console.log("search button end date2: " + endDate2);

                    let startDateIso = startDate2 ? moment(startDate2).format() : null;
                    let endDateIso = endDate2 ? moment(endDate2).format() : null;


    const emptySearchRequest = {	
        StartDate: startDateIso,
        EndDate: endDateIso,	
        PageIndex: 0,	
        PageSize: 500,	
        CpabcVenueId: [],	
        CpabcCourseSubcategory: [],	
        DurationHours: [],	
        CpabcCourseDeliveryMethod: [],	
        CpabcCourseProgram: [],	
        EthicsHours: [],	
        EthicsHoursGreaterThan0: null,	
        CpabcCertificateProgram: [],	
        CpabcSeminarLeaderId: [],	
        PassportCredits: [],	
        Keywords: "",	
        Sort: 1	
    };	
    return emptySearchRequest;	
}	
export function getDatesFormat (firstDate, secondDate) {	
    let currentDate = new Date();	
    let currentDateYear = moment(currentDate).format("YYYY");	
    let currentDateMonth = moment(currentDate).format("MMM");	
    let firstDateYear = moment(firstDate).format("YYYY");	
    let firstDateMonth = moment(firstDate).format("MMM");	
    let firstDateFormatFull = moment(firstDate).format("MMM D, YYYY");	
    let firstDateFormatM = moment(firstDate).format("MMM D");	
    let firstDateFormatD = moment(firstDate).format("D");	
    let secondDateYear = moment(secondDate).format("YYYY");	
    let secondDateMonth = moment(secondDate).format("MMM");	
    let secondDateFormatFull = moment(secondDate).format("MMM D, YYYY");	
    let secondDateFormatM = moment(secondDate).format("MMM D");	
    let secondDateFormatD = moment(secondDate).format("D");	
    if (firstDate === null && secondDate === null) {	
        return "";	
    }	
    else if (firstDateFormatFull === secondDateFormatFull) {	
        return firstDateFormatFull;	
    }	
    else if (firstDate != null && secondDate === null) {	
        return firstDateFormatFull;	
    }	
    else if (firstDate != null && secondDate != null && (firstDateYear === secondDateYear) && (firstDateMonth === secondDateMonth)) {	
        return firstDateFormatM + " - " + secondDateFormatD + ", " + firstDateYear;	
    }	
    else if (firstDate != null && secondDate != null && (firstDateYear === secondDateYear) && (firstDateMonth != secondDateMonth)) {	
        return firstDateFormatM + " - " + secondDateFormatFull;	
    }	
    else if (firstDate != null && secondDate != null && (firstDateYear != secondDateYear) && (firstDateMonth === secondDateMonth)) {	
        return firstDateFormatFull + " - " + secondDateFormatFull;	
    }	
    else if (firstDate != null && secondDate != null && (firstDateYear != secondDateYear) && (firstDateMonth != secondDateMonth)) {	
        return firstDateFormatFull + " - " + secondDateFormatFull;	
    }	
}	

