import React from 'react';
import { connect } from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';
import Dotdotdot from 'react-dotdotdot';

const hashStringParse = (string) => {
    let parsed = {};
    if (string != '') {
        string = string.replace("#/", "#");
        string = string.substring(string.indexOf('#') + 1);
        let p1 = string.split('&');
        p1.map(function (value) {
            let params = value.split('=')
            parsed[params[0]] = decodeURIComponent(params[1])
        });
    }
    return parsed;
}

const search = (payload, endpointUrl) => {
    return fetch(endpointUrl, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(res => res.json());
}

class SiteSearch extends React.Component {
    constructor(props) {
        super(props);

        this.categories = props.categories;

        this.apiPayLoad = {
            pageIndex: 0,
            pageSize: props.pageSize || 9,
            keywords: '',
            siteSection: ''
        };

        let state = {
            isNoResults: false,
            isLoading: false,
            items: [],
            facets: [],
            originalFacets: [],
            totalItems: 0,
            originalTotalItems: 0,
            activeFilter: '',
            isSiteSectionSearch: false,
            keywords: hashStringParse(location.hash).q || "",
            canLoadMore: false,
            lastExecuteRequest: false
        };

        this.state = state;
        this.keywordsInput = React.createRef();
        this.apiEndpoint = "/api/sitesearch/";

        this.search = this.search.bind(this);
        this.updateHash = this.updateHash.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.sanitizeHtml = this.sanitizeHtml.bind(this);
        this.getFacetSectionName = this.getFacetSectionName.bind(this);

        return this;
    }

    componentDidMount() {

        if (this.keywordsInput.current != null)
            this.keywordsInput.current.focus();

        if (this.state.keywords.length > 0) {
            this.search();
        }

        $("body").on("site-search", function (e, data) {

            this.setState({ keywords: data.keywords, isSiteSectionSearch: false }, () => {
                this.search();
            });

        }.bind(this));
    }

    search() {
        this.updateHash();

        if (this.state.keywords) {

            this.apiPayLoad.offset = 0;
            this.apiPayLoad.pageIndex = 0;
            this.apiPayLoad.keywords = this.state.keywords;
            this.apiPayLoad.siteSection = this.state.siteSection;

            this.setState({
                isLoading: true,
                items: [],
                facets: []
            }, () => {
                search(this.apiPayLoad, this.apiEndpoint)
                    .then(this.onSearchResolved.bind(this), this.onApiError.bind(this));
            })
        } else {
            this.setState({
                isNoResults: false,
                isLoading: false,
                items: [], // search result items,
                facets: [],
                originalFacets: [],
                totalItems: 0,
                originalTotalItems: 0,
                activeFilter: '',
                isSiteSectionSearch: false,
                keywords: '',
                canLoadMore: false,
                lastExecuteRequest: false
            }); //original state
        }

        return this.state;
    }

    updateHash() {

        let updatedHash = "#";

        if (this.state.keywords.length > 0)
            updatedHash += "q=" + encodeURIComponent(this.state.keywords);

        window.location.href = updatedHash;
    }

    onSearchResolved(data) {
        if (this.state.isSiteSectionSearch) {
            this.setState(prevState => ({
                items: data.items,
                facets: data.facets,
                canLoadMore: data.totalItemsCount > data.items.length,
                isLoading: false,
                totalItems: data.totalItemsCount,
                isNoResults: data.items.length === 0
            }));
        } else {
            this.setState(prevState => ({
                items: data.items,
                facets: data.facets,
                originalFacets: data.facets,
                canLoadMore: data.totalItemsCount > data.items.length,
                isLoading: false,
                totalItems: data.totalItemsCount,
                originalTotalItems: data.totalItemsCount,
                isNoResults: data.items.length === 0
            }));
        }
    }

    loadMore(pageIndex) {
        this.apiPayLoad.pageIndex += 1;
        this.apiPayLoad.offset = this.apiPayLoad.pageIndex * this.apiPayLoad.pageSize;

        this.setState({

            isLoading: true

        }, () => {

            search(this.apiPayLoad, this.apiEndpoint)
                .then(this.onLoadMoreResolved.bind(this), this.onApiError.bind(this));

        });
    }

    onLoadMoreResolved(data) {

        const canLoadMore = data.totalItemsCount > this.state.items.length + data.items.length;

        this.setState(prevState => ({
            items: prevState.items.concat(data.items),
            canLoadMore: canLoadMore,
            isLoading: false
        }));

    }

    sanitizeHtml(html) {
        return { __html: html };
    }

    onKeyPress = (e) => {

        if (e.key === "Enter") {
            this.setState({ isSiteSectionSearch: false });
            this.search();
        }

    }

    onButtonClick = (e) => {
        this.setState({ isSiteSectionSearch: false });
        this.search();
    }

    handleKeywordsChange(event) {
        this.setState({ keywords: event.target.value });
    }

    getFacetSectionName(guid) {
        var facetSection = this.props.SiteSection.find(x => x.Value == guid);

        return facetSection ? facetSection.Title : "";
    }

    onApiError(error) {
        console.log(error);
    }

    render() {
        // no results messages
        const noResultsMessage = (this.state.isNoResults)
            ? <div className="results-wrapper">
                <div className="text-muted small-title">{this.props.dictionary["NoResults"]}</div>
            </div>
            : null;

        const noResultsSuggestions = (this.state.isNoResults)
            ? <div className="no-results-suggestions">
                {this.props.NoResultsSuggestions && <div dangerouslySetInnerHTML={{ __html: this.props.NoResultsSuggestions }}></div>}
            </div>
            : null;

        // total results
        const totalResultsMessage = (this.state.totalItems > 0)
            ? <div key={"results-messages"}>
                <div className="text-muted">
                    {this.state.totalItems} {this.props.dictionary[this.state.totalItems === 1 ? "1ResultFound" : "XResultsFound"]} for "{this.apiPayLoad.keywords}" in {this.state.siteSection ? this.getFacetSectionName(this.state.siteSection) : "all sections"}
                </div>
            </div>
            : null;

        // can execute load more
        const canExecuteLoadMore = this.state.canLoadMore && (this.state.isLoading === false);

        // inner results
        const innerResults = this.state.items.map((item, key) => {

            const imgContainer = item.thumbnail != null && item.thumbnail.length > 0
                ? <div className="img-container d-flex align-items-center">
                    <img src={item.thumbnail} className="img-fluid w-100" alt={item.thumbnailAlt} title={item.title}/>
                </div>
                : null;

            return <div className="item w-100 d-flex" key={item.nodeId.toString()}>
                <div className="item-body w-100">
                    <a className="title" href={item.url}>{item.title}</a>
                    <Dotdotdot clamp={3}>
                        <div className="abstract" dangerouslySetInnerHTML={this.sanitizeHtml(item.description)}></div>
                    </Dotdotdot>
                    <a className="url d-block mt-2 text-muted" href={item.url}>{item.url ? item.url.replace(":443", "") : ""}</a>
                </div>
                {imgContainer}
            </div>
        });

        //facets results
        const facetResults = (this.state.originalFacets || []).map(facet => (

            <li key={facet.value} className={this.state.activeFilter === facet.value ? "active" : ""}>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    this.setState({ keywords: this.state.keywords, siteSection: facet.value, isSiteSectionSearch: true, activeFilter: facet.value }, () => {
                        this.search();
                    });

                }}>{this.getFacetSectionName(facet.value)} ({facet.count})</a>
            </li>
        ));

        // results wrapper
        const resultsWrapper = (this.state.items.length > 0 || this.state.isLoading)
            ? <React.Fragment>
                <InfiniteScroll
                    pageStart={-1}
                    loadMore={this.loadMore}
                    hasMore={canExecuteLoadMore}
                    className={"infinite-scroll"}
                >
                    {innerResults}
                </InfiniteScroll>
                {this.state.isLoading ? (
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : null}
            </React.Fragment>
            : null;

        return (<div className="row">
            <div className="content">
                {this.props.BreadcrumbsHtml && <div dangerouslySetInnerHTML={{ __html: this.props.BreadcrumbsHtml }}></div>}
                <div className="page-section section-one-column no-padding-top no-padding-bottom">
                    <div className="page-section-inner">
                        <div className="row">
                            <div className="col">
                                <h1>{this.props.DocumentName}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-section section-one-column no-padding-top no-padding-bottom">
                    <div className="page-section-inner">
                        <div className="row">
                            <div className="col">
                                <div className="site-search-wrapper">
                                    <div className="filters form-row">
                                        <div className="col">
                                            <div className="input-group mb-2">
                                                <div class="search-icon"><i class="fas fa-search"></i></div>
                                                <input type="text"
                                                       ref={this.keywordsInput}
                                                       onChange={this.handleKeywordsChange.bind(this)}
                                                       onKeyPress={this.onKeyPress.bind(this)}
                                                       value={this.state.keywords}
                                                       className="form-control mr-2"
                                                       placeholder="Search" />
                                                <div className="input-group-append" onClick={this.onButtonClick.bind(this)}>
                                                    <div className="btn btn-primary m-0">Search</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results-message">
                                        {totalResultsMessage}
                                        {noResultsMessage}
                                    </div>

                                    {resultsWrapper}
                                    {noResultsSuggestions}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebar">
                <div className="side-column-container">
                    <div className="side-column-nav">
                        <div className="side-nav-title" data-toggle="collapse" data-target="#sidebarNavList" aria-expanded="false" aria-controls="sidebarNavList">{this.props.SidebarTitle}</div>
                        <div id="sidebarNavList" className="collapse">
                            <ul className="card card-body">
                                <li className={this.state.activeFilter ? "" : "active"}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ keywords: this.state.keywords, siteSection: "", isSiteSectionSearch: true, activeFilter: "" }, () => {
                                            this.search();
                                        });

                                    }}>All sections ({this.state.originalTotalItems})</a>
                                </li>

                                {facetResults}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteSearch);