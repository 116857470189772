import React, { memo } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SampleReduxComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }
    
    render() {
        const { title } = this.props;
        return <h2>SampleReduxComponent {title}</h2>;
    }
}

function mapStateToProps(state) {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {

        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleReduxComponent);