export const SEARCH_PD_SUCCESS = "SEARCH_PD_SUCCESS";
export const GET_DETAILS_PD_SUCCESS = "GET_DETAILS_PD_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const SET_TIME_OUT_LOADING = "SET_TIME_OUT_LOADING";
export const RESET_SEARCH_RESULT_ITEMS = "RESET_SEARCH_RESULT_ITEMS";
export const BEGIN_LOAD_MORE_CALL = "BEGIN_LOAD_MORE_CALL";
export const END_LOAD_MORE_CALL = "END_LOAD_MORE_CALL";

export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_SELECTED_LOCATIONS = "SET_SELECTED_LOCATIONS";
export const SET_SELECTED_SUBJECTS = "SET_SELECTED_SUBJECTS";
export const SET_SELECTED_SEMINAR_LENGTH = "SET_SELECTED_SEMINAR_LENGTH";
export const SET_SELECTED_DELIVERY_FORMATS = "SET_SELECTED_DELIVERY_FORMATS";
export const SET_SELECTED_COURSE_PROGRAMS = "SET_SELECTED_COURSE_PROGRAMS";
export const SET_SELECTED_ETHICS_HOURS = "SET_SELECTED_ETHICS_HOURS";
export const SET_SELECTED_CERTIFICATE_PROGRAMS = "SET_SELECTED_CERTIFICATE_PROGRAMS";
export const SET_SELECTED_LEADERS = "SET_SELECTED_LEADERS";
export const SET_SELECTED_PASSPORT_CREDITS = "SET_SELECTED_PASSPORT_CREDITS";
export const SET_KEYWORDS_SEARCH_VALUE = "SET_KEYWORDS_SEARCH_VALUE";
export const SELECT_COURSE = "SELECT_COURSE";
export const SELECT_BOOKMARK = "SELECT_BOOKMARK";
export const SET_VISIBLE_LIST_BOOKMARKS = "SET_VISIBLE_LIST_BOOKMARKS";
export const SET_SORT_BY_VALUE = "SET_SORT_BY_VALUE";
export const SET_SEARCH_NEW_COURSES = "SET_SEARCH_NEW_COURSES";
export const BEGIN_API_CALL_PD_DETAILS = "BEGIN_API_CALL_PD_DETAILS";
export const RESET_FILTERS_SEARCH = "RESET_FILTERS_SEARCH";
export const SET_ALL_SEARCH_CRITERIA = "SET_ALL_SEARCH_CRITERIA";
export const SET_COURSE_FROM_HASH_VISIBLE = "SET_COURSE_FROM_HASH_VISIBLE";
export const SET_NEW_COURSES_ONLY_VISIBLE = "SET_NEW_COURSES_ONLY_VISIBLE";
export const SET_HASH_ALL_SEARCH_CRITERIA = "SET_HASH_ALL_SEARCH_CRITERIA";
export const SET_IS_LOADING_PD_DETAILS = "SET_IS_LOADING_PD_DETAILS";
export const SET_PD_DETAILS = "SET_PD_DETAILS";
export const SET_COPY_SEARCH_TAG_VISIBLE = "SET_COPY_SEARCH_TAG_VISIBLE";
export const SET_SEARCH_TAG = "SET_SEARCH_TAG";


