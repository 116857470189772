import React from 'react';

export default function SelectedCoursePrograms(props) {
    const { selectedCoursePrograms, onRemoveCourseProg} = props;

    return (
        <>
            {selectedCoursePrograms.length > 0 &&
                <div className="d-flex mr-4 mb-2 courseProgram-filters">
                    <div className="option-select">
                        Seminar Type: &nbsp;
                            {selectedCoursePrograms.length > 0 && selectedCoursePrograms.map((item, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{item.Description}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveCourseProg(item,
                                        "Description",
                                        "CoursePrograms")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
