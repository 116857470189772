import React, { useState, useEffect } from "react";
import CarouselTestimonials from "./CarouselTestiomonials";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import { Responsive } from "../../../utils/Responsive";
import LoadingIndicator from "./LoadingIndicator";
import { getDatesFormat } from "./filterUtils";

export default function PDDetails(props) {
    const pastDatesRef = React.createRef();
    const availableDatesRef = React.createRef();

    const [popoverSeminarLevel, setPopoverSeminarLevel] = useState(false);
    const [pldpWordmark, setPldpWordmark] = useState(false);
    const [popoverBookmark, setPopoverBookmark] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const { selectedCourse, pdDetails, itemsTestimonials, getCourseSubjectDescription, selectedBookmark, onSelectTag, bookmark } = props;
    const { getDeliveryMethod, seminarLevels } = props;
    const { availableButtonLink, cancelledButtonLink, closedButtonLink, fullButtonLink, pastButtonLink } = props;
    const { availableButtonLabel, cancelledButtonLabel, closedButtonLabel, fullButtonLabel, pastButtonLabel } = props;
    const { leaders, tags, locations, coursePrograms, isLoadingPdDetails } = props;

    useEffect(
        () => {
            setExpanded(false);
        },
        [props.selectedCourse],
    );

    const seeAvailableDates = () => {
        if (pdDetails) {
            if (pdDetails.upcomingCourses) {
                (pdDetails.upcomingCourses.length > 0)
                    ? availableDatesRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
                    : pastDatesRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }

    const getSrcImage = (parameter) => {

        return "/cpabc-pd/media/cpabc-pd/icons/search/course-" + parameter.toLowerCase() + ".svg";
    }

    const togglePldpWordmark = () => {
        setPldpWordmark(!pldpWordmark);
    }

    const onPldpWordmarkHover = (hoverStatus) => {
        setPldpWordmark(hoverStatus);
    }

    const togglePSL = () => {
        setPopoverSeminarLevel(!popoverSeminarLevel);
    }

    const onHoverPSL = () => {
        setPopoverSeminarLevel(true);
    }

    const onHoverLeavePSL = () => {
        setPopoverSeminarLevel(false);
    }

    const togglePB = () => {
        setPopoverBookmark(!popoverBookmark);
    }

    const onHoverPB = () => {
        setPopoverBookmark(true);
    }

    const onHoverLeavePB = () => {
        setPopoverBookmark(false);
    }

    const onClickLessMore = () => {
        setExpanded(!expanded);
    };

    const arrayIncludesInObj = (arr, key, valueToCheck) => {
        return arr.some(value => value[key] === valueToCheck);
    }

    const getPopoverBody = (parameter) => {
        switch (parameter) {
            case "introductory":
                return seminarLevels[0].ToolTip;
            case "intermediate":
                return seminarLevels[1].ToolTip;
            case "advanced":
                return seminarLevels[2].ToolTip;
            case "general":
                return seminarLevels[3].ToolTip;
            case "intermediate to advanced":
                return seminarLevels[4].ToolTip;
            case "introductory to intermediate":
                return seminarLevels[5].ToolTip;

            default:
                return "";
        }
    }

    const getPathRegistrationButton = (parameter, cpabcMeeting) => {
        var link = "";
        switch (parameter) {
            case "available":
                link = availableButtonLink;
                break;
            case "cancelled":
                link = cancelledButtonLink;
                break;
            case "closed":
                link = closedButtonLink;
                break;
            case "full":
                link = fullButtonLink;
                break;
            case "past":
                link = pastButtonLink;
                break;
            default:
                link = "";
                break;
        }

        if (link) {
            return link + cpabcMeeting;
        }
        else {
            return "javascript:void(0);";
        }
    }

    const getLabelRegistrationButton = (parameter) => {
        switch (parameter) {
            case "available":
                return availableButtonLabel;
            case "cancelled":
                return cancelledButtonLabel;
            case "closed":
                return closedButtonLabel;
            case "full":
                return fullButtonLabel;
            case "past":
                return pastButtonLabel;

            default:
                return "";
        }
    }

    const getNameSpeaker = (parameter) => {
        for (let i = 0; i < leaders.length; i++) {
            if (leaders[i].LeaderId === parameter) {
                return leaders[i].FirstName + " " + leaders[i].LastName;
            }
        }
        return "Unknown";
    }

    const getDesignationsSpeaker = (parameter) => {
        for (let i = 0; i < leaders.length; i++) {
            if (leaders[i].LeaderId === parameter) {
                return leaders[i].Designations;
            }
        }
        return "Unknown";
    }

    const getBiographySpeaker = (parameter) => {
        for (let i = 0; i < leaders.length; i++) {
            if (leaders[i].LeaderId === parameter) {
                return leaders[i].Biography;
            }
        }
        return "Unknown";
    }

    const getDescriptionTag = (parameter) => {

        for (let i = 0; i < tags.length; i++) {
            if (tags[i].CpaBcCourseTagCd === parameter) {
                return tags[i].Description;
            }
        }
        return "Unknown";
    }

    const getCity = (parameter) => {

        for (let i = 0; i < locations.length; i++) {
            if (locations[i].LocationId === parameter) {
                return locations[i].City;
            }
        }
        return null;
    }

    const getLocationObject = (parameter) => {
        for (let i = 0; i < locations.length; i++) {
            if (locations[i].LocationId === parameter) {
                console.log(locations[i]);
                return locations[i];
            }
        }
        return null;
    }

    const getLocation = (parameter) => {
        for (let i = 0; i < locations.length; i++) {
            if (locations[i].LocationId === parameter) {
                return locations[i].Name;
            }
        }
        return null;
    }

    const getLocationFullAddress = (parameter) => {

        for (let i = 0; i < locations.length; i++) {
            if (locations[i].LocationId === parameter) {
                return locations[i].FullAddress;
            }
        }
        return null;
    }

    const getLocationGeneralNotes = (parameter) => {

        for (let i = 0; i < locations.length; i++) {
            if (locations[i].LocationId === parameter) {
                return locations[i].VenueGeneralNotes;
            }
        }
        return null;
    }

    const getSeminarType = (parameter) => {
        for (let i = 0; i < coursePrograms.length; i++) {
            if (coursePrograms[i].Code === parameter) {
                return coursePrograms[i].Description;
            }
        }
        return null;
    }

    let beginDate = moment(selectedCourse.beginDate).format("MMM DD, YYYY");

    return (
        <div className="card course">
            <div className="card-body">
                {navigator.cookieEnabled &&
                    <React.Fragment>
                        {!props.isMobile &&
                            <div className="course-title">
                                <div className={"d-flex flex-column flex-sm-row align-items-center side-icon-wrapper" + " bg-color-" + selectedCourse.cpabcCourseSubject} >
                                    <div className="icon-wrapper">
                                        {selectedCourse.cpabcCourseSubject &&
                                            <img alt={getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)} src={getSrcImage(selectedCourse.cpabcCourseSubject)}>
                                            </img>
                                        }
                                    </div>
                                    {selectedCourse.cpabcCourseSubject &&
                                        <div className="small-title">
                                            {getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}
                                        </div>
                                    }
                                    {selectedCourse.passportUnit !== 0 && selectedCourse.beginDate >= '2020-09-01' &&
                                        <div className="side-icon-wrapper d-flex align-items-center align-self-md-center hours-wrapper label-bottom">
                                            <div className="nr-days ng-binding">
                                                {selectedCourse.passportUnit}
                                            </div>
                                            <div className="hours-wrapper d-flex flex-column">
                                                <span className="lbl-cpd ">Passport</span>
                                                <span className="lbl-hours">Credits</span>
                                            </div>
                                        </div>
                                    }
                                    {selectedCourse.cpdHours !== 0 && selectedCourse.beginDate < '2020-09-01' &&
                                        <div className="side-icon-wrapper d-flex align-items-center align-self-md-center hours-wrapper label-bottom">
                                            <div className="nr-days ng-binding">
                                                {selectedCourse.cpdHours}
                                            </div>
                                            <div className="hours-wrapper d-flex flex-column">
                                                <span className="lbl-cpd ">CPD</span>
                                                <span className="lbl-hours">Hours</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="flex-fill">
                                    <div className="main-container-wrapper d-flex justify-content-between flex-column">
                                        <div className="flex-title-wrapper">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div className="title-wrapper">
                                                        {selectedCourse.title &&
                                                            <h1>{selectedCourse.title}</h1>
                                                        }
                                                    </div>
                                                    <div className="former-title-wrapper">
                                                        {selectedCourse.formerTitle &&
                                                            <h6>Former Title: {selectedCourse.formerTitle}</h6>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <button type="button" className="btn-add-favorites d-none" title="Bookmark"></button>
                                                    <div className="is-bookmarked position-relative true" ng-show="pdCourse">
                                                        <Button
                                                            id="Popover2"
                                                            onClick={() => { bookmark(); }}
                                                            title="Bookmark"
                                                            type="button"
                                                            className={arrayIncludesInObj(selectedBookmark, "cpabcMeeting", selectedCourse.cpabcMeeting) ? "btn btn-select" : "btn btn-add-favorites"}
                                                            onMouseEnter={onHoverPB}
                                                            onMouseLeave={onHoverLeavePB}></Button>
                                                        <Popover
                                                            placement="top"
                                                            isOpen={popoverBookmark}
                                                            target="Popover2"
                                                            toggle={togglePB}
                                                        >
                                                            <PopoverHeader>
                                                                <div className="pop-bookmark-header">Bookmark</div>
                                                            </PopoverHeader>
                                                            <PopoverBody>
                                                                <div className="pop-bookmark-body">The heart icon allows you to bookmark seminars of interest for future referencing. It does not add the seminar to your shopping cart.</div>
                                                            </PopoverBody>
                                                        </Popover>
                                                        <span className="bookmark">Bookmark</span>
                                                        <ToastContainer
                                                            position="bottom-right"
                                                            autoClose={4000}
                                                            hideProgressBar
                                                            newestOnTop={false}
                                                            closeOnClick
                                                            rtl={false}
                                                            pauseOnFocusLoss
                                                            draggable
                                                            pauseOnHover
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row row-detail">
                                            {selectedCourse.cpabcCourseDeliveryMethod &&
                                                <div className="col">
                                                    <span className="lbl-uppercase">Delivery Method</span>
                                                    <div className="status-pill online"><span>{getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span></div>
                                                </div>
                                            }
                                            {selectedCourse.cpabcCourseProgram &&
                                                <div className="col">
                                                    <span className="lbl-uppercase">Seminar Type</span>
                                                    <div className="lbl-lg">{getSeminarType(selectedCourse.cpabcCourseProgram)}</div>
                                                </div>
                                            }
                                            {selectedCourse.required && selectedCourse.requiredCaption && <div className="col">
                                                <React.Fragment>
                                                    <span className="lbl-uppercase">Required</span>
                                                    <div className="lbl-lg">{selectedCourse.requiredCaption}</div>
                                                </React.Fragment>
                                            </div>
                                            }
                                            <div className="col">
                                                {selectedCourse.specialAttribute &&
                                                    <React.Fragment>
                                                        <span className="lbl-uppercase">Material Format</span>
                                                        <div className="lbl-lg">{selectedCourse.specialAttribute}</div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                            {selectedCourse.newCourse && <div className="col">
                                                <React.Fragment>
                                                    <span className="lbl-uppercase">New Seminar</span>
                                                    <div className="lbl-lg text-green"><i class="fas fa-check-circle"></i></div>
                                                </React.Fragment>
                                            </div>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>}
                        {props.isMobile &&
                            <div key={selectedCourse.cpabcMeeting} className={"card search-pd" + " left-margin-color-" + (selectedCourse.cpabcCourseSubject ? selectedCourse.cpabcCourseSubject.toLowerCase() : "")} id={selectedCourse.cpabcMeeting}>
                                <div className="card-body">
                                    <div className="d-flex flex-row-reverse justify-content-between">
                                        {true &&
                                            <div className="mt-2">
                                                <button type="button" className="btn-add-favorites d-none" title="Bookmark"></button>
                                                <div className="is-bookmarked position-relative true" ng-show="pdCourse">
                                                    <Button
                                                        id="Popover2"
                                                        onClick={() => { bookmark(); }}
                                                        title="Bookmark"
                                                        type="button"
                                                        className={arrayIncludesInObj(selectedBookmark, "cpabcMeeting", selectedCourse.cpabcMeeting) ? "btn btn-select" : "btn btn-add-favorites"}
                                                        onMouseEnter={onHoverPB}
                                                        onMouseLeave={onHoverLeavePB}></Button>
                                                    <Popover
                                                        placement="top"
                                                        isOpen={popoverBookmark}
                                                        target="Popover2"
                                                        toggle={togglePB}
                                                    >
                                                        <PopoverHeader>
                                                            <div className="pop-bookmark-header">Bookmark</div>
                                                        </PopoverHeader>
                                                        <PopoverBody>
                                                            <div className="pop-bookmark-body">The heart icon allows you to bookmark seminars of interest for future referencing. It does not add the seminar to your shopping cart.</div>
                                                        </PopoverBody>
                                                    </Popover>
                                                    <span className="bookmark">Bookmark</span>
                                                    <ToastContainer
                                                        position="bottom-right"
                                                        autoClose={4000}
                                                        hideProgressBar
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {selectedCourse.cpabcCourseSubject &&
                                                <div className="course-subject text-grey">{getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}</div>
                                            }
                                            {selectedCourse.title &&
                                                <div className="course-title">{selectedCourse.title}</div>
                                            }
                                            {selectedCourse.formerTitle &&
                                                <div className="former-title">Former Title: {selectedCourse.formerTitle}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        {selectedCourse.cpdHours &&
                                            <div className="col-5">
                                                <span className="label-number">{selectedCourse.cpdHours}</span>
                                                <span className="medium"> CPD </span>
                                                <span className="small">Hours</span>
                                            </div>
                                        }
                                        {selectedCourse.beginDate >= '2020-09-01' &&
                                            <div className="col-7">
                                                {selectedCourse.passportUnit == 0 &&
                                                    <span className="label-black">N/A</span>
                                                }
                                                {selectedCourse.passportUnit != 0 &&
                                                    <>
                                                        <span className="label-number">{selectedCourse.passportUnit}</span>
                                                        <span className="text-grey medium"> Passport </span>
                                                        <span className="small">Credits</span>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                        <div className="d-flex cell mr-3">
                                            <div className="date">
                                                {beginDate &&
                                                    <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>
                                                }
                                            </div>
                                            <div className="time">
                                                {selectedCourse.beginTime && selectedCourse.endTime &&
                                                    <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                        <div className="delivery-method">
                                            {selectedCourse.cpabcCourseDeliveryMethod &&
                                                <span className="label-black">{getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>
                                            }
                                        </div>
                                        <div className="course-status">
                                            <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="course-overview">
                            <div className={"container-fluid px-0" + (expanded ? " more" : " less")}>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <div className=" layout-two-columns d-lg-flex">
                                            <div className="main-column-wrapper">
                                                <div className="section-buttons p-0 d-block d-sm-none mb-4">
                                                    <button type="button" className="btn btn-md btn-primary" onClick={seeAvailableDates}>See Available Dates <i className="fas fa-arrow-down ml-4"></i></button>
                                                </div>
                                                {selectedCourse.prerequisites &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Prerequisites</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisites }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.overview && !selectedCourse.overview == '' &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Overview</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.overview }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.courseDescription &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Course Description</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.courseDescription }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.attendees &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Applicable for</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.attendees }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.learningObjectives &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Learning Objectives</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.learningObjectives }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.content &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Content</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.content }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {selectedCourse.seminarMaterialFormat &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Improving Your Virtual Learning Experience</h2>
                                                        <div className="mb-4">
                                                            <div dangerouslySetInnerHTML={{ __html: selectedCourse.seminarMaterialFormat }} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {itemsTestimonials.length > 0 &&
                                                    <React.Fragment>
                                                        <h2 class="h4 text-dark">Testimonials from Past Attendees</h2>
                                                        <div className="mb-4">
                                                            <CarouselTestimonials itemsTestimonials={itemsTestimonials} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>

                                            <div className={"side-column-wrapper" + (props.pldpImage && (selectedCourse.cpabcCourseOwner == "PLDP" || selectedCourse.cpabcCourseOwner == "PLDPB" || selectedCourse.cpabcCourseOwner == "BC") ? " pt-0" : "")}>
                                                <div className="side-table">
                                                    {(selectedCourse.cpabcCourseOwner == "PLDP" || selectedCourse.cpabcCourseOwner == "PLDPB" || selectedCourse.cpabcCourseOwner == "BC") && 
                                                        props.pldpImage && <div className="row mb-2">
                                                        <div className="col-12">
                                                            <div className="html-tooltip" id="popupPldpHoverText">
                                                                <div
                                                                    className="wordmark-wrapper"
                                                                    onMouseEnter={() => { onPldpWordmarkHover(true); }}
                                                                    onMouseLeave={() => { onPldpWordmarkHover(false) }}>
                                                                    <img
                                                                        id="popupPldpHoverTextImage"
                                                                        src={props.pldpImage}
                                                                        className="img-fluid"
                                                                        alt="PLDP Wordmark"
                                                                        />
                                                                </div>

                                                                <Popover
                                                                    placement="top"
                                                                    isOpen={pldpWordmark}
                                                                    target="popupPldpHoverTextImage"
                                                                    toggle={togglePldpWordmark}
                                                                >
                                                                    <PopoverBody>
                                                                        <div dangerouslySetInnerHTML={{ __html: props.pldpHoverText }} />
                                                                    </PopoverBody>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {selectedCourse.cpdHours &&
                                                        <div className="row mb-2">
                                                            <div className="col-8"><span className="label-black">Eligible CPD Hours</span></div>
                                                            <div className="col-4"><span className="text-gray">{selectedCourse.cpdHours}</span></div>
                                                        </div>
                                                    }
                                                    <div className="row mb-2">
                                                        <div className="col-8"><span className="label-black">Eligible Ethics Hours</span></div>
                                                        <div className="col-4"><span className="text-gray">{selectedCourse.ethicHours}</span></div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-8"><span className="label-black">Eligible AML Hours</span></div>
                                                        <div className="col-4"><span className="text-gray">{selectedCourse.amlHours ? selectedCourse.amlHours : "0"}</span></div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-8"><span className="label-black">Passport</span></div>
                                                        <div className="col-4">
                                                            {selectedCourse.passportValid &&
                                                                <div className="text-right text-green">
                                                                    <i className="fas fa-check-circle"></i>
                                                                </div>
                                                            }
                                                            {!selectedCourse.passportValid &&
                                                                <div className="text-right text-grey">
                                                                    <span className="text-gray">Invalid</span>
                                                                </div>

                                                            }
                                                        </div>
                                                    </div>
                                                    {selectedCourse.passportUnit != 0 && selectedCourse.beginDate >= '2020-09-01' &&
                                                        <div className="row mb-2">
                                                            <div className="col-8"><span className="label-black">Passport Credits</span></div>
                                                            <div className="col-4"><span className="text-gray">{selectedCourse.passportUnit}</span></div>
                                                        </div>
                                                    }
                                                    {selectedCourse.passportUnit === 0 &&
                                                        <div className="row mb-2">
                                                            <div className="col-8"><span className="label-black">Passport Credits</span></div>
                                                            <div className="col-4"><span className="text-gray">N/A</span></div>
                                                        </div>
                                                    }
                                                    {selectedCourse.cpabcCourseLevelTooltip &&
                                                        <div className="row mb-2 seminar-level-popup">
                                                            <div className="col-7"><span className="label-black">Seminar Level</span></div>
                                                            <div className="col-5">
                                                                <div className="tooltip-wrapper">
                                                                    {selectedCourse.cpabcCourseLevelTooltip &&
                                                                        <div className="html-tooltip" id="popuptextOne">
                                                                            <a>

                                                                                <Button
                                                                                    id="Popover1"
                                                                                    type="button"
                                                                                    onMouseEnter={onHoverPSL}
                                                                                    onMouseLeave={onHoverLeavePSL}
                                                                                >
                                                                                    {selectedCourse.cpabcCourseLevelTooltip}

                                                                                </Button>
                                                                            </a>
                                                                            <Popover
                                                                                placement="top"
                                                                                isOpen={popoverSeminarLevel}
                                                                                target="Popover1"
                                                                                toggle={togglePSL}
                                                                            >
                                                                                <PopoverHeader>{selectedCourse.cpabcCourseLevelTooltip}</PopoverHeader>
                                                                                <PopoverBody>
                                                                                    <div dangerouslySetInnerHTML={{ __html: getPopoverBody(selectedCourse.cpabcCourseLevelTooltip.toLowerCase()) }} />
                                                                                </PopoverBody>
                                                                            </Popover>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                                {((selectedCourse.earlyBirdPrice && selectedCourse.earlyBirdPrice > 0)
                                                    || (selectedCourse.memberPrice && selectedCourse.memberPrice > 0)
                                                    || (selectedCourse.nonMemberPrice && selectedCourse.nonMemberPrice > 0)
                                                    || (selectedCourse.studentPrice && selectedCourse.studentPrice > 0)) &&
                                                    <div className="side-table mt-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h2 className="label-pricing">Pricing</h2>
                                                            </div>
                                                        </div>

                                                        {selectedCourse.earlyBirdPrice && selectedCourse.earlyBirdPrice > 0 &&
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="price">
                                                                        <span className="currency">$</span>
                                                                        <span className="price-number">{selectedCourse.earlyBirdPrice}</span>
                                                                        <span className="price-label pl-3">Early Bird Pricing</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {selectedCourse.memberPrice && selectedCourse.memberPrice > 0 &&
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="price">
                                                                        <span className="currency">$</span>
                                                                        <span className="price-number">{selectedCourse.memberPrice}</span>
                                                                        <span className="price-label pl-3">Member Pricing</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {selectedCourse.nonMemberPrice && selectedCourse.nonMemberPrice > 0 &&
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="price">

                                                                        <span className="currency">$</span>
                                                                        <span className="price-number">{selectedCourse.nonMemberPrice}</span>
                                                                        <span className="price-label pl-3">Non-Member Pricing</span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {selectedCourse.studentPrice && selectedCourse.studentPrice > 0 &&
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="price">
                                                                        <span className="currency">$</span>
                                                                        <span className="price-number">{selectedCourse.studentPrice}</span>
                                                                        <span className="price-label pl-3">Student Pricing</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                }

                                                <div className="side-table section-buttons d-none d-sm-block">
                                                    <button type="button" className="btn btn-md btn-primary" onClick={seeAvailableDates}>See Available Dates <i className="fas fa-arrow-down ml-4"></i></button>
                                                </div>
                                                {
                                                    selectedCourse.specialNotes &&
                                                    <div className="expandable-read-more read-more text-right">
                                                        <div id="demo" className="collapse info-details text-left show">
                                                            <div className="body-content">
                                                                <div className="title">
                                                                    Special Notes
                                                                </div>
                                                            </div>
                                                            {selectedCourse.specialNotes}
                                                        </div>
                                                    </div>
                                                }
                                                <div>
                                                    {isLoadingPdDetails &&
                                                        <div className="mt-4">
                                                            <LoadingIndicator />
                                                        </div>
                                                    }
                                                    {pdDetails && selectedCourse && (selectedCourse.masterCode === pdDetails.currentCourse.masterCode) && pdDetails.currentCourse.tags.length > 0 && !isLoadingPdDetails &&
                                                        <div className="side-table course-tags">
                                                            <div id="demo" className="course-tags-text">
                                                                <div className="body-content">
                                                                    <div className="title pb-2">
                                                                        <span class="label-black">
                                                                            Interested in similar content?
                                                                        </span>
                                                                    </div>
                                                                    <div className="tags">
                                                                        <ul>
                                                                            {pdDetails.currentCourse.tags
                                                                                .map((tag) => {
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            <li>
                                                                                                <a className="see-all" href="javascript:void(0);" onClick={onSelectTag(tag)}>{getDescriptionTag(tag)}</a>
                                                                                            </li>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"view-more" + (expanded ? " more" : " less")}>
                            <button onClick={onClickLessMore}><span>{!expanded ? "View More" : "View Less"}</span></button>
                        </div>

                        {pdDetails && selectedCourse && (selectedCourse.masterCode === pdDetails.currentCourse.masterCode) && pdDetails.upcomingCourses.length > 0 && !isLoadingPdDetails &&
                            <div ref={availableDatesRef} className="table-grid-container mt-4 availableDates" id="availableDates">
                                <div className="flex-fill header bg-blue">
                                    <div className="wrapper d-flex flex-sm-row align-content-between flex-wrap">
                                        <div className="label-glyph mr-auto title">
                                            <h3 id="registration">Registration</h3>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex-fill register-details">
                                    <div className="main-container-wrapper d-flex justify-content-between flex-column">

                                        <div className="row row-detail">
                                            <table className="table data-grid">
                                                <tbody>
                                                    {pdDetails.upcomingCourses
                                                        .map((item) => {
                                                            const location = getLocationObject(item.cpabcVenueId);
                                                            return (
                                                                <tr id={"registration-row-" + item.cpabcMeeting}>

                                                                    {item.cpabcCourseDeliveryMethod === "OD" &&
                                                                        <React.Fragment>
                                                                            <td colspan="4">
                                                                                <span className="lbl-uppercase">Date</span>
                                                                                {item.cpabcCourseProgram === "FREE" &&
                                                                                    <div className="lbl-lg">Available on-demand. 60-day access from purchase date.</div>
                                                                                }
                                                                                {item.cpabcCourseProgram !== "FREE" &&
                                                                                    <div className="lbl-lg">Available on-demand. 180-day access from purchase date.</div>
                                                                                }
                                                                            </td>
                                                                            {item.courseStatus &&
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        <span className="lbl-uppercase">Status</span>
                                                                                        <div className="lbl-lg"><span id={"label-status-" + item.cpabcMeeting} className={"label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span></div>
                                                                                        <span className="seats"></span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="lbl-lg"><a id={"register-button-" + item.cpabcMeeting} className={"btn btn-md btn-primary " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")} href={getPathRegistrationButton(item.courseStatus.toLowerCase(), item.cpabcMeeting)} target="_blank">{getLabelRegistrationButton(item.courseStatus.toLowerCase())}</a></div>
                                                                                        <span className="code">Seminar Code {item.cpabcMeeting}</span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    {item.cpabcCourseDeliveryMethod !== "OD" &&
                                                                        <React.Fragment>
                                                                            <td className="date-cell">
                                                                                <span className="lbl-uppercase">Date</span>
                                                                                <div className="lbl-lg">{getDatesFormat(item.beginDate, item.endDate)}</div>

                                                                                <a className="seminar-leaders"
                                                                                    data-toggle="collapse"
                                                                                    href={"#seminarLeaders-meeting-" + item.cpabcMeeting}
                                                                                    aria-expanded="false" aria-controls="seminarLeaders">Show Seminar Leaders<i className="cpabc-glyph-arrows-down"></i>
                                                                                </a>
                                                                                <div className="collapse" id={"seminarLeaders-meeting-" + item.cpabcMeeting}>
                                                                                    <div className="leaders">
                                                                                        {item.speakerIds && item.speakerIds.length > 0 && item.speakerIds
                                                                                            .map((speaker) => {
                                                                                                return (
                                                                                                    <React.Fragment>
                                                                                                        <a className="speaker-name" data-toggle="modal" href={"#speaker-details" + speaker + item.cpabcMeeting} aria-controls={"speaker-details" + speaker + item.cpabcMeeting}>{getNameSpeaker(speaker)}</a>

                                                                                                        <div className="modal fade" id={"speaker-details" + speaker + item.cpabcMeeting} tabindex="-1" role="dialog" aria-labelledby={"speaker-details-title-" + item.cpabcMeeting} aria-hidden="true">
                                                                                                            <div className="modal-dialog" role="document">
                                                                                                                <div className="modal-content">
                                                                                                                    <div className="modal-header">
                                                                                                                        <h4 className="modal-title" id={"speaker-details-title" + item.cpabcMeeting}>Seminar Leader</h4>
                                                                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                                                            <span aria-hidden="true">Close</span>
                                                                                                                        </button>
                                                                                                                    </div>

                                                                                                                    <div className="modal-body">
                                                                                                                        <div className="title">{getNameSpeaker(speaker)}</div>
                                                                                                                        <div className="designations">{getDesignationsSpeaker(speaker)}</div>
                                                                                                                        <p>{getBiographySpeaker(speaker)}</p>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="time-cell">
                                                                                <span className="lbl-uppercase">Time</span>
                                                                                <div className="lbl-lg">{item.beginTime} - {item.endTime}</div>
                                                                            </td>
                                                                            {location && <td>
                                                                                <span className="lbl-uppercase">Location</span>
                                                                                <div className="lbl-lg">{location.Name}</div>
                                                                                <a className="venue-details" data-toggle="modal" href={"#venue-details" + item.cpabcMeeting} aria-controls={"venue-details" + item.cpabcMeeting}>Venue Details</a>
                                                                                <div className="modal fade" id={"venue-details" + item.cpabcMeeting} tabindex="-1" role="dialog" aria-labelledby={"venue-details-title-" + item.cpabcMeeting} aria-hidden="true">
                                                                                    <div className="modal-dialog" role="document">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header">
                                                                                                <h4 className="modal-title" id={"venue-details-title" + item.cpabcMeeting}>Venue Details</h4>
                                                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                                    <span aria-hidden="true">Close </span>
                                                                                                </button>
                                                                                            </div>

                                                                                            <div className="modal-body">

                                                                                                <div className="title">Address</div>
                                                                                                {item.cpabcCourseDeliveryMethod === "IP" &&
                                                                                                    <React.Fragment>
                                                                                                        <p>{location.FullAddress}</p>
                                                                                                        {location.VenueGeneralNotes && <React.Fragment>
                                                                                                            <div className="title">Additional Information</div>
                                                                                                            <p>{location.VenueGeneralNotes}</p>
                                                                                                        </React.Fragment>}
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                {item.cpabcCourseDeliveryMethod !== "IP" &&
                                                                                                    <React.Fragment>
                                                                                                        <p>{location.Name}</p>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>}
                                                                            <td>
                                                                                {item.cpabcCourseDeliveryMethod === 'IP' &&
                                                                                    <React.Fragment>
                                                                                        <span className="lbl-uppercase">City</span>
                                                                                        <div className="lbl-lg">{getCity(item.cpabcVenueId)}</div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <span className="lbl-uppercase">Status</span>
                                                                                <div className="lbl-lg"><span className={"label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span></div>
                                                                                <span className="seats"></span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="lbl-lg"><a className={"btn btn-md btn-primary " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")} href={getPathRegistrationButton(item.courseStatus.toLowerCase(), item.cpabcMeeting)} target="_blank">{getLabelRegistrationButton(item.courseStatus.toLowerCase())}</a></div>
                                                                                <span className="code">Seminar Code {item.cpabcMeeting}</span>
                                                                            </td>
                                                                        </React.Fragment>
                                                                    }
                                                                </tr>
                                                            );
                                                        })
                                                    }

                                                    <tr className="toggle-more">
                                                        <td colspan="3" className="pb-0"></td>
                                                        <td colspan="3" className="pb-0"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        }

                        {isLoadingPdDetails &&
                            <div className="mt-4">
                                <LoadingIndicator />
                            </div>
                        }

                        {pdDetails && selectedCourse && (selectedCourse.masterCode === pdDetails.currentCourse.masterCode) && pdDetails.pastCourses.length > 0 && !isLoadingPdDetails &&
                            <div ref={pastDatesRef} className="table-grid-container mt-4 grid-disabled" id="pastdates">
                                <div className="flex-fill header bg-blue">
                                    <div className="wrapper d-flex flex-sm-row align-content-between flex-wrap">
                                        <div className="label-glyph mr-auto title">
                                            <h3 id="registration">Past Dates</h3>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex-fill register-details">
                                    <div className="main-container-wrapper d-flex justify-content-between flex-column">

                                        <div className="row row-detail">
                                            <table className="table data-grid">
                                                <tbody>
                                                    {pdDetails.pastCourses
                                                        .map((item) => {
                                                            let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                            return (
                                                                <tr id={"pastdates-row-" + item.cpabcMeeting}>

                                                                    {item.cpabcCourseDeliveryMethod === "OD" &&
                                                                        <React.Fragment>
                                                                            <td colspan="4">
                                                                                <span className="lbl-uppercase">Date</span>
                                                                                {item.cpabcCourseProgram === "FREE" &&
                                                                                    <div className="lbl-lg">Available on-demand. 60-day access from purchase date.</div>
                                                                                }
                                                                                {item.cpabcCourseProgram !== "FREE" &&
                                                                                    <div className="lbl-lg">Available on-demand. 180-day access from purchase date.</div>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <span className="lbl-uppercase">Status</span>
                                                                                <div className="lbl-lg"><span className="label-status available">{item.courseStatus}</span></div>
                                                                                <span className="seats"></span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="lbl-lg">
                                                                                    <a className="btn btn-md btn-primary"
                                                                                        href={getPathRegistrationButton(item.courseStatus.toLowerCase(), item.cpabcMeeting)}
                                                                                        target="_blank">Register Now</a>
                                                                                </div>
                                                                                <span className="code">Seminar Code {item.cpabcMeeting}</span>
                                                                            </td>
                                                                        </React.Fragment>

                                                                    }

                                                                    {item.cpabcCourseDeliveryMethod !== "OD" &&
                                                                        <React.Fragment>

                                                                            <td className="date-cell">
                                                                                <span className="lbl-uppercase">Date</span>
                                                                                <div className="lbl-lg">{getDatesFormat(item.beginDate, item.endDate)}</div>
                                                                            </td>
                                                                            <td className="time-cell">
                                                                                <span className="lbl-uppercase">Time</span>
                                                                                <div className="lbl-lg">{item.beginTime} - {item.endTime}</div>
                                                                            </td>
                                                                            <td>
                                                                                <span className="lbl-uppercase">Location</span>
                                                                                <div className="lbl-lg">{getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</div>
                                                                            </td>
                                                                            <td>
                                                                                {item.cpabcCourseDeliveryMethod === 'IP' &&
                                                                                    <React.Fragment>
                                                                                        <span className="lbl-uppercase">City</span>
                                                                                        <div className="lbl-lg">{getCity(item.cpabcVenueId)}</div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                            </td>
                                                                            <td>
                                                                                <div className="lbl-lg"><a className="btn btn-md btn-primary" href="#" target="_blank">Register Now</a></div>
                                                                                <span className="code">Seminar Code {item.cpabcMeeting}</span>

                                                                            </td>
                                                                        </React.Fragment>

                                                                    }

                                                                </tr>

                                                            );
                                                        })
                                                    }

                                                    <tr className="toggle-more">
                                                        <td colspan="3" className="pb-0"></td>
                                                        <td colspan="3" className="pb-0"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }
                {!navigator.cookieEnabled &&
                    <h4>Please enable cookies, clear your browser cache and reload this page.</h4>
                }
            </div>
        </div>
    );
}