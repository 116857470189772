import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Col, Button  } from 'reactstrap';

class SubmitButtonComponent extends Component {
    render() {
        return <FormGroup row>
            <Col>
                <Button color="primary" className={"submit-button"}
                        onClick={this.props.submit}>
                        {this.props.buttonText}
                        <span style={this.props.loading ? {} : { display: 'none' }}> ... </span>
                </Button>
            </Col>
        </FormGroup>
    }
}

export default SubmitButtonComponent;