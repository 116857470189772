import * as React from "react";
import FormService from './services/formService';
import { Button, Form, FormGroup, Col } from 'reactstrap';
import TwoColumnFormSection from './twoColumnFormSection';
import ThreeColumnFormSection from './threeColumnFormSection';
import DefaultColumnFormSection from './defaultColumnFormSection';
import URLSearchParams from '@ungap/url-search-params';

class FormComponent extends React.Component {

    constructor(props) {
        super(props);

        this.formService = new FormService(this.props.apiEndpoint);

        this.formElementRefs = {};
        this.sectionElementRefs = {};

        this.recaptchaColumnName = null;

        this.state = {
            loading: false,
            formInfo: { fields: [], layout: { sections: [] }, contactMappings: [] },
            model: {},
            posted: false,
            defaultValues: this.props.defaultValues,
            tooltips: {},
            isPostBack: false,
            sectionVisibility: {},
            hideSubmitButton: false
        }

        this.submit = this.submit.bind(this);
        this.fillRecaptchaColumnName = this.fillRecaptchaColumnName.bind(this);
        this.isVisible = this.isVisible.bind(this);
        this.checkVisibilityCondition = this.checkVisibilityCondition.bind(this);
    }

    fillRecaptchaColumnName(columnName) {
        this.recaptchaColumnName = columnName;
    }

    isEmptyAndRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value === "") && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    isValidMaxLength(inputElement, inputModel) {
        var length = inputElement.value ? inputElement.value.length : 0;
        var maxLength = !inputModel.isTextArea ? this.state.formInfo.formMaxTextInputLenght : this.state.formInfo.formMaxTextAreaLenght;
        if (maxLength >= length) {
            return false;
        }
        else {
            return true;
        }
    }

    isFileRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value.length === 0) && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    fileWrongFileType(files, props) {
        var file = files && files.length && files[0];
        if (!file || !(props.fileTypes || "").length) return false;
        const lastDot = file.name.lastIndexOf('.');
        const ext = file.name.substring(lastDot + 1);
        return props.fileTypes.indexOf(ext) < 0;
    }

    fileExeedLimit(files, props) {
        if (files && files.length > 0) {
            var fileSize = files[0].size / 1024 / 1024; // in MB
            if (fileSize > parseInt(props.fileSize)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    isDateEmptyAndRequired(inputElement, inputModel) {
        if (!inputElement.state.dateTime && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    isEmailField(field) {
        let isEmailField = false;

        if (field.validationRules && field.validationRules.length > 0) {
            for (var index = 0; index < field.validationRules.length; index++) {
                if (field.validationRules[index].identifier === "Kentico.Email") {
                    isEmailField = true;
                    break;
                }
            }
        }

        return isEmailField;
    }

    getSectionVisibilities(tempModel, formInfo) {
        var visibilities = {};

        if (formInfo && formInfo.layout && formInfo.layout.sections && formInfo.layout.sections.length > 0) {
            for (var i = 0; i < formInfo.layout.sections.length; i++) {
                visibilities[formInfo.layout.sections[i].id] = true;
            }

            for (var i = 0; i < formInfo.layout.sections.length; i++) {
                if (formInfo.layout.sections[i].name !== "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSectionHighlighted"
                    && formInfo.layout.sections[i].name !== "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection3366Highlighted") {
                    continue;
                }
                var section = formInfo.layout.sections[i];

                var allFieldsIds = [];
                for (var j = 0; j < section.zones.length; j++) {
                    var zone = section.zones[j];
                    if (zone.formComponents && zone.formComponents.length > 0) {
                        for (var k = 0; k < zone.formComponents.length; k++) {
                            allFieldsIds.push(zone.formComponents[k].id);
                        }
                    }
                }

                if (allFieldsIds.length > 0) {
                    var allFieldsAreInvisible = true;
                    for (var column in tempModel) {
                        for (var l = 0; l < allFieldsIds.length; l++) {
                            if (allFieldsIds[l] === tempModel[column].guid) {
                                allFieldsAreInvisible = allFieldsAreInvisible && tempModel[column].visible;
                            }
                        }
                    }
                    visibilities[section.id] = allFieldsAreInvisible;
                }
            }
        }

        return visibilities;
    }

    checkVisibilityCondition() {
        var tempModel = this.state.model;
        this.state.formInfo.fields.forEach(element => {
            this.isVisible(element, tempModel);
        });

        var visibilities = this.getSectionVisibilities(tempModel, this.state.formInfo);

        this.setState({ model: tempModel, sectionVisibility: visibilities });
    }

    isVisible(field, stateModel) {
        if (field.visibilityIdentifier === "Kentico.AnotherBoolField") {
            let visibilityCondition = field.visibilityCondition;
            if (visibilityCondition) {
                var expectedValue = visibilityCondition.expectedValue;
                var dependeeFieldGuid = visibilityCondition.dependeeFieldGuid;
                for (var f in stateModel) {
                    if (stateModel[f].guid === dependeeFieldGuid) {
                        if (expectedValue === "IsTrue") {
                            stateModel[field.column].visible = stateModel[f].value;
                        } else {
                            stateModel[field.column].visible = !stateModel[f].value;
                        }
                        break;
                    }
                }
            }
        } else if (field.visibilityIdentifier === "Kentico.AnotherStringField") {
            let visibilityCondition = field.visibilityCondition;
            if (visibilityCondition) {
                var expectedValue = visibilityCondition.expectedValue;
                var dependeeFieldGuid = visibilityCondition.dependeeFieldGuid;
                var comparisonType = visibilityCondition.comparisonType;
                for (var f in stateModel) {
                    if (stateModel[f].guid === dependeeFieldGuid) {
                        stateModel[field.column].visible = !comparisonType || comparisonType == "Equal" ? expectedValue == stateModel[f].value : expectedValue != stateModel[f].value;
                        break;
                    }
                }
            }
        }
    }

    getInitialValue(field, availableValues) {
        var defaultValue = "";

        var specialQSParamCheck = /^\?(\w+)\?$/gi; // Make sure the default value doesn't have a special syntax.
        if (field.defaultValue && field.defaultValue !== "" && !specialQSParamCheck.test(field.defaultValue)) {
            defaultValue = field.defaultValue;
        }

        if (!this.state.defaultValues) {
            return defaultValue;
        }

        defaultValue = this.state.defaultValues[field.column];
        if (defaultValue) {
            return defaultValue;
        }
        else {
            if (availableValues && availableValues.length > 0) {
                return availableValues[0].value;
            }
            return "";
        }
    }

    getInitialBooleanValue(field) {
        var defaultValue = false;

        if (field.defaultValue && field.defaultValue !== "") {
            defaultValue = field.defaultValue.toLowerCase() === "true";
        }

        if (!this.state.defaultValues) {
            return defaultValue;
        }

        defaultValue = this.state.defaultValues[field.column];
        if (defaultValue) {
            return defaultValue;
        }
        else {
            return false;
        }
    }



    validationRulesNotSatisfied(field, formComponent) {
        var notSatisfiedObject = {
            notSatisfied: false,
            message: ""
        }
        
        if (field && field.validationRules && field.validationRules.length > 0) {
            for (var index = 0; index < field.validationRules.length; index++) {
                if (field.validationRules[index].identifier === "Kentico.Email") {
                    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/.test(formComponent.formElementRefs[field.column].value)) {
                        notSatisfiedObject.notSatisfied = true;
                        notSatisfiedObject.message = field.validationRules[index].errorMessage;
                        break;
                    }
                } else if (field.validationRules[index].identifier === "Kentico.RegularExpression") {
                    if (field.validationRules[index].regularExpression) {
                        var regex = new RegExp(field.validationRules[index].regularExpression);
                        if (!regex.test(formComponent.formElementRefs[field.column].value)) {
                            notSatisfiedObject.notSatisfied = true;
                            notSatisfiedObject.message = field.validationRules[index].errorMessage;
                            break;
                        }
                    }
                } else if (field.validationRules[index].identifier === "Kentico.MaximumLength") {
                    if (field.validationRules[index].maximumLength) {
                        if (formComponent.formElementRefs[field.column].value.length > field.validationRules[index].maximumLength) {
                            notSatisfiedObject.notSatisfied = true;
                            notSatisfiedObject.message = field.validationRules[index].errorMessage;
                            break;
                        }
                    }
                } else if (field.validationRules[index].identifier === "Kentico.BoolIsSet") {
                    if (!formComponent.formElementRefs[field.column].checked) {
                        notSatisfiedObject.notSatisfied = true;
                        notSatisfiedObject.message = field.validationRules[index].errorMessage;
                        break;
                    }
                } else if (field.validationRules[index].identifier === "DateValueLiesBetweenValidationRule") {
                    var getDateFromStr = function (dateStr) {
                        if (!dateStr || !dateStr.length) return undefined;
                        dateStr = dateStr.trim();
                        var parts = dateStr.split('/');
                        if (parts.length != 3) return undefined;
                         //MM/dd/yyyy
                        return new Date(parts[2], parts[0] - 1, parts[1]);
                    }

                    var selectedDate = getDateFromStr(formComponent.formElementRefs[field.column].state.model.value);
                    var minDate = getDateFromStr(field.validationRules[index].minimumRangeValue);
                    var maxDate = getDateFromStr(field.validationRules[index].maximumRangeValue); 
                   
                    if (minDate && selectedDate < minDate) {
                        notSatisfiedObject.notSatisfied = true;
                        notSatisfiedObject.message = field.validationRules[index].errorMessage;
                        break;
                    }

                    if (maxDate && selectedDate > maxDate) {
                        notSatisfiedObject.notSatisfied = true;
                        notSatisfiedObject.message = field.validationRules[index].errorMessage;
                        break;
                    }
                }
            }
        }
        return notSatisfiedObject;
    }

    submit() {
        var model = this.state.model;
        for (var column in model) {
            var inputElement = this.formElementRefs[column];
            var columnModel = model[column];
            if (inputElement && columnModel) {
                if (column === this.recaptchaColumnName) {
                    model[column].invalid = !model[column].value;
                }
                else {
                    if (model[column].type === "string") {
                        if (this.isEmptyAndRequired(inputElement, columnModel)) {
                            model[column].invalid = true;
                            model[column].errorMessage = columnModel.description;
                        } else if (this.isValidMaxLength(inputElement, columnModel)) {
                            model[column].invalid = true;
                            model[column].errorMessage =
                                !columnModel.isTextArea
                                    ? this.state.formInfo.formMaxTextInputLenghtErrorMessage
                                    : this.state.formInfo.formMaxTextAreaLenghtErrorMessage;
                        } else {
                            var notSatisfiedObject = model[column].isNotValid();
                            model[column].invalid = notSatisfiedObject.notSatisfied;

                            if (model[column].invalid) {
                                model[column].errorMessage = notSatisfiedObject.message;
                            }
                            else {
                                model[column].errorMessage = "";
                            }
                        }
                    } if (model[column].type === "datetime") {
                        if (this.isDateEmptyAndRequired(inputElement, columnModel)) {
                            model[column].invalid = true;
                            model[column].errorMessage = columnModel.description;
                        } else {
                            //model[column].invalid = false;
                            //model[column].errorMessage = "";
                            var notSatisfiedObject = model[column].isNotValid();
                            model[column].invalid = notSatisfiedObject.notSatisfied;

                            if (model[column].invalid) {
                                model[column].errorMessage = notSatisfiedObject.message;
                            }
                            else {
                                model[column].errorMessage = "";
                            }
                        }
                    } else if (model[column].type === "radio") {
                        if (!model[column].allowEmpty) {
                            let isOneOptionChecked = false;
                            for (var index in inputElement) {
                                var radioElement = inputElement[index];
                                if (radioElement.checked) {
                                    isOneOptionChecked = true;
                                    break;
                                }
                            }

                            model[column].invalid = !isOneOptionChecked;
                            model[column].errorMessage = columnModel.description;
                        }
                        else {
                            model[column].invalid = false;
                            model[column].errorMessage = "";
                        }
                    } else if (model[column].type === "boolean") {
                        if (!model[column].allowEmpty) {
                            model[column].invalid = !inputElement.checked;
                            if (model[column].invalid) {
                                model[column].errorMessage = columnModel.description;
                            } else {
                                model[column].errorMessage = "";
                            }
                        }
                        else {
                            var notSatisfiedObject = model[column].isNotValid();
                            model[column].invalid = notSatisfiedObject.notSatisfied;
                            if (model[column].invalid) {
                                model[column].errorMessage = notSatisfiedObject.message;
                            }
                            else {
                                model[column].errorMessage = "";
                            }
                        }
                    } else if (model[column].type === "file") {
                        if (!model[column].allowEmpty) {
                            if (this.isFileRequired(inputElement, columnModel)) {
                                model[column].invalid = true;
                                model[column].errorMessage = columnModel.description || "Required";
                            } else {
                                model[column].invalid = false;
                                model[column].errorMessage = "";
                            }
                        }

                        for (let i = 0; i < this.state.formInfo.fields.length; i++) {
                            if (this.state.formInfo.fields[i].column === column) {
                                if (this.fileWrongFileType(inputElement.files, this.state.formInfo.fields[i])) {
                                    model[column].invalid = true;
                                    model[column].errorMessage = this.state.formInfo.fields[i].fileTypeErrorMessage || "Wrong file format!";
                                    break;
                                }

                                if (this.fileExeedLimit(inputElement.files, this.state.formInfo.fields[i])) {
                                    model[column].invalid = true;
                                    model[column].errorMessage = this.state.formInfo.fields[i].fileSizeErrorMessage;
                                    break;
                                }
                            }
                        }

                    }
                }
            }
        }

        var isValid = true;
        for (var column in model) {
            if (model[column].invalid) {
                isValid = false;
                break;
            }
        }

        if (!isValid) {
            this.setState({ model: model });
        } else {
            this.setState(prevState => ({
                loading: true
            }));

            var payload = {
                formCodeName: this.props.formName,
                values: [],
                contactMappings: this.state.formInfo.contactMappings,
                contactId: this.props.contactId,
                overrideContact: this.state.formInfo.contactOverwriteEnabled,
                culture: this.props.culture,
                siteName: this.props.siteName
            }

            let containsPageUrl = false;

            const urlParams = new URLSearchParams(location.search);

            for (var column in this.state.model) {
                if (column === "PageUrl") {
                    containsPageUrl = true;
                    let value = this.state.model[column].value;
                    if (value) {
                        payload.values.push({
                            name: column,
                            value: value
                        });
                    }
                    else {
                        payload.values.push({
                            name: column,
                            value: window.location.pathname
                        });
                    }

                } else if (column.type === "file") {
                    var files = this.state.model[column].value;
                    payload.values.push({
                        name: column,
                        value: files
                    });
                } else {

                    // RegExp to check for the special syntax.
                    var specialQSParamCheck = /^\?(\w+)\?$/;
                    // Get the special query string param so we can test for it.
                    var specialQSParam = this.state.model[column].querystringparam;
                    // See if the special querystring field is wrapped in ?
                    if ((!this.state.model[column].value || this.state.model[column].value.length === 0) && specialQSParam && specialQSParam.length > 2 && specialQSParamCheck.test(specialQSParam)) {
                        var qsMatches = specialQSParamCheck.exec(specialQSParam);
                        if (qsMatches && qsMatches.length > 0) {
                            var qsParam = qsMatches[1];
                            payload.values.push({
                                name: column,
                                value: urlParams.get(qsParam)
                            });
                        }
                    } else {
                        payload.values.push({
                            name: column,
                            value: this.state.model[column].value
                        });
                    }
                }
            }

            if (!containsPageUrl) {
                payload.values.push({
                    name: "PageUrl",
                    value: window.location.pathname
                });
            }

            let formData = new FormData();

            formData.append("form_formCodeName", payload.formCodeName);
            formData.append("form_contactMappings", payload.contactMappings);
            formData.append("form_contactId", payload.contactId);
            formData.append("form_overrideContact", payload.overrideContact);
            formData.append("form_culture", payload.culture);
            formData.append("form_siteName", payload.siteName);

            for (var i = 0; i < payload.values.length; i++) {
                if (payload.values[i].value && payload.values[i].value.constructor === FileList && payload.values[i].value.length > 0) {
                    formData.append(payload.values[i].name, payload.values[i].value[0], payload.values[i].value[0].name);
                }
                else {
                    formData.append(payload.values[i].name, payload.values[i].value);
                }
            }

            this.formService.postForm(formData, this, function (formData, formComponent) {
                try {
                    window.dataLayer = window.dataLayer || [];
                    var gaPayload = { 'event': 'form-submit-success', 'form-name': payload.formCodeName }
                    window.dataLayer.push(gaPayload);
                    var formComponentBoundingClientRect = ReactDOM.findDOMNode(formComponent).getBoundingClientRect(); // Scroll back to the start of the form.
                    if (formComponentBoundingClientRect.top < 0) {
                        window.scrollTo(0, window.pageYOffset + formComponentBoundingClientRect.top - 100); // Approx location of the Form, with 100px extra.
                    }

                } catch (ex) {
                    console.log(ex);
                }

                if (formComponent.state.formInfo.formSubmitRedirectUrl && formComponent.state.formInfo.formSubmitRedirectUrl !== "") {
                    window.location.href = formComponent.state.formInfo.formSubmitRedirectUrl;
                    return;
                }
                formComponent.setState({ loading: false, posted: true });
            }, function (err, formComponent) {
                console.error(err);
                formComponent.setState({ loading: false });
            })
        }
    }

    componentDidMount() {
        this.setState(prevState => ({
            loading: true
        }));

        const urlParams = (location.hash || '').length > 0
            ? new URLSearchParams('?' + location.hash.substring(1))
            : new URLSearchParams(location.search);

        this.formService.loadForm(this, function (formData, formComponent) {
            var model = {};
            var tooltips = {};
            var showSubmitButton = true;
            if (formData && formData.fields && formData.fields.length > 0) {
                formData.fields.forEach(element => {
                    tooltips[element.column] = false;

                    // start process default values
                    if ((element.defaultValue || "").length > 2) {
                        if (element.defaultValue[0] === "#" && element.defaultValue[element.defaultValue.length - 1] === "#") {
                            var urlParamName = element.defaultValue.substring(1, element.defaultValue.length - 1);
                            element.defaultValue = urlParams.get(urlParamName);
                        }                     
                    }

                    // end process default values
                    switch (element.componentIdentifier) {
                        case "Kentico.TextInput":
                        case "Kentico.EmailInput":
                        case "Kentico.TextArea":
                            {
                                model[element.column] = {
                                    guid: element.guid,
                                    invalid: false,
                                    value: formComponent.getInitialValue(element),
                                    allowEmpty: element.allowEmpty,
                                    isNotValid: formComponent.validationRulesNotSatisfied.bind(this, element, formComponent),
                                    type: "string",
                                    errorMessage: "",
                                    description: element.description,
                                    visible: true,
                                    querystringparam: element.defaultValue,
                                    isTextArea: element.componentIdentifier === "Kentico.TextArea"
                                };
                                break;
                            }
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.DatePickerComponent":
                            {
                                model[element.column] = {
                                    guid: element.guid,
                                    invalid: false,
                                    value: formComponent.getInitialValue(element),
                                    allowEmpty: element.allowEmpty,
                                    isNotValid: formComponent.validationRulesNotSatisfied.bind(this, element, formComponent),
                                    type: "datetime",
                                    errorMessage: "",
                                    description: element.description,
                                    visible: true,
                                };
                                break;
                            }
                        case "Kentico.RadioButtons":
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.RadioButtonsComponent":
                            {
                                model[element.column] = {
                                    guid: element.guid,
                                    invalid: false,
                                    value: formComponent.getInitialValue(element),
                                    allowEmpty: element.allowEmpty,
                                    isNotValid: formComponent.validationRulesNotSatisfied.bind(this, element, formComponent),
                                    type: "radio",
                                    errorMessage: "",
                                    description: element.description,
                                    visible: true,
                                };
                                break;
                            }
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.DataSourceDropDownComponent":
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.ProvinceStateDropDownComponent":
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.MultiSelectDropDownComponent":
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.DropDownComponent":
                        case "Kentico.DropDown":
                            {
                                model[element.column] = {
                                    guid: element.guid,
                                    invalid: false,
                                    value: formComponent.getInitialValue(element, element.values),
                                    allowEmpty: element.allowEmpty,
                                    isNotValid: formComponent.validationRulesNotSatisfied.bind(this, element, formComponent),
                                    type: "string",
                                    errorMessage: "",
                                    description: element.description,
                                    visible: true,
                                    isTextArea: element.componentIdentifier === "Kentico.TextArea"
                                };
                                break;
                            }
                        case "Kentico.FileUploader":
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.CustomFileUploaderComponent": {
                            model[element.column] = {
                                guid: element.guid,
                                invalid: false,
                                value: null,
                                allowEmpty: element.allowEmpty,
                                type: "file",
                                errorMessage: "",
                                description: element.description,
                                visible: true,
                            };
                            break;
                        }
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.SubmitButtonComponent":
                            {
                                showSubmitButton = false;
                                break;
                            }
                        case "Kentico.Recaptcha": {
                            model[element.column] = {
                                guid: element.guid,
                                invalid: false,
                                value: false,
                                allowEmpty: false,
                                type: "boolean",
                                errorMessage: "",
                                description: element.description,
                                visible: true,
                            };
                            break;
                        }
                        case "Kentico.CheckBox": {
                            model[element.column] = {
                                guid: element.guid,
                                invalid: false,
                                value: formComponent.getInitialBooleanValue(element),
                                allowEmpty: element.allowEmpty,
                                isNotValid: formComponent.validationRulesNotSatisfied.bind(this, element, formComponent),
                                type: "boolean",
                                errorMessage: "",
                                description: element.description,
                                visible: true,
                            };
                            break;
                        }
                        case "CustomDev.FormComponents.TextLabel": {
                            model[element.column] = {
                                guid: element.guid,
                                invalid: false,
                                value: formComponent.getInitialBooleanValue(element),
                                allowEmpty: element.allowEmpty,
                                type: "text",
                                errorMessage: "",
                                description: element.description,
                                visible: true,
                            };
                            break;
                        }
                        case "CPABCPD.WebCore.FormBuilder.FormComponents.TextComponent": {
                            model[element.column] = {
                                guid: element.guid,
                                invalid: false,
                                value: formComponent.getInitialBooleanValue(element),
                                allowEmpty: element.allowEmpty,
                                type: "text",
                                errorMessage: "",
                                description: element.description,
                                visible: true,
                            };
                            break;
                        }
                    }
                });

                formData.fields.forEach(element => {
                    formComponent.isVisible(element, model);
                });
            }


            var visibilities = formComponent.getSectionVisibilities(model, formData);

            formComponent.setState({ hideSubmitButton: !showSubmitButton, formInfo: formData, model: model, tooltips: tooltips, loading: false, isPostBack: true, sectionVisibility: visibilities });
        }, function (err) {
            console.error(err, formComponent);
            formComponent.setState({ loading: false });
        })
    }

    componentDidUpdate() {
        //console.log("component updated");
    }

    render() {
        const sections = (<div className="form-sections">
            {this.state.formInfo.layout.sections.map((section, key) => {
                if (section.name === "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection") {
                    return <TwoColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection"}
                        isHighlighted={false}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels} labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs} model={this.state.model}
                        key={section.id} fields={this.state.formInfo.fields}
                        section={section}
                        type={"5050"}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                } else if (section.name === "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection3366") {
                    return <TwoColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection3366"}
                        isHighlighted={false}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels} labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs} model={this.state.model}
                        key={section.id} fields={this.state.formInfo.fields}
                        section={section}
                        type={"3366"}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                } else if (section.name === "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSectionHighlighted") {
                    return <TwoColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSectionHighlighted"}
                        isHighlighted={true}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels} labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs} model={this.state.model}
                        key={section.id} fields={this.state.formInfo.fields}
                        section={section}
                        type={"5050"}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                } else if (section.name === "CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection3366Highlighted") {
                    return <TwoColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"CPABCPD.WebCore.FormBuilder.FormsSections.TwoColumnFormSection3366Highlighted"}
                        isHighlighted={true}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels} labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs} model={this.state.model}
                        key={section.id} fields={this.state.formInfo.fields}
                        section={section}
                        type={"3366"}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                } else if (section.name === "CPABCPD.WebCore.FormBuilder.FormsSections.ThreeColumnFormSection") {
                    return <ThreeColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"CPABCPD.WebCore.FormBuilder.FormsSections.ThreeColumnFormSection"}
                        isHighlighted={false}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels} labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs} model={this.state.model}
                        key={section.id} fields={this.state.formInfo.fields}
                        section={section}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                } else {
                    return <DefaultColumnFormSection ref={ref => { this.sectionElementRefs[section.id] = ref }}
                        name={"Default"}
                        isHighlighted={false}
                        checkVisibilityCondition={this.checkVisibilityCondition}
                        gooleRecaptchaSitekey={this.props.gooleRecaptchaSitekey}
                        showLabels={this.props.showLabels}
                        labelsOnTop={this.props.labelsOnTop}
                        formElementRefs={this.formElementRefs}
                        model={this.state.model}
                        key={section.id}
                        fields={this.state.formInfo.fields}
                        section={section}
                        visible={this.state.sectionVisibility && typeof this.state.sectionVisibility[section.id] !== 'undefined' ? this.state.sectionVisibility[section.id] : true}
                        loading={this.state.loading}
                        submit={this.submit}
                        formInfo={this.state.formInfo}
                        posted={this.state.posted}
                        formComponent={this}
                        fillRecaptchaColumnName={this.fillRecaptchaColumnName} />
                }
            })}
        </div>);
        return (<div className="form-wrapper">
            <Form style={!this.state.isPostBack ? { display: 'none' } : {}}>
                {
                    this.state.formInfo && this.state.formInfo.layout && this.state.formInfo.layout.sections && this.state.formInfo.layout.sections.length > 0 ? sections : <div className="form-sections"></div>
                }
                <FormGroup row style={!this.state.posted && !this.state.hideSubmitButton ? {} : { display: 'none' }}>
                    <Col sm={12}>
                        <Button color="primary" onClick={this.submit}>{this.state.formInfo != null ? this.state.formInfo.formSubmitButtonText : "Submit Button"}<span style={this.state.loading ? {} : { display: 'none' }}> ... </span></Button>
                    </Col>
                </FormGroup>
            </Form>
            <div className="loading" style={!this.state.isPostBack ? {} : { display: 'none' }}>Loading ...</div>
            <div className="success-message" style={this.state.posted ? {} : { display: 'none' }}>
                {this.state.formInfo.formSubmitSuccessText}
            </div>
        </div>);
    }
}

export default FormComponent;