import * as types from "./actionTypes";
import { beginApiCall, apiCallError, beginApiCallPdDetails, beginLoadMoreCall, endLoadMoreCall } from "./apiStatusActions";
import * as searchPdApi from "../../api/searchPdApi";

export function searchPdSuccess(searchResult) {
    return { type: types.SEARCH_PD_SUCCESS, searchResult };
}

export function getDetailsPdSuccess(pdDetails) {
    return { type: types.GET_DETAILS_PD_SUCCESS, pdDetails };
}

export function searchPd(searchRequest, loadMoreCallBack) {
    return function (dispatch) {

        dispatch(beginApiCall());
        
        if (searchRequest.PageIndex === 0) {
            dispatch(setIsLoadingPdDetails(true));
        }
        
        if (searchRequest.PageIndex > 0) {
            dispatch(beginLoadMoreCall());
        }
        return searchPdApi
            .search(searchRequest)
            .then((result) => {
                if (result) {
                    const products = {
                        pageIndex: searchRequest.PageIndex,
                        pageSize: searchRequest.PageSize,
                        items: result.items ? result.items : [],
                        totalCount: result.totalCount,
                        hasResultNewCourses: result.hasResultNewCourses,
                        isLoading: false
                    }
                    if (products.pageIndex === 0 && products.items.length > 0) {
                        if (searchRequest.StartDate === null &&
                            searchRequest.EndDate === null &&
                            searchRequest.Keywords === "" &&
                            searchRequest.CpabcCertificateProgram.length === 0 &&
                            searchRequest.CpabcCourseDeliveryMethod.length === 0 &&
                            searchRequest.CpabcCourseProgram.length === 0 &&
                            searchRequest.CpabcCourseSubcategory.length === 0 &&
                            searchRequest.CpabcSeminarLeaderId.length === 0 &&
                            searchRequest.CpabcVenueId.length === 0 &&
                            searchRequest.DurationHours.length === 0 &&
                            searchRequest.EthicsHours.length === 0 && 
                            searchRequest.PassportCredits.length === 0 
                        ) {
                            dispatch(setNewCoursesOnlyVisible(false))
                        }
                        else {
                            dispatch(setNewCoursesOnlyVisible(true));
                        }

                        dispatch(selectCourse(products.items[0]));

                        if (searchRequest.deviceTypeinfo === undefined || searchRequest.deviceTypeinfo != "Mobile") {
                            dispatch(getPdDetails(products.items[0].masterCode, searchRequest));
                        }

                    }
                    if (products.items.length === 0 && products.totalCount === 0) {
                        window.location.hash = '';
                        dispatch(selectCourse(null));
                    }
                    dispatch(searchPdSuccess(products));
                }
                else {
                    const products = {
                        pageIndex: searchRequest.pageIndex,
                        pageSize: searchRequest.pageSize,
                        items: [],
                        totalCount: 0,
                        isLoading: true
                    }
                    dispatch(searchPdSuccess(products));
                }
                if (searchRequest.PageIndex > 0) {
                    dispatch(endLoadMoreCall());
                    if (loadMoreCallBack) {
                        loadMoreCallBack();
                    }
                }
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                dispatch(endLoadMoreCall());

                if (loadMoreCallBack) {
                    loadMoreCallBack();
                }
                throw error;
            });
    };
}

export function getPdDetails(masterCode, searchRequest = null) {
    return function (dispatch) {
        dispatch(beginApiCallPdDetails());
        return searchPdApi
            .getPdDetails(masterCode, searchRequest)
            .then((result) => {
                if (result) {
                    dispatch(setIsLoadingPdDetails(false));
                    //dispatch(setIsLoadingPdDetails(false));
                    dispatch(getDetailsPdSuccess(result));
                    //dispatch(selectCourse(result.currentCourse));
                }
                else {
                    dispatch(getDetailsPdSuccess(null));
                }
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                throw error;
            });
    };

}

export function getPdDetailsHash(masterCode, searchRequest = null) {
    return function (dispatch) {
        dispatch(beginApiCallPdDetails());
        return searchPdApi
            .getPdDetails(masterCode, searchRequest)
            .then((result) => {
                if (result) {
                    dispatch(setIsLoadingPdDetails(false));
                    //dispatch(setIsLoadingPdDetails(false));
                    dispatch(getDetailsPdSuccess(result));
                    dispatch(selectCourse(result.currentCourse));
                }
                else {
                    dispatch(getDetailsPdSuccess(null));
                }
            })
            .catch((error) => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function resetSearchResultItems() {
    return { type: types.RESET_SEARCH_RESULT_ITEMS};
}

export function setLoadingFalse(isLoading) {
    return { type: types.SET_TIME_OUT_LOADING, isLoading};
}

export function setStartDate(startDate) {
    return { type: types.SET_START_DATE, startDate };
}

export function setEndDate(endDate) {
    return { type: types.SET_END_DATE, endDate };
}

export function setSelectedLocations(selectedLocations) {
    return { type: types.SET_SELECTED_LOCATIONS, selectedLocations };
}

export function setSelectedSubjects(selectedSubjects) {
    return { type: types.SET_SELECTED_SUBJECTS, selectedSubjects };
}

export function setSelectedSeminarLength(selectedSeminarLength) {
    return { type: types.SET_SELECTED_SEMINAR_LENGTH, selectedSeminarLength };
}

export function setSelectedDeliveryFormats(selectedDeliveryFormats) {
    return { type: types.SET_SELECTED_DELIVERY_FORMATS, selectedDeliveryFormats };
}

export function setSelectedCoursePrograms(selectedCoursePrograms) {
    return { type: types.SET_SELECTED_COURSE_PROGRAMS, selectedCoursePrograms };
}

export function setSelectedEthicsHours(selectedEthicsHours) {
    return { type: types.SET_SELECTED_ETHICS_HOURS, selectedEthicsHours };
}

export function setSelectedCertificatePrograms(selectedCertificatePrograms) {
    return { type: types.SET_SELECTED_CERTIFICATE_PROGRAMS, selectedCertificatePrograms };
}

export function setSelectedLeaders(selectedLeaders) {
    return { type: types.SET_SELECTED_LEADERS, selectedLeaders };
}

export function setSelectedPassportCredits(selectedPassportCredits) {
    return { type: types.SET_SELECTED_PASSPORT_CREDITS, selectedPassportCredits };
}

export function setKeywordsSearchValue(keywordsSearchValue) {
    return { type: types.SET_KEYWORDS_SEARCH_VALUE, keywordsSearchValue };
}

export function selectCourse(selectedCourse) {
    return { type: types.SELECT_COURSE, selectedCourse };
}

export function selectBookmark(selectedBookmark) {
    return { type: types.SELECT_BOOKMARK, selectedBookmark };
}

export function setVisibleListBookmarks(listBookmarksVisible) {
    return { type: types.SET_VISIBLE_LIST_BOOKMARKS, listBookmarksVisible };
}

export function setSearchNewCourses(newCourses) {
    return { type: types.SET_SEARCH_NEW_COURSES, newCourses };
}

export function setSortByValue(sortBy) {
    return { type: types.SET_SORT_BY_VALUE, sortBy };
}

export function resetFiltersSearch() {
    return {
        type: types.RESET_FILTERS_SEARCH,
        startDate: null,
        endDate: null,
        selectedLocations: [],
        selectedSubjects: [],
        selectedSeminarLength: [],
        selectedDeliveryFormats: [],
        selectedCoursePrograms: [],
        selectedEthicsHours: [],
        selectedCertificatePrograms: [],
        selectedLeaders: [],
        selectedPassportCredits: [],
        keywordsSearchValue: '',
        selectedCourse: null,
        //selectedBookmark: [],
        listBookmarksVisible: false,
        pdDetails: null};
}

export function setAllSearchCriteria(allcriteria) {
    return { type: types.SET_ALL_SEARCH_CRITERIA, allcriteria};
}

export function setCourseFromHashVisible(courseFromHashVisible) {
    return { type: types.SET_COURSE_FROM_HASH_VISIBLE, courseFromHashVisible };

} export function setNewCoursesOnlyVisible(newCoursesOnlyVisible) {
    return { type: types.SET_NEW_COURSES_ONLY_VISIBLE, newCoursesOnlyVisible };
}

export function setHashAllSearchCriteria(hashAllSearchCriteria) {
    return { type: types.SET_HASH_ALL_SEARCH_CRITERIA, hashAllSearchCriteria };
}

export function setIsLoadingPdDetails(isLoadingPdDetails) {
    return { type: types.SET_IS_LOADING_PD_DETAILS, isLoadingPdDetails };
}

export function setPdDetails(pdDetails) {
    return { type: types.SET_PD_DETAILS, pdDetails };
}

export function setCopySearchTagVisible(copySearchTagVisible) {
    return { type: types.SET_COPY_SEARCH_TAG_VISIBLE, copySearchTagVisible };
}

export function setSearchTag(searchTag) {
    return { type: types.SET_SEARCH_TAG, searchTag };
}
