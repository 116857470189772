import React, { useState } from 'react';
import * as filterUtils from "./filterUtils";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function LocationDropdown(props) {
    const { selectedLocations, onSelectAnyOrAll, onSelectItem, getActiveClassName, locations} = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-location">
            <DropdownToggle caret>
                {selectedLocations.length === 0 &&
                    <span>Location</span>}
                {selectedLocations.length > 0 && selectedLocations.map((item, index) => {
                    return <span>{(index ? ", " : "") + item.City}</span>;
                })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedLocations, "City", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("City", "LocationId", "Location")}>Any or All</DropdownItem>
                    {locations
                        .map((location) => {
                            let isActive = getActiveClassName(selectedLocations, "City", location.City);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(location, "City", "LocationId", "Location")}>
                                    {location.City}
                                </DropdownItem>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
