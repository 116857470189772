import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Input, Col, Tooltip } from 'reactstrap';

class DropdownFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.formElementRef = null;

        this.state = {
            model: this.props.model,
            tooltip: false
        }
    }

    getInputElement() {
        return this.formElementRef;
    }

    isEmptyAndRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value === "") && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    inputChange(event) {
        let inputElement = event.target;
        var columnModel = this.state.model;
        if (columnModel) {
            if (this.isEmptyAndRequired(inputElement, columnModel)) {
                columnModel.invalid = true;
            }
            else {
                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied;
                columnModel.value = inputElement.value;
                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({ model: columnModel }, () => {
                this.props.onChanged && this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {

        const propFieldValues = this.props.field.listValues || [];

        return (
            <FormGroup row key={this.props.field.guid} className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
                style={this.state.model.visible ? {} : { display: 'none' }}>
                <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                    <Label for={"dwnWithDataSource" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                </Col>
                <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9}>
                    <Label for={"dwnWithDataSource" + this.props.field.column + "_" + this.props.field.guid}
                        style={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}
                        className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                    <Input invalid={this.state.model.invalid}
                        type="select" name={this.props.field.column}
                        id={"dwnWithDataSource" + this.props.field.column + "_" + this.props.field.guid} placeholder={this.props.field.caption}
                        onChange={this.inputChange.bind(this)}
                        innerRef={ref => { this.formElementRef = ref; this.props.formElementRefs[this.props.field.column] = ref }}
                        defaultValue={this.state.model.value}>
                        {
                            propFieldValues.map((option) => {
                                return <option key={option.value} value={option.value}>{option.text}</option>
                            })
                        }
                    </Input>
                    <div className="explanation-text">{this.props.field.explanationText}</div>
                    <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                        className="invalid-message">
                        <Tooltip placement="top"
                            target={this.props.field.column + "_errorMessage_" + this.props.field.guid}
                            toggle={this.toggleTooltip.bind(this)}
                            isOpen={this.state.tooltip}>
                            {this.state.model.errorMessage}
                        </Tooltip>
                    </div>
                </Col>
            </FormGroup>
        )
    }
}

export default DropdownFieldComponent;