import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function EthicsHoursDropdown(props) {
    const { selectedEthicsHours, onSelectAnyOrAll, onSelectItem, getActiveClassName, ethicsHours } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-ethics-hours">
            <DropdownToggle caret>
                {selectedEthicsHours.length === 0 &&
                    <span>Ethics Hours</span>}
                {selectedEthicsHours.length > 0 && selectedEthicsHours
                    .sort((a, b) => a.Value - b.Value)
                    .map((item, index) => {
                        return <span>{(index ? ", " : "") + item.Text}</span>;
                    })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedEthicsHours, "Text", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("Text", "Value", "EthicsHours")}>Any or All</DropdownItem>
                    {ethicsHours
                        .map((item) => {
                            let isActive = getActiveClassName(selectedEthicsHours, "Text", item.Text);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(item, "Text", "Value", "EthicsHours")}>
                                    {item.Text}
                                </DropdownItem>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
