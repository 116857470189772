import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function SubjectDropdown(props) {
    const { selectedSubjects, onSelectAnyOrAll, onSelectItem, getActiveClassName, courseSubjects } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-subject">
            <DropdownToggle caret>
                {selectedSubjects.length === 0 &&
                    <span>Subject</span>}
                {selectedSubjects.length > 0 && selectedSubjects.map((item, index) => {
                    return <span>{(index ? ", " : "") + item.Description}</span>;
                })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedSubjects, "Description", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("Description", "Code", "Subject")}>Any or All</DropdownItem>
                    {courseSubjects
                        .map((course) => {
                            return (
                                <>
                            <DropdownItem
                                toggle={false}
                                className="dropdown-item disabled group">
                                {course.Description}
                            </DropdownItem>
                            {course.Children != null &&
                                course.Children
                                    .map(subject => {

                                        let isActive = getActiveClassName(selectedSubjects, "Description", subject.Description);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(subject, "Description", "Code","Subject")}>
                                    {subject.Description}
                                </DropdownItem>
                                        );
                                    })
                                    }
                                </>
                            );
                        }
                        )
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
