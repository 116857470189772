import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function searchPdReducer(state = initialState.searchResult, action) {
    switch (action.type) {
        case types.SEARCH_PD_SUCCESS:
            {
                let newState = action.searchResult && action.searchResult.pageIndex > 0
                    ? {
                        pageIndex: action.searchResult.pageIndex,
                        pageSize: action.searchResult.pageSize,
                        totalCount: action.searchResult.totalCount,
                        items: [...state.items, ...action.searchResult.items],
                        hasResultNewCourses: state.hasResultNewCourses,
                        isLoading: false
                    }
                    : action.searchResult;

                return newState;
            }

        case types.BEGIN_API_CALL:
            return {
                pageIndex: state.pageIndex,
                pageSize: state.pageSize,
                totalCount: state.totalCount,
                items: state.items,
                hasResultNewCourses: state.hasResultNewCourses,
                isLoading: action.isLoading
            };

        case types.SET_TIME_OUT_LOADING:
            return {
                ... state,
                isLoading: action.isLoading
            };

        case types.RESET_SEARCH_RESULT_ITEMS:
            return {
                ...state,
                items: [],
                totalCount: 0,
                pageIndex: 0,
                pageSize: 9,
                isLoading: false,
            };

        default:
            return state;
    }
}

