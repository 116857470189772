import React from 'react';

export default function SelectedLeaders(props) {
    const { selectedLeaders, onRemoveLeader} = props;

    return (
        <>
            {selectedLeaders.length > 0 &&
                <div className="d-flex mr-2 mb-2 seminarLeader-filters">
                    <div className="option-select">
                        Seminar leader: &nbsp;
                        {selectedLeaders.length > 0 && selectedLeaders.map((item, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{item.Name}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveLeader(item, "LeaderId", "Leaders")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
