import React from "react";
import { Spinner } from 'reactstrap';

function LoadingIndicator(props) {

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spinner color="primary" />
            </div>
        </div>
    )
}

export default LoadingIndicator;