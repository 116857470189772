import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Zoom } from "react-toastify";
import PDDetails from "../common/PDDetails";
import LoadingIndicator from "../common/LoadingIndicator";
import InfiniteScroll from 'react-infinite-scroller';
import Dotdotdot from 'react-dotdotdot';
import { Responsive } from "../../../utils/Responsive";
import { getDeviceTypeInfo } from "../../../utils/utils";
import { getDatesFormat, getEmptySearchRequest } from "./filterUtils";	
import BookmarkDropdown from "../common/BookmarkDropdown";

class SearchResultCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.searchItemsRef = React.createRef();
        this.headPdDetails = React.createRef();
        this.cardsContainerRef = React.createRef();
        this.loadMore = this.loadMore.bind(this);
        this.getCourseSubjectDescription = this.getCourseSubjectDescription.bind(this);
        this.onSelectTag = this.onSelectTag.bind(this);
        this.bookmark = this.bookmark.bind(this);
        this.getDeliveryMethod = this.getDeliveryMethod.bind(this);


        // CALENDAR VARIABLES START //////////////////////////////////////////////////////////////////////////////////

        // ugh there are more efficient ways of doing this ...
        // assume there are at most 500 courses in a month. 
        // so that's 500 popups for the calendar

        let popoverCalendarStatesInit = new Array();
        for (let j = 0; j < 500; j++) {
            popoverCalendarStatesInit.push(false);
        }

        // creating ids on the fly is creating errors, so lets us state
        let popoverCalendarIdsInit = new Array();
        for (let j = 0; j < 500; j++) {
            popoverCalendarIdsInit.push("Popup" + j);
        }
        
        // CALENDAR VARIABLES END ///////////////////////////////////////////////////////////////////////////////////

        this.state = {
            loadingMore: false,
            visiblePdDetails: false,
            popoverCalendarStates: popoverCalendarStatesInit,
            popoverCalendarIds: popoverCalendarIdsInit,
            popoverCalendar: false,
            dayChosen: 0,
            oldStartDateForCalendar: this.props.startDateForCalendar,
            pdDetailsMobileVisible: false
        };
        //console.log("TIME TOP ");

    }

    seeHeadPdDetails = () => {
        this.headPdDetails.current.scrollIntoView({ block: "start" });
    }

    onClickButtonClosePdDetails = () => () => {
        const { selectedCourse } = this.props;
        this.setState({
            visiblePdDetails: false
        });
    };

    //#region load More	
    loadMore() {
        if (!this.state.loadMore && !this.props.searchResult.isLoading) {
            this.setState({ loadingMore: true }, () => {
                const searchCriteria = this.props.allSearchCriteria;
                const pageIndex = (searchCriteria ? searchCriteria.PageIndex : 0) + 1;
                const searchRequest = {
                    StartDate: searchCriteria && searchCriteria.StartDate ? searchCriteria.StartDate : null,
                    EndDate: searchCriteria && searchCriteria.EndDate ? searchCriteria.EndDate : null,
                    PageIndex: pageIndex,
                    PageSize: searchCriteria && searchCriteria.PageSize ? searchCriteria.PageSize : 9,
                    CpabcVenueId: searchCriteria && searchCriteria.CpabcVenueId ? searchCriteria.CpabcVenueId : [],
                    CpabcCourseSubcategory: searchCriteria && searchCriteria.CpabcCourseSubcategory ? searchCriteria.CpabcCourseSubcategory : [],
                    DurationHours: searchCriteria && searchCriteria.DurationHours ? searchCriteria.DurationHours : [],
                    CpabcCourseDeliveryMethod: searchCriteria && searchCriteria.CpabcCourseDeliveryMethod ? searchCriteria.CpabcCourseDeliveryMethod : [],
                    CpabcCourseProgram: searchCriteria && searchCriteria.CpabcCourseProgram ? searchCriteria.CpabcCourseProgram : [],
                    EthicsHours: searchCriteria && searchCriteria.EthicsHours ? searchCriteria.EthicsHours : [],
                    EthicsHoursGreaterThan0: searchCriteria && searchCriteria.EthicsHoursGreaterThan0 ? searchCriteria.EthicsHoursGreaterThan0 : null,
                    CpabcCertificateProgram: searchCriteria && searchCriteria.CpabcCertificateProgram ? searchCriteria.CpabcCertificateProgram : [],
                    CpabcSeminarLeaderId: searchCriteria && searchCriteria.CpabcSeminarLeaderId ? searchCriteria.CpabcSeminarLeaderId : [],
                    PassportCredits: searchCriteria && searchCriteria.PassportCredits ? searchCriteria.PassportCredits : [],
                    Keywords: searchCriteria && searchCriteria.Keywords ? searchCriteria.Keywords : "",
                    FromInit: false,
                    Sort: searchCriteria && searchCriteria.Sort ? searchCriteria.Sort : 3,
                    NewCourses: searchCriteria && searchCriteria.NewCourses ? searchCriteria.NewCourses : null,
                }
                if (searchCriteria.Tags && searchCriteria.Tags.length > 0) {	
                    searchRequest.Tags = searchCriteria.Tags;	
                }
                const sessionStorageSearchRequest = JSON.stringify(searchRequest);
                sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);
                this.props.setAllSearchCriteria(searchRequest);
                setTimeout(() => {
                    var state = this;
                    this.props.searchPd(searchRequest, function () {
                        state.setState({ loadingMore: false });
                    });
                }, 100);
            })
        }
    }
    //#endregion

    componentDidUpdate() {
        if (this.props.selectedCourse) {
            window.location.hash = '#/' + this.props.selectedCourse.cpabcMeeting.toLowerCase() + '/' + this.props.selectedCourse.masterCode.toLowerCase() + '/';
        }
        if (this.props.startDateForCalendar != this.state.oldStartDateForCalendar) {

            this.setState({ oldStartDateForCalendar: this.props.startDateForCalendar,  dayChosen: 0 });
        }
    }
    
    componentDidMount() {
    }


    togglePC(key) {
        //console.log("test array length : " + this.state.popoverCalendarStates.length);
        let popoverCalendarStatesTemp = [...this.state.popoverCalendarStates];
        popoverCalendarStatesTemp[key] = !popoverCalendarStatesTemp[key];
        this.setState({
            popoverCalendarStates: popoverCalendarStatesTemp
        });
    }

    //#region onHover popover calendar
    onHoverPC = (key) => {
        //console.log("test array lengthc: " + this.state.popoverCalendarStates.length);
        let popoverCalendarStatesTemp = [...this.state.popoverCalendarStates];
        popoverCalendarStatesTemp[key] = true;
        this.setState({
            popoverCalendarStates: popoverCalendarStatesTemp
        });
    }
    //#endregion

    //#region onHover Leave popover calendar
    onHoverLeavePC = (key) => {
        let popoverCalendarStatesInit = new Array();
        for (let j = 0; j < 500; j++) {
            popoverCalendarStatesInit.push(false);
        }
        this.setState({ popoverCalendarStates: popoverCalendarStatesInit });
    }
    //#endregion

    togglePC2() {
        this.setState({
            popoverCalendar: !this.state.popoverCalendar
        });
    }

    //#region onHover popover calendar
    onHoverPC2 = () => {
        this.setState({
            popoverCalendar: true
        });
    }
    //#endregion

    //#region onHover Leave popover calendar
    onHoverLeavePC2 = () => {
        this.setState({
            popoverCalendar: false
        });
    }
    //#endregion


    getCourseSubjectDescription(parameter) {
        const { courseSubjects } = this.props;
        for (var i = 0; i < courseSubjects.length; i++) {
            if (courseSubjects[i].Code === parameter) {
                return courseSubjects[i].Description.toUpperCase();
            }
        }
        return null;
    }

    getCourseSubjectDescriptionCalendar(parameter) {
        const { courseSubjects } = this.props;
        for (var i = 0; i < courseSubjects.length; i++) {
            if (courseSubjects[i].Code === parameter) {
                return courseSubjects[i].Description;
            }
        }
        return null;
    }

    getDeliveryMethod(parameter) {
        const { deliveryFormats } = this.props;
        for (let i = 0; i < deliveryFormats.length; i++) {
            if (parameter === "OL") {
                return "Online";
            }
            else if (deliveryFormats[i].Code === parameter) {
                return deliveryFormats[i].Description;
            }
        }
        return null;
    }

    getCity = (parameter) => {
        for (let i = 0; i < this.props.locations.length; i++) {
            if (this.props.locations[i].LocationId === parameter) {
                //console.log("TEST LOCATION/city: " + parameter + "|" + this.props.locations[i].City);
                if (this.props.locations[i].City != "N/A") {
                    return this.props.locations[i].City;
                }
            }
        }
        return null;
    }

    getSeminarType = (parameter) => {
        for (let i = 0; i < this.props.coursePrograms.length; i++) {
            if (this.props.coursePrograms[i].Code === parameter) {
                return this.props.coursePrograms[i].Description;
            }
        }
        return null;
    }
   


    onClickCourseDetails = (item) => () => {	
        const {allSearchCriteria} = this.props	
        this.props.setIsLoadingPdDetails(true);	
        this.props.setPdDetails(null);	
        this.props.selectCourse(item);	
        this.props.getPdDetails(item.masterCode, allSearchCriteria);	
        this.setState({	
            visiblePdDetails: true	
        });	
        var element_to_scroll_to = document.getElementById('courseDetailAnchor');
        //console.log("scroll: " + element_to_scroll_to);
        element_to_scroll_to.scrollIntoView({ behavior: "smooth"});
    };	
    //#endregion	
    //#region onClick Course Details Bookmark	
    onClickCourseDetailsBookmark = (item) => () => {	
        const emptySearchRequest = getEmptySearchRequest();	
        this.props.setIsLoadingPdDetails(true);	
        this.props.setPdDetails(null);	
        this.props.selectCourse(item);	
        this.props.getPdDetails(item.masterCode, emptySearchRequest);	
        this.setState({	
            visiblePdDetails: true	
        });	
        var element_to_scroll_to = document.getElementById('courseDetailAnchor');
        //console.log("scroll: " + element_to_scroll_to);
        element_to_scroll_to.scrollIntoView({ behavior: "smooth"});
    };

    //#region onClick Course Details Mobile	
    onClickCourseDetailsMobile = (item) => () => {	
        const { setIsLoadingPdDetails, selectCourse, getPdDetails, allSearchCriteria } = this.props;	
        setIsLoadingPdDetails(true);	
        selectCourse(item);	
        getPdDetails(item.masterCode, allSearchCriteria);	
        this.setState({	
            visiblePdDetails: true	
        });	
        this.seeHeadPdDetails();	
        document.body.className = "pd-details";	
        var element_to_scroll_to = document.getElementById('courseDetailAnchorMobile');
        //console.log("scroll: " + element_to_scroll_to);
        element_to_scroll_to.scrollIntoView({ behavior: "smooth"});
        	
    };	
    //#endregion	
    //#region onClick Bookmark Mobile	
    onClickBookmarkMobile = (item) => () => {	
        const { setIsLoadingPdDetails, selectCourse, getPdDetails, setVisibleListBookmarks, setCourseFromHashVisible, allSearchCriteria } = this.props;	
        const emptySearchRequest = getEmptySearchRequest();	
        setVisibleListBookmarks(true);	
        setCourseFromHashVisible(false);	
        setIsLoadingPdDetails(true);	
        selectCourse(item);	
        getPdDetails(item.masterCode, emptySearchRequest);	
        this.setState({	
            visiblePdDetails: true	
        });	
        this.seeHeadPdDetails();	
        document.body.className = "pd-details";	
        var element_to_scroll_to = document.getElementById('courseDetailAnchorMobile');
        //console.log("scroll: " + element_to_scroll_to);
        element_to_scroll_to.scrollIntoView({ behavior: "smooth"});
    };	
    //#endregion	

    arrayIncludesInObj = (arr, key, valueToCheck) => {
        return arr.some(value => value[key] === valueToCheck);
    }

    bookmark = () => {

        const { selectedCourse, selectedBookmark } = this.props;
        let arrayBookmark = [];
        arrayBookmark = this.props.selectedBookmark;
        if (this.arrayIncludesInObj(arrayBookmark, "cpabcMeeting", selectedCourse.cpabcMeeting)) {
            for (var i = 0; i < arrayBookmark.length; i++) {
                if (arrayBookmark[i].cpabcMeeting == selectedCourse.cpabcMeeting) {
                    arrayBookmark.splice(i, 1);
                    toast.info(
                        selectedCourse.title + " has been removed from bookmarks.",
                        {
                            position: "bottom-right",
                            autoClose: 4000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            transition: Zoom
                        });

                }
            }
        }
        else {
            arrayBookmark.push(this.props.selectedCourse);
            toast.info(selectedCourse.title + " has been bookmarked.", {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Zoom
            });
        }

        const finalArrayBookmark = JSON.stringify(arrayBookmark);

        //localStorage.setItem("ngStorage-bookmarksCalendar", finalArrayBookmark);
        localStorage.setItem("ngStorage-bookmarks", finalArrayBookmark);
        this.props.selectBookmark(arrayBookmark);
    }

    //#region on Sort By	
    onSortBy = (parameter) => () => {	
        const { copySearchTagVisible, tags, searchTag } = this.props;	
        if (copySearchTagVisible) {	
            const sortByValue = (parameter === "Alpha") ? 3 : 1;	
            const searchRequest = {	
                StartDate: null,	
                EndDate: null,	
                PageIndex: 0,	
                PageSize: 9,	
                CpabcVenueId: [],	
                CpabcCourseSubcategory: [],	
                DurationHours: [],	
                CpabcCourseDeliveryMethod: [],	
                CpabcCourseProgram: [],	
                EthicsHours: [],	
                EthicsHoursGreaterThan0: null,	
                CpabcCertificateProgram: [],	
                CpabcSeminarLeaderId: [],	
                PassportCredits: [],	
                Keywords: "",	
                Sort: sortByValue,	
            };	
            let listTags = [];	
            for (var i = 0; i < tags.length; i++) {	
                if (searchTag.toLowerCase() === tags[i].Description.toLowerCase()) {	
                    listTags.push(tags[i].CpaBcCourseTagCd);	
                    break;	
                }	
            }	
            if (listTags.length > 0) {	
                searchRequest.Tags = listTags;	
            }	
            setTimeout(() => {	
                this.props.searchPd(searchRequest);	
            },	
                100);	
            this.props.setSortByValue(parameter);	
            this.props.setAllSearchCriteria(searchRequest);	
            this.props.setLoadingFalse(true);	
            this.props.selectCourse(null);	
            this.props.resetSearchResultItems();	
            this.props.setVisibleListBookmarks(false);	
            this.props.setCourseFromHashVisible(false);	
            this.props.setCopySearchTagVisible(true);	
        }	
        else {	
            this.props.setSortByValue(parameter);	
            const searchCriteria = this.props.allSearchCriteria;	
            const sortByValue = (parameter === "Alpha") ? 3 : 1;	
            const finalSearchCriteria = searchCriteria ? searchCriteria : [];	
            finalSearchCriteria.Sort = sortByValue;	
            const searchRequest = {	
                StartDate: finalSearchCriteria.StartDate ? finalSearchCriteria.StartDate : null,	
                EndDate: finalSearchCriteria.EndDate ? finalSearchCriteria.EndDate : null,	
                PageIndex: 0,	
                PageSize: finalSearchCriteria.PageSize ? finalSearchCriteria.PageSize : 9,	
                CpabcVenueId: finalSearchCriteria.CpabcVenueId ? finalSearchCriteria.CpabcVenueId : [],	
                CpabcCourseSubcategory: finalSearchCriteria.CpabcCourseSubcategory ? finalSearchCriteria.CpabcCourseSubcategory : [],	
                DurationHours: finalSearchCriteria.DurationHours ? finalSearchCriteria.DurationHours : [],	
                CpabcCourseDeliveryMethod: finalSearchCriteria.CpabcCourseDeliveryMethod ? finalSearchCriteria.CpabcCourseDeliveryMethod : [],	
                CpabcCourseProgram: finalSearchCriteria.CpabcCourseProgram ? finalSearchCriteria.CpabcCourseProgram : [],	
                EthicsHours: finalSearchCriteria.EthicsHours ? finalSearchCriteria.EthicsHours : [],	
                EthicsHoursGreaterThan0: finalSearchCriteria.EthicsHoursGreaterThan0 ? finalSearchCriteria.EthicsHoursGreaterThan0 : null,	
                CpabcCertificateProgram: finalSearchCriteria.CpabcCertificateProgram ? finalSearchCriteria.CpabcCertificateProgram : [],	
                CpabcSeminarLeaderId: finalSearchCriteria.CpabcSeminarLeaderId ? finalSearchCriteria.CpabcSeminarLeaderId : [],	
                PassportCredits: finalSearchCriteria.PassportCredits ? finalSearchCriteria.PassportCredits : [],	
                Keywords: finalSearchCriteria.Keywords ? finalSearchCriteria.Keywords : "",	
                FromInit: false,	
                Sort: finalSearchCriteria.Sort,	
                NewCourses: finalSearchCriteria.NewCourses	
            }	
            const sessionStorageSearchRequest = JSON.stringify(searchRequest);	
            sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);	
            this.props.setAllSearchCriteria(searchRequest);	
            this.props.setSortByValue(parameter);	
            let device = getDeviceTypeInfo();	
            let deviceType;	
            if (device) {	
                deviceType = device.deviceType;	
            };	
            if (deviceType === "Mobile") {	
                searchRequest.deviceTypeinfo = "Mobile";	
            };	
            this.props.setLoadingFalse(true);	
            this.props.selectCourse(null);	
            this.props.resetSearchResultItems();	
            this.props.setVisibleListBookmarks(false);	
            this.props.searchPd(searchRequest);	
        }	
    };	
    //#endregion

    onSelectTag = (parameter) => () => {
    
    // okay the calendar Needs a start and end date.
                // make sure we have start and end dates
                // check for null date
                var startDate2 = new Date();

                // check for date that isn't first of the month
                // really, just set day to '1'
                // but setDate returns seconds so we won't use that
                startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

                // lets set the end date as well
                var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

                //in case it was changed above
                this.props.setStartDate(startDate2);
                this.props.setEndDate(endDate2);

                //console.log("search button start date2: " + startDate2);
                //console.log("search button end date2: " + endDate2);

                let startDateIso = startDate2 ? moment(startDate2).format() : null;
                let endDateIso = endDate2 ? moment(endDate2).format() : null;

         const emptySearchRequest = {
                    StartDate: startDateIso,
                    EndDate: endDateIso,
            PageIndex: 0,	
            PageSize: 500,	
            CpabcVenueId: [],	
            CpabcCourseSubcategory: [],	
            DurationHours: [],	
            CpabcCourseDeliveryMethod: [],	
            CpabcCourseProgram: [],	
            EthicsHours: [],	
            EthicsHoursGreaterThan0: null,	
            CpabcCertificateProgram: [],	
            CpabcSeminarLeaderId: [],	
            PassportCredits: [],	
            Keywords: "",	
            Sort: 1,	
            Tags: [parameter]	
        };	
        const sessionStorageSearchRequest = JSON.stringify(searchRequest);	
        sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);	
        this.props.setAllSearchCriteria(searchRequest);	
        let tagDescription = this.getDescriptionTag(parameter);	
        this.props.setSearchTag(tagDescription);	
        setTimeout(() => {	
                    this.props.searchPd(searchRequest);	
                },	
            100);	
        this.props.setAllSearchCriteria(searchRequest);	
        this.props.setLoadingFalse(true);	
        this.props.selectCourse(null);	
        this.props.resetSearchResultItems();	
        this.props.setVisibleListBookmarks(false);	
        this.props.setCourseFromHashVisible(false);	
        this.props.setCopySearchTagVisible(true);	
        	
    };

    getDescriptionTag = (parameter) => {	
        const {tags} = this.props;	
        for (let i = 0; i < tags.length; i++) {	
            if (tags[i].CpaBcCourseTagCd === parameter) {	
                return tags[i].Description;	
            }	
        }	
        return "Unknown";	
    }

    // CALENDAR VARIABLES AND FUNCTIONS START /////////////////////////////////////////////////////////////////////

    calculateNumDaysInMonth = function (month, year) {
        return new Date(year, month + 1, 0).getDate();  // previous months last day, zero indexed 
    }

    calculateFirstDayOfMonth = function (month, year) {  // datetime
        return new Date(year, month, 1);
    };

    calculateCellNumberOfMonthStart = function (month, year) {
        return this.calculateFirstDayOfMonth(month, year).getDay();  // 0-based
    };

    
    calculateNumDateCells = function (cellNumberOfMonthStart, numDaysInMonth) {
        var numDateCells = cellNumberOfMonthStart + numDaysInMonth;
        if ((numDateCells % 7) != 0) {
            numDateCells = numDateCells + (7 - (numDateCells % 7));
        }
        return numDateCells;
    }

    
    calculateDayIndex = function (cellIndex, cellNumberOfMonthStart) {  // 1-based cause this is the DAY of the month
        return (cellIndex < cellNumberOfMonthStart) ? 0 : (cellIndex - cellNumberOfMonthStart) + 1;
    };

    
    numDateCellsLoop = function (numDateCells) {  // 0-based loop
        return Array.from(new Array(numDateCells).keys());
    };


    calculateCellStyle = function (cellNumberOfMonthStart, numDaysInMonth, currentDisplayMonth, cellCounter, dayCounter) {
        var today = new Date();
        var cellStyle = "CpabcCalendarDefault";

        if ((currentDisplayMonth.getFullYear() < today.getFullYear()) || ((currentDisplayMonth.getMonth() < today.getMonth()) && (currentDisplayMonth.getFullYear() == today.getFullYear()))) {
            cellStyle = "CpabcCalendarBeforeCurrentCell";
        } else if ((currentDisplayMonth.getFullYear() > today.getFullYear()) || ((currentDisplayMonth.getMonth() > today.getMonth()) && (currentDisplayMonth.getFullYear() == today.getFullYear()))) {
            cellStyle = "CpabcCalendarAfterCurrentCell";
        }

        if ((currentDisplayMonth.getMonth() == today.getMonth()) && (currentDisplayMonth.getFullYear() == today.getFullYear())) {
            // today
            if (dayCounter == today.getDate()) {
                cellStyle = "CpabcCalendarCurrentCell";
            } else if (cellCounter < cellNumberOfMonthStart) {
                // empty cells before calendar
                cellStyle = "CpabcCalendarBeforeCurrentCell";
            } else if (cellCounter >= (numDaysInMonth + cellNumberOfMonthStart)) {
                // empty cells after calendar
                cellStyle = "CpabcCalendarAfterCurrentCell";
            } else if (dayCounter < today.getDate()) {
                // calendar before today's date
                cellStyle = "CpabcCalendarBeforeCurrentCell";
            } else if (dayCounter >= today.getDate()) {
                // calendar on or after today's date
                cellStyle = "CpabcCalendarAfterCurrentCell";
            } else {
                // oops can't figure out the correct style for this cell
                cellStyle = "CpabcCalendarAfterCurrentCell";
            }
        }
        return cellStyle;
    };

    checkDisplayOldCoursesToday = function (displayOldCourses, dayCounter) {

        if (displayOldCourses) {
            return true;
        }
        if (new Date() >= new Date(this.props.startDateForCalendar.getFullYear(), this.props.startDateForCalendar.getMonth(), dayCounter)) {
            return false;
        }
        return true;
    }


    //#region onClick calendar cell
    displayCoursesForDay(theDay) {
        //console.log("test click: " + theDay);
        this.setState({
            dayChosen: theDay
            //listBookmarksVisible: false
        });
        this.props.setVisibleListBookmarks(false);
        //console.log("TIME chosen set ");
    }
    displayCoursesForDayMobile(theDay) {
        //console.log("test click: " + theDay);
        this.setState({
            dayChosen: theDay
            //listBookmarksVisible: false
        });
        this.props.setVisibleListBookmarks(false);
        //console.log("TIME chosen set ");
        var element_to_scroll_to = document.getElementById('courseCardAnchor');
        //console.log("scroll: " + element_to_scroll_to);
        element_to_scroll_to.scrollIntoView({ behavior: "smooth" });
    }

    

    // CALENDAR VARIABLES AND FUNCTIONS END /////////////////////////////////////////////////////////////////////

    render() {
        const {
            selectedCourse,
            selectedBookmark,
            listBookmarksVisible,
            pdDetails,
            availableButtonLabel,
            availableButtonLink,
            cancelledButtonLabel,
            cancelledButtonLink,
            closedButtonLabel,
            closedButtonLink,
            fullButtonLabel,
            fullButtonLink,
            pastButtonLabel,
            pastButtonLink,
            seminarLevels,
            coursePrograms,
            courseFromHashVisible,
            ideasTitle,
            ideasAbstract,
            ideasIcon,
            ideasLink,
            leaders,
            tags,
            locations,
            isLoadingPdDetails,
            setPdDetails,	
            selectCourse,	
            setVisibleListBookmarks,	
            setCourseFromHashVisible,	
            getPdDetails,	
            setIsLoadingPdDetails,
        } = this.props;
        
        //const tags = new Array(); // only used to test a problem with tags

        //console.log("selected bookmark: " + selectedBookmark);

        //let listBookmarksVisible = false;
        //if (this.state.dayChosen == 0) {
        //    listBookmarksVisible = this.props.listBookmarksVisible;
        //}

        //console.log("TIME render: " + this.state.listBookmarksVisible);

        const { searchResult } = this.props;

        let items = [];
        let itemsTestimonials = [];
        if (pdDetails) {	
            if (pdDetails.currentCourse.testimonials) {	
                items = pdDetails.currentCourse.testimonials.length > 0 ? pdDetails.currentCourse.testimonials : [];
                for (var i = 0; i < items.length; i++) {
                    itemsTestimonials.push({
                        id: i + 1,
                        caption: items[i]
                    })
                }
            }
            
        }

        // CALENDAR STUFF START //////////////////////////////////////////////////////////////////////////////////

        // ugh I need to know what calendar month is being displayed.
        // the items will have the correct month+year, so get it from them.
        let currentDisplayMonth = this.props.startDateForCalendar;
        let numDaysInMonth = 0;
        let firstDayOfMonth = null;
        let cellNumberOfMonthStart = 0;
        let numDateCells = 0;
        

        if (currentDisplayMonth != null) {
            numDaysInMonth = this.calculateNumDaysInMonth(currentDisplayMonth.getMonth(), currentDisplayMonth.getFullYear());
            firstDayOfMonth = this.calculateFirstDayOfMonth(currentDisplayMonth.getMonth(), currentDisplayMonth.getFullYear());
            cellNumberOfMonthStart = this.calculateCellNumberOfMonthStart(currentDisplayMonth.getMonth(), currentDisplayMonth.getFullYear());
            numDateCells = this.calculateNumDateCells(cellNumberOfMonthStart, numDaysInMonth);
        }

        //console.log("current display month: " + currentDisplayMonth);
        //console.log("numDaysInMonth: " + numDaysInMonth);
        //console.log("firstDayOfMonth: " + firstDayOfMonth);
        //console.log("cellNumberOfMonthStart: " + cellNumberOfMonthStart);
        //console.log("numDateCells: " + numDateCells);
        //console.log("date index for cellIndex 5: " + this.calculateDayIndex(5, cellNumberOfMonthStart));
        //console.log("num date cells loop: " + this.numDateCellsLoop(numDateCells));

        // we're incrementing this at the start of the loop
        // set to -1 so it gets increment to '0' for the first cell
        // note: cells are zero based.
        let cellCounterMobile = -1;
        let cellCounter = -1;

        // incremented for each actual day of the month
        // note days are 1 based
        let dayCounter = 1;
        let dayCounterMobile = 1;
        //let courseCounter = -1;

        if ((searchResult != null)) {
            //console.log("items: " + searchResult.items.length);
        }
        else {
            //console.log("items = 0");
        }

        let itemsDailyKeys = new Array();
        for (i = 1; i < 32; i++) {
            itemsDailyKeys[i] = new Array();
        }
        /*
        for (i = 0; i < searchResult.items.length; i++) {

            if (searchResult.items[i].cpabcCourseDeliveryMethod !== "OD") {
                itemsDailyKeys[new Date(searchResult.items[i].beginDate).getDate()].push(i);
                //console.log("begin date:  " + i + "|" + searchResult.items[i].beginDate + "|" + searchResult.items[i].cpabcCourseDeliveryMethod);
            }
        }*/

        
        var currentDate = new Date();
        searchResult.items.map((item, index) => {
            if ((item.cpabcCourseDeliveryMethod !== "OD") && (item.availableDates != null) && (item.availableDates.length > 0) && (item.availableDates[0].beginTime != null))  {
                item.availableDates.map((aCourse, index2) => {
                    itemsDailyKeys[new Date(aCourse.beginDate).getDate()].push(index + (index2 / 100));
                    //console.log("testing current day 2222222:  " + aCourse.beginDate + "|" + new Date(aCourse.beginDate).getDate() + "|" + aCourse.title);
                })
            } else if ((item.cpabcCourseDeliveryMethod !== "OD") && (moment(item.beginDate).format("YYYY") == currentDate.getFullYear() && (moment(item.beginDate).format("MM") - 1) == currentDate.getMonth() && moment(item.beginDate).format("DD") == currentDate.getDate())) {
                itemsDailyKeys[new Date(item.beginDate).getDate()].push(index);
                //console.log("testing current day 111111111111:  " + item.beginDate + "|" + new Date(item.beginDate).getDate() + "|" + item.title);
            }
        })
        //console.log("8888888888888888888888888888888888888");


        for (i = 1; i < 32; i++) {
            //console.log("test keys array:  " + i + "|" + itemsDailyKeys[i].length + "|" + itemsDailyKeys[i]);
        }


        let dayOfWeekIndexes = new Array(0, 1, 2, 3, 4, 5, 6);
        let weekOfMonthIndexes = new Array();

        for (var i = 0; i < (numDateCells/7); i++) {
            weekOfMonthIndexes.push(i);
        }
        //console.log("SEARCH RESULT RENDER ... index: " + numDateCells + "|" + weekOfMonthIndexes + "|" + numDateCells / 7);

        // CALENDAR STUFF END //////////////////////////////////////////////////////////////////////////////////

        return <div className="search-result bg-grey">
            <div className="row">
                <a name="courseDetailAnchorMobile" id="courseDetailAnchorMobile"></a>
                <div className="col px-0">
                    <div className="d-flex flex-column flex-lg-row mb-4">  
                        <div className="search-items bg-white" ref={this.searchItemsRef}>
                        <Responsive displayIn={["Mobile"]}>	
                        <a name="courseCardAnchor" id="courseCardAnchorMobile"></a>
                                {true &&	
                                    <div className="bookmark-mobile">	
                                        <BookmarkDropdown	
                                            selectedBookmark={selectedBookmark}	
                                            selectCourse={selectCourse}	
                                            setVisibleListBookmarks={setVisibleListBookmarks}	
                                            setCourseFromHashVisible={setCourseFromHashVisible}	
                                            getPdDetails={getPdDetails}	
                                            setIsLoadingPdDetails={setIsLoadingPdDetails}	
                                            onClickBookmarkMobile={this.onClickBookmarkMobile}	
                                        />	
                                    </div>	
                                }	
                            </Responsive>
                            <a href={ideasLink}>
                                <div className="ideas-card d-none d-sm-block mb-4">
                                    <div className="d-flex">
                                        <div className="mr-2 idea-icon">
                                            <i className={ideasIcon}></i>
                                        </div>
                                        <div className="eq">
                                            <div className="title">
                                                PD View-by-Month Course Calendar
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Use this view-by-month calendar to navigate through the courses available in each month.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {!searchResult.isLoading &&
                                <React.Fragment>
                                <Responsive displayIn={["Mobile"]}>
                                            <div className="card course" style={{ width: "100%" }}>
                                                <div className="card-body">
                                                    <div className="course-title" style={{ border: "none" }}>
                                                        <div className="d-flex flex-column flex-sm-row align-items-center side-icon-wrapper">


                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="header-results">
                                                                            <div class="d-flex">


                                                                                <div class="mr-auto header-title-container">
                                                                                    <h3 class="header-title" style={{ color: "#000000" }}>PD View-by-Month Course Calendar {currentDisplayMonth !== null && <span> — {moment(currentDisplayMonth).format("MMM YYYY")}</span>}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div>
                                                                    {weekOfMonthIndexes
                                                                        .map((keyWeek) => {

                                                                            return (
                                                                                <div class="row">
                                                                                    {dayOfWeekIndexes
                                                                                        .map((keyDay) => {
                                                                                            cellCounterMobile++;

                                                                                            let numCourses = 0;
                                                                                            if ((cellCounterMobile >= cellNumberOfMonthStart) && (dayCounterMobile <= numDaysInMonth) && (itemsDailyKeys[dayCounterMobile] != null)) {
                                                                                                numCourses = itemsDailyKeys[dayCounterMobile].length;
                                                                                            }
                                                                                            let cellStyle = this.calculateCellStyle(cellNumberOfMonthStart, numDaysInMonth, currentDisplayMonth, cellCounterMobile, dayCounterMobile);
                                                                                            let displayOldCourses = false;
                                                                                            //console.log("test77: " + cellStyle);
                                                                                            return (

                                                                                                <div class={"col calendarCell " + cellStyle}>
                                                                                                    {cellCounterMobile >= cellNumberOfMonthStart && dayCounterMobile <= numDaysInMonth &&
                                                                                                        <div><span class="calendarDayIndex calendarDayIndexText">{dayCounterMobile++}</span>
                                                                                                        {!this.checkDisplayOldCoursesToday(displayOldCourses, dayCounterMobile) && <p>&nbsp;</p>}
                                                                                                        {this.checkDisplayOldCoursesToday(displayOldCourses, dayCounterMobile) &&
                                                                                                                <>
                                                                                                            {(numCourses > 0) && <div class="calendarNumberCoursesForMobileContainer"><div class="calendarNumberCoursesForMobile" onClick={cellCounterMobile >= cellNumberOfMonthStart && (dayCounterMobile - 1) < numDaysInMonth && this.displayCoursesForDayMobile.bind(this, dayCounterMobile - 1)}>{numCourses}</div></div>}
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }



                                                                                                </div>
                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )

                                                                        })
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </Responsive>
                                    
                                </React.Fragment>
                            }
























                            	
                            {!listBookmarksVisible && searchResult.isLoading && !courseFromHashVisible && !this.state.loadingMore &&	
                                <LoadingIndicator />	
                            }

                            {!listBookmarksVisible && this.state.dayChosen != 0 &&
                                <React.Fragment>
                                <a name="courseCardAnchor" id="courseCardAnchor"></a>
                                {/*
                                    {!searchResult.isLoading && !courseFromHashVisible &&
                                        <div className="d-flex sort-by-and-result-count mb-3">
                                            <div className="founds">
                                                <span>Found {searchResult.totalCount} results</span>
                                            </div>
                                            <div className=" sortby">
                                                {searchResult.totalCount > 0 &&
                                                    <div className="d-flex">
                                                        <div className="sortby-label ">
                                                            <span className="lbl-uppercase va-bottom d-none d-sm-block">Sort By:</span>
                                                        </div>
                                                        <div className="dropdown filter-dropdown show">
                                                            <a className="btn btn-secondary dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                {this.props.sortBy} </a>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <a className="dropdown-item" href="javascript:void(0);" onClick={this.onSortBy('Alpha')}>Alpha</a>
                                                                <a className="dropdown-item" href="javascript:void(0);" onClick={this.onSortBy('Date')}>Date</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                }
                                */}
                                    <Responsive displayIn={["Tablet", "Laptop"]}>
                                        <div className="cards" ref={this.cardsContainerRef}>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.loadMore}
                                                hasMore={this.props.searchResult.totalCount > this.props.searchResult.items.length && !this.state.loadingMore && !searchResult.isLoading}
                                                useWindow={false}
                                            className={"infinite-scroll"}>



                                            {itemsDailyKeys[this.state.dayChosen]
                                                .map((key) => {


                                                    //unpack the key
                                                    let itemsIndex2 = Math.floor(key);
                                                    let availableDatesIndex2 = Math.round((key - itemsIndex2) * 100);
                                                    

                                                    let beginDate2;
                                                    let endDate2;
                                                    let checkChosenDate;
                                                    let currentItem;
                                                    if ((searchResult.items[itemsIndex2].availableDates != null) && (searchResult.items[itemsIndex2].availableDates.length > 0) && (searchResult.items[itemsIndex2].availableDates[0].beginTime != null)) {
                                                        beginDate2 = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].beginDate).format("MMM DD, YYYY");
                                                        endDate2 = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].endDate).format("MMM DD, YYYY");
                                                        checkChosenDate = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].beginDate).format("D");
                                                        currentItem = searchResult.items[itemsIndex2].availableDates[availableDatesIndex2];
                                                        
                                                    } else {
                                                        beginDate2 = moment(searchResult.items[itemsIndex2].beginDate).format("MMM DD, YYYY");
                                                        endDate2 = moment(searchResult.items[itemsIndex2].endDate).format("MMM DD, YYYY");
                                                        checkChosenDate = moment(searchResult.items[itemsIndex2].beginDate).format("D");
                                                        currentItem = searchResult.items[itemsIndex2];
                                                    }
                                                    //console.log("keys2: " + key + "|" + itemsIndex2 + "|" + availableDatesIndex2 + "|" + checkChosenDate + "|" + this.state.dayChosen);




                                                        //let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                        return (
                                                            <>
                                                                {
                                                                    this.state.dayChosen == this.state.dayChosen &&
                                                                    <div key={currentItem.cpabcMeeting} className={"card search-pd" + " left-margin-color-" + (currentItem.cpabcCourseSubject ? currentItem.cpabcCourseSubject.toLowerCase() : "") + ((currentItem != null && selectedCourse != null && currentItem.masterCode == selectedCourse.masterCode) ? " bg-grey active" : "")} onClick={this.onClickCourseDetails(currentItem)} id={currentItem.cpabcMeeting}>
                                                                <div className="card-body">
                                                                            {currentItem.cpabcCourseSubject &&
                                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(currentItem.cpabcCourseSubject)}</div>
                                                                    }
                                                                            {currentItem.title &&
                                                                                    <div className="course-title">{currentItem.title}</div>
                                                                    }
                                                                    <div className="row pt-3">
                                                                                {currentItem.cpdHours &&
                                                                            <div className="col-5">
                                                                                    <span className="label-number">{currentItem.cpdHours}</span>
                                                                                <span className="medium"> CPD </span>
                                                                                <span className="small">Hours</span>
                                                                            </div>
                                                                                }
                                                                                
                                                                            <div className="col-7">
                                                                                    {currentItem.passportUnit === 0 &&
                                                                                    <span className="label-black">N/A Passport Credits</span>	
                                                                                }
                                                                                    {currentItem.passportUnit != 0 &&
                                                                                    <>
                                                                                        <span className="label-number">{currentItem.passportUnit}</span>
                                                                                        <span className="text-grey medium"> Passport </span>
                                                                                        <span className="small">Credits</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        
                                                                    </div>
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {selectedCourse &&
                                                                                <div className="prerequisite-container">
                                                                                    <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                        {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: searchResult.items[itemsIndex2].prerequisites }}></div></Dotdotdot>}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                        <div className="d-flex cell mr-3">
                                                                            <div className="date">
                                                                                {beginDate2 &&
                                                                                    <span className="label-black">{beginDate2}</span>
                                                                                }
                                                                            </div>
                                                                            <div className="time">
                                                                                        {currentItem.beginTime && currentItem.endTime &&
                                                                                            <span className="label-black">{currentItem.beginTime} - {currentItem.endTime}</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                        <div className="delivery-method">
                                                                                    {currentItem.cpabcCourseDeliveryMethod &&
                                                                                        <span className="label-black">{this.getDeliveryMethod(currentItem.cpabcCourseDeliveryMethod)}</span>
                                                                            }
                                                                        </div>
                                                                                <div className="course-status">
                                                                                    <span className={"label-black label-status " + (currentItem.courseStatus ? currentItem.courseStatus.toLowerCase() : "")}>{currentItem.courseStatus}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                }
                                                            </>

                                                        )
                                                    })
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </Responsive>
                                    <Responsive displayIn={["Mobile"]}>	
                                        <div className="cards" ref={this.cardsContainerRef}>	
                                            <InfiniteScroll	
                                                pageStart={0}	
                                                loadMore={this.loadMore}	
                                                hasMore={this.props.searchResult.totalCount > this.props.searchResult.items.length && !this.state.loadingMore}	
                                                className={"infinite-scroll"}>	
                                            {itemsDailyKeys[this.state.dayChosen]
                                                .map((key) => {


                                                    //unpack the key
                                                    let itemsIndex2 = Math.floor(key);
                                                    let availableDatesIndex2 = Math.round((key - itemsIndex2) * 100);


                                                    let beginDate2;
                                                    let endDate2;
                                                    let checkChosenDate;
                                                    let currentItem;
                                                    if ((searchResult.items[itemsIndex2].availableDates != null) && (searchResult.items[itemsIndex2].availableDates.length > 0) && (searchResult.items[itemsIndex2].availableDates[0].beginTime != null)) {
                                                        beginDate2 = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].beginDate).format("MMM DD, YYYY");
                                                        endDate2 = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].endDate).format("MMM DD, YYYY");
                                                        checkChosenDate = moment(searchResult.items[itemsIndex2].availableDates[availableDatesIndex2].beginDate).format("D");
                                                        currentItem = searchResult.items[itemsIndex2].availableDates[availableDatesIndex2];

                                                    } else {
                                                        beginDate2 = moment(searchResult.items[itemsIndex2].beginDate).format("MMM DD, YYYY");
                                                        endDate2 = moment(searchResult.items[itemsIndex2].endDate).format("MMM DD, YYYY");
                                                        checkChosenDate = moment(searchResult.items[itemsIndex2].beginDate).format("D");
                                                        currentItem = searchResult.items[itemsIndex2];
                                                    }
                                                    //console.log("keys3: " + key + "|" + itemsIndex2 + "|" + availableDatesIndex2 + "|" + checkChosenDate + "|" + this.state.dayChosen);
                                                        return (
                                                        <>
                                                                {
                                                                    this.state.dayChosen == this.state.dayChosen &&
                                                                    <div key={currentItem.cpabcMeeting} className={"card search-pd" + " left-margin-color-" + (currentItem.cpabcCourseSubject ? currentItem.cpabcCourseSubject.toLowerCase() : "") + ((currentItem != null && selectedCourse != null && currentItem.masterCode == selectedCourse.masterCode) ? " bg-grey active" : "")} onClick={this.onClickCourseDetailsMobile(currentItem)} id={currentItem.cpabcMeeting}>
                                                                        <div className="card-body">
                                                                            {currentItem.cpabcCourseSubject &&
                                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(currentItem.cpabcCourseSubject)}</div>
                                                                            }
                                                                            {currentItem.title &&
                                                                                    <div className="course-title">{currentItem.title} </div>
                                                                            }
                                                                            <div className="row pt-3">
                                                                                {currentItem.cpdHours &&
                                                                                    <div className="col-5">
                                                                                        <span className="label-number">{currentItem.cpdHours}</span>
                                                                                        <span className="medium"> CPD </span>
                                                                                        <span className="small">Hours</span>
                                                                                    </div>
                                                                                }

                                                                                <div className="col-7">
                                                                                    {currentItem.passportUnit === 0 &&
                                                                                        <span className="label-black">N/A Passport Credits</span>
                                                                                    }
                                                                                    {currentItem.passportUnit != 0 &&
                                                                                        <>
                                                                                            <span className="label-number">{currentItem.passportUnit}</span>
                                                                                            <span className="text-grey medium"> Passport </span>
                                                                                            <span className="small">Credits</span>
                                                                                        </>
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                            <div className="row pt-1">
                                                                                <div className="col">
                                                                                    {selectedCourse &&
                                                                                        <div className="prerequisite-container">
                                                                                            <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                            {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: searchResult.items[itemsIndex2].prerequisites }}></div></Dotdotdot>}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                <div className="d-flex cell mr-3">
                                                                                    <div className="date">
                                                                                        {beginDate2 &&
                                                                                            <span className="label-black">{beginDate2}</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="time">
                                                                                        {currentItem.beginTime && currentItem.endTime &&
                                                                                            <span className="label-black">{currentItem.beginTime} - {currentItem.endTime}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                <div className="delivery-method">
                                                                                    {currentItem.cpabcCourseDeliveryMethod &&
                                                                                        <span className="label-black">{this.getDeliveryMethod(currentItem.cpabcCourseDeliveryMethod)}</span>
                                                                                    }
                                                                                </div>
                                                                                <div className="course-status">
                                                                                    <span className={"label-black label-status " + (currentItem.courseStatus ? currentItem.courseStatus.toLowerCase() : "")}>{currentItem.courseStatus}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            </>
                                                        )	
                                                    })	
                                                }	
                                            </InfiniteScroll>	
                                        </div>	
                                    </Responsive>
                                </React.Fragment>
                            }

                            {!listBookmarksVisible && searchResult.isLoading && !courseFromHashVisible && this.state.loadingMore &&	
                                <LoadingIndicator />	
                            }
                            {listBookmarksVisible && searchResult.isLoading &&
                                <>
                                    <span>Bookmarked seminars ...</span>
                                    <LoadingIndicator />
                                </>
                            }

                            {listBookmarksVisible && !searchResult.isLoading &&
                               <React.Fragment>	
                                    <Responsive displayIn={["Tablet", "Laptop"]}>	
                                        <span className="bookmarked mb-3 pt-1">Bookmarked seminars - {selectedBookmark.length}</span>	
                                        <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>	
                                            {selectedBookmark	
                                                .map((item) => {	
                                                    let beginDate = moment(item.beginDate).format("MMM DD, YYYY");	
                                                    return (	
                                                        <div className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item == selectedCourse) ? " bg-grey" : "")} onClick={this.onClickCourseDetailsBookmark(item)} id={item.cpabcMeeting}>	
                                                            <div className="card-body">	
                                                                {item.cpabcCourseSubject &&	
                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>	
                                                                }	
                                                                {item.title &&	
                                                                    <div className="course-title">{item.title}</div>	
                                                                }	
                                                                <div className="row">	
                                                                    {item.cpdHours &&	
                                                                        <div className="col-5">	
                                                                            <span className="label-number">{item.cpdHours}</span>	
                                                                            <span className="medium"> CPD </span>	
                                                                            <span className="small">Hours</span>	
                                                                        </div>	
                                                                    }	
                                                                    {item.beginDate >= '2020-09-01' &&	
                                                                        <div className="col-7">	
                                                                            {item.passportUnit === 0 &&	
                                                                                <span className="label-black">N/A Passport Credits</span>	
                                                                            }	
                                                                            {item.passportUnit != 0 &&	
                                                                                <>	
                                                                                    <span className="label-number">{item.passportUnit}</span>	
                                                                                    <span className="text-grey medium"> Passport </span>	
                                                                                    <span className="small">Credits</span>	
                                                                                </>	
                                                                            }	
                                                                        </div>	
                                                                    }	
                                                                </div>	
                                                                <div className="row pt-1">	
                                                                    <div className="col">	
                                                                        {selectedCourse &&	
                                                                            <div className="prerequisite-container">	
                                                                                <div className="checkbox-icon is-checked">Prerequisite</div>	
                                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}	
                                                                            </div>	
                                                                        }	
                                                                    </div>	
                                                                </div>	
                                                                {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&	
                                                                    item.availableDates	
                                                                        .map((item, index) => {	
                                                                            return (	
                                                                                <React.Fragment>	
                                                                                    {index === 0 &&	
                                                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                                                            <div className="d-flex cell mr-3">	
                                                                                                <div className="date">	
                                                                                                    {item.beginDate &&	
                                                                                                        <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>	
                                                                                                    }	
                                                                                                </div>	
                                                                                                <div className="time">	
                                                                                                    {item.beginTime && item.endTime &&	
                                                                                                        <span className="label-black">{item.beginTime} - {item.endTime}</span>	
                                                                                                    }	
                                                                                                </div>	
                                                                                            </div>	
                                                                                        </div>	
                                                                                    }	
                                                                                </React.Fragment>	
                                                                            );	
                                                                        })	
                                                                }	
                                                                {item.availableDates && item.availableDates.length > 0
                                                                    && item.availableDates[0].beginTime === null
                                                                        && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {item.cpabcCourseProgram === "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                            }
                                                                            {item.cpabcCourseProgram !== "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }	
                                                                {item.availableDates && item.availableDates.length === 0 &&	
                                                                    <div className="row pt-1">	
                                                                        <div className="col">	
                                                                            <div className="on-demande-container">	
                                                                                No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates	
                                                                                    </div>	
                                                                        </div>	
                                                                    </div>	
                                                                }	
                                                                <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&	
                                                                        <div className="delivery-method">	
                                                                            {item.cpabcCourseDeliveryMethod &&	
                                                                                <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>	
                                                                            }	
                                                                        </div>	
                                                                    }	
                                                                    {item.availableDates && item.availableDates.length > 0 &&	
                                                                        <div className="course-status">	
                                                                            <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>	
                                                                        </div>	
                                                                    }	
                                                                </div>	
                                                            </div>	
                                                        </div>	
                                                    )	
                                                })	
                                            }	
                                        </div>	
                                    </Responsive>

                                    <Responsive displayIn={["Mobile"]}>	
                                        <span className="bookmarked mb-3 pt-1">Bookmarked seminars - {selectedBookmark.length}</span>	
                                        <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>	
                                            {selectedBookmark	
                                                .map((item) => {	
                                                    let beginDate = moment(item.beginDate).format("MMM DD, YYYY");	
                                                    return (	
                                                        <div className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item == selectedCourse) ? " bg-grey" : "")} onClick={this.onClickCourseDetailsBookmark(item)} id={item.cpabcMeeting}>	
                                                            <div className="card-body">	
                                                                {item.cpabcCourseSubject &&	
                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>	
                                                                }	
                                                                {item.title &&	
                                                                    <div className="course-title">{item.title}</div>	
                                                                }	
                                                                <div className="row">	
                                                                    {item.cpdHours &&	
                                                                        <div className="col-5">	
                                                                            <span className="label-number">{item.cpdHours}</span>	
                                                                            <span className="medium"> CPD </span>	
                                                                            <span className="small">Hours</span>	
                                                                        </div>	
                                                                    }	
                                                                    {item.beginDate >= '2020-09-01' &&	
                                                                        <div className="col-7">	
                                                                            {item.passportUnit === 0 &&	
                                                                                <span className="label-black">N/A Passport Credits</span>	
                                                                            }	
                                                                            {item.passportUnit != 0 &&	
                                                                                <>	
                                                                                    <span className="label-number">{item.passportUnit}</span>	
                                                                                    <span className="text-grey medium"> Passport </span>	
                                                                                    <span className="small">Credits</span>	
                                                                                </>	
                                                                            }	
                                                                        </div>	
                                                                    }	
                                                                </div>	
                                                                <div className="row pt-1">	
                                                                    <div className="col">	
                                                                        {selectedCourse &&	
                                                                            <div className="prerequisite-container">	
                                                                                <div className="checkbox-icon is-checked">Prerequisite</div>	
                                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}	
                                                                            </div>	
                                                                        }	
                                                                    </div>	
                                                                </div>	
                                                                {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&	
                                                                    item.availableDates	
                                                                        .map((item, index) => {	
                                                                            return (	
                                                                                <React.Fragment>	
                                                                                    {index === 0 &&	
                                                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                                                            <div className="d-flex cell mr-3">	
                                                                                                <div className="date">	
                                                                                                    {item.beginDate &&	
                                                                                                        <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>	
                                                                                                    }	
                                                                                                </div>	
                                                                                                <div className="time">	
                                                                                                    {item.beginTime && item.endTime &&	
                                                                                                        <span className="label-black">{item.beginTime} - {item.endTime}</span>	
                                                                                                    }	
                                                                                                </div>	
                                                                                            </div>	
                                                                                        </div>	
                                                                                    }	
                                                                                </React.Fragment>	
                                                                            );	
                                                                        })	
                                                                }	
                                                                {item.availableDates && item.availableDates.length > 0
                                                                    && item.availableDates[0].beginTime === null
                                                                        && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {item.cpabcCourseProgram === "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                            }
                                                                            {item.cpabcCourseProgram !== "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }		
                                                                {item.availableDates && item.availableDates.length === 0 &&	
                                                                    <div className="row pt-1">	
                                                                        <div className="col">	
                                                                            <div className="on-demande-container">	
                                                                                No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates	
                                                                                    </div>	
                                                                        </div>	
                                                                    </div>	
                                                                }	
                                                                <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&	
                                                                        <div className="delivery-method">	
                                                                            {item.cpabcCourseDeliveryMethod &&	
                                                                                <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>	
                                                                            }	
                                                                        </div>	
                                                                    }	
                                                                    {item.availableDates && item.availableDates.length > 0 &&	
                                                                        <div className="course-status">	
                                                                            <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>	
                                                                        </div>	
                                                                    }	
                                                                </div>	
                                                            </div>	
                                                        </div>	
                                                    )	
                                                })	
                                            }	
                                        </div>	
                                    </Responsive>	
                                </React.Fragment>

                            }

                            {/*
                            {courseFromHashVisible &&	
                                <Responsive displayIn={["Tablet", "Laptop"]}>	
                                    <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>	
                                        {isLoadingPdDetails &&	
                                            <div className="mt-4">	
                                                <LoadingIndicator />	
                                            </div>	
                                        }	
                                        {selectedCourse && !isLoadingPdDetails &&	
                                            <div className={"card search-pd" + " left-margin-color-" + (selectedCourse.cpabcCourseSubject ? selectedCourse.cpabcCourseSubject.toLowerCase() : "") + " bg-grey active"}>	
                                                <div className="card-body">	
                                                    {selectedCourse.cpabcCourseSubject &&	
                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}</div>	
                                                    }	
                                                    {selectedCourse.title &&	
                                                        <div className="course-title">{selectedCourse.title}</div>	
                                                    }	
                                                    <div className="row">	
                                                        {selectedCourse.cpdHours > 0 &&	
                                                            <div className="col-5">	
                                                                <span className="label-number">{selectedCourse.cpdHours}</span>	
                                                                <span className="medium"> CPD </span>	
                                                                <span className="small">Hours</span>	
                                                            </div>	
                                                        }	
                                                        {selectedCourse.beginDate >= '2020-09-01' &&	
                                                            <div className="col-7">	
                                                                {selectedCourse.passportUnit === 0 &&	
                                                                    <span className="label-black">N/A Passport Credits</span>	
                                                                }	
                                                                {selectedCourse.passportUnit != 0 &&	
                                                                    <>	
                                                                        <span className="label-number">{selectedCourse.passportUnit}</span>	
                                                                        <span className="text-grey medium"> Passport </span>	
                                                                        <span className="small">Credits</span>	
                                                                    </>	
                                                                }	
                                                            </div>	
                                                        }	
                                                    </div>	
                                                    <div className="row pt-1">	
                                                        <div className="col">	
                                                            <div className="prerequisite-container">	
                                                                <div className="checkbox-icon is-checked">Prerequisite</div>	
                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisites }}></div></Dotdotdot>}	
                                                            </div>	
                                                        </div>	
                                                    </div>	
                                                    {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&	
                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                            <div className="d-flex cell mr-3">	
                                                                <div className="date">	
                                                                    {selectedCourse.beginDate &&	
                                                                        <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>	
                                                                    }	
                                                                </div>	
                                                                <div className="time">	
                                                                    {selectedCourse.beginTime && selectedCourse.endTime &&	
                                                                        <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>	
                                                                    }	
                                                                </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    {selectedCourse && selectedCourse.courseStatus != "Past" && selectedCourse.beginTime === null &&	
                                                        <div className="row pt-1">	
                                                            <div className="col">	
                                                                <div className="on-demande-container">	
                                                                    Available on-demand. 90-day access from purchase date.	
                                                                                    </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    {selectedCourse && selectedCourse.courseStatus === "Past" &&	
                                                        <div className="row pt-1">	
                                                            <div className="col">	
                                                                <div className="on-demande-container">	
                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates	
                                                                                    </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                        {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&	
                                                            <div className="delivery-method">	
                                                                {selectedCourse.cpabcCourseDeliveryMethod &&	
                                                                    <span className="label-black">{this.getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>	
                                                                }	
                                                            </div>	
                                                        }	
                                                        {selectedCourse && selectedCourse.courseStatus != "Past" &&	
                                                            <div className="course-status">	
                                                                <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>	
                                                            </div>	
                                                        }	
                                                    </div>	
                                                </div>	
                                            </div>	
                                        }	
                                    </div>	
                                </Responsive>	
                            }	

                             {courseFromHashVisible &&	
                                <Responsive displayIn={["Mobile"]}>	
                                    <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>	
                                        {isLoadingPdDetails &&	
                                            <div className="mt-4">	
                                                <LoadingIndicator />	
                                            </div>	
                                        }	
                                        {selectedCourse && !isLoadingPdDetails &&	
                                            <div className={"card search-pd" + " left-margin-color-" + (selectedCourse.cpabcCourseSubject ? selectedCourse.cpabcCourseSubject.toLowerCase() : "") + " bg-grey"} onClick={this.onClickCourseDetailsMobile(selectedCourse)}>	
                                                <div className="card-body">	
                                                    {selectedCourse.cpabcCourseSubject &&	
                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}</div>	
                                                    }	
                                                    {selectedCourse.title &&	
                                                        <div className="course-title">{selectedCourse.title}</div>	
                                                    }	
                                                    <div className="row">	
                                                        {selectedCourse.cpdHours != 0 &&	
                                                            <div className="col-5">	
                                                                <span className="label-number">{selectedCourse.cpdHours}</span>	
                                                                <span className="medium"> CPD </span>	
                                                                <span className="small">Hours</span>	
                                                            </div>	
                                                        }	
                                                        {selectedCourse.beginDate >= '2020-09-01' &&	
                                                            <div className="col-7">	
                                                                {selectedCourse.passportUnit === 0 &&	
                                                                    <span className="label-black">N/A Passport Credits</span>	
                                                                }	
                                                                {selectedCourse.passportUnit != 0 &&	
                                                                    <>	
                                                                        <span className="label-number">{selectedCourse.passportUnit}</span>	
                                                                        <span className="text-grey medium"> Passport </span>	
                                                                        <span className="small">Credits</span>	
                                                                    </>	
                                                                }	
                                                            </div>	
                                                        }	
                                                    </div>	
                                                    <div className="row pt-1">	
                                                        <div className="col">	
                                                            <div className="prerequisite-container">	
                                                                <div className="checkbox-icon is-checked">Prerequisite</div>	
                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisites }}></div></Dotdotdot>}	
                                                            </div>	
                                                        </div>	
                                                    </div>	
                                                    {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&	
                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                            <div className="d-flex cell mr-3">	
                                                                <div className="date">	
                                                                    {selectedCourse.beginDate &&	
                                                                        <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>	
                                                                    }	
                                                                </div>	
                                                                <div className="time">	
                                                                    {selectedCourse.beginTime && selectedCourse.endTime &&	
                                                                        <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>	
                                                                    }	
                                                                </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    {selectedCourse && selectedCourse.courseStatus != "Past" && selectedCourse.beginTime === null &&	
                                                        <div className="row pt-1">	
                                                            <div className="col">	
                                                                <div className="on-demande-container">	
                                                                    Available on-demand. 90-day access from purchase date.	
                                                                                    </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    {selectedCourse && selectedCourse.courseStatus === "Past" &&	
                                                        <div className="row pt-1">	
                                                            <div className="col">	
                                                                <div className="on-demande-container">	
                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates	
                                                                                    </div>	
                                                            </div>	
                                                        </div>	
                                                    }	
                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">	
                                                        {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&	
                                                            <div className="delivery-method">	
                                                                {selectedCourse.cpabcCourseDeliveryMethod &&	
                                                                    <span className="label-black">{this.getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>	
                                                                }	
                                                            </div>	
                                                        }	
                                                        {selectedCourse && selectedCourse.courseStatus != "Past" &&	
                                                            <div className="course-status">	
                                                                <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>	
                                                            </div>	
                                                        }	
                                                    </div>	
                                                </div>	
                                            </div>	
                                        }	
                                    </div>	
                                </Responsive>	
                            }
                            */}


                        </div>

                        
                        {!searchResult.isLoading && searchResult.items.length !== 0 && !selectedCourse &&
                            <span className="course">Select course</span>
                        }

                        
                       
                        <div className="container">
                            {!searchResult.isLoading &&
                                <React.Fragment>
                                    <Responsive displayIn={["Tablet", "Laptop"]}>
                                <div className="row">
                                        <div className="card course" style={{ width: "100%", borderRadius: '10px', border: '1px solid #e2e7ec'}}>
                                            <div className="card-body">
                                                <div className="course-title" style={{ border: "none"}}>
                                            <div className="d-flex flex-column flex-sm-row align-items-center side-icon-wrapper">

                                                
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                                    <div class="header-results">
                                                                <div class="d-flex">


                                                                            <div class="mr-auto header-title-container">
                                                                        <h3 class="header-title" style={{ color: "#000000"}}>PD View-by-Month Course Calendar {currentDisplayMonth !== null && <span> — {moment(currentDisplayMonth).format("MMM YYYY")}</span>}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                        
                                                            <div>
                                                        {weekOfMonthIndexes
                                                            .map((keyWeek) => {
                                                                let positionTB = "tooltipTop";
                                                                if (keyWeek == 0 || keyWeek == 1) {
                                                                    positionTB = "tooltipBottom";
                                                                }
                                                                return (
                                                                    <div class="row">
                                                                        {dayOfWeekIndexes
                                                                            .map((keyDay) => {
                                                                                cellCounter++;
                                                                                
                                                                                let numCourses = 0;
                                                                                if ((cellCounter >= cellNumberOfMonthStart) && (dayCounter <= numDaysInMonth) && (itemsDailyKeys[dayCounter] != null)) {
                                                                                    numCourses = itemsDailyKeys[dayCounter].length;
                                                                                }
                                                                                let positionRL = "tooltipRight";
                                                                                if (keyDay == 4 || keyDay == 5 || keyDay == 6) {
                                                                                    positionRL = "tooltipLeft";
                                                                                }
                                                                                let numCoursesToDisplay = 2;
                                                                                let cellStyle = this.calculateCellStyle(cellNumberOfMonthStart, numDaysInMonth, currentDisplayMonth, cellCounter, dayCounter);
                                                                                let displayOldCourses = false;
                                                                                //console.log("test77: " + cellStyle);
                                                                                return (

                                                                                    <div class={"col calendarCell " + cellStyle}>
                                                                                        {cellCounter >= cellNumberOfMonthStart && dayCounter <= numDaysInMonth &&
                                                                                            <div><span class="calendarDayIndex calendarDayIndexText">{dayCounter++}</span>
                                                                                            {!this.checkDisplayOldCoursesToday(displayOldCourses, dayCounter) && <p>&nbsp;</p>}
                                                                                               


                                                                                            {this.checkDisplayOldCoursesToday(displayOldCourses, dayCounter) &&
                                                                                            <>
                                                                                                <div class="calendarTheEvents">
                                                                                                    {(itemsDailyKeys[dayCounter - 1].length == 0) && <p>&nbsp;</p>}
                                                                                            {itemsDailyKeys[dayCounter-1]
                                                                                                        .map((key) => {

                                                                                                    //unpack the key
                                                                                                            let itemsIndex = Math.floor(key);
                                                                                                            let availableDatesIndex = Math.round((key - itemsIndex) * 100);
                                                                                                            //console.log("keys!111: " + key + "|" + itemsIndex + "|" + availableDatesIndex + "|" );

                                                                                                            numCoursesToDisplay--;
                                                                                                            let beginDate;
                                                                                                            let endDate;
                                                                                                            let currentItem2;
                                                                                                            if (key == itemsIndex) {
                                                                                                                beginDate = moment(searchResult.items[itemsIndex].beginDate).format("MMM DD, YYYY");
                                                                                                                endDate = moment(searchResult.items[itemsIndex].endDate).format("MMM DD, YYYY");
                                                                                                                currentItem2 = searchResult.items[itemsIndex]
                                                                                                            } else {
                                                                                                                beginDate = moment(searchResult.items[itemsIndex].availableDates[availableDatesIndex].beginDate).format("MMM DD, YYYY");
                                                                                                                endDate = moment(searchResult.items[itemsIndex].availableDates[availableDatesIndex].endDate).format("MMM DD, YYYY");
                                                                                                                currentItem2 = searchResult.items[itemsIndex].availableDates[availableDatesIndex];
                                                                                                            }
                                                                                                    //console.log("num courses: " + itemsDailyKeys[dayCounter-1].length);
                                                                                                    //console.log("tooltips: " + keyWeek + "|" + positionTB + "|" + keyDay + "|" + positionRL + "|");
                                                                                                    return (
                                                                                                        <>
                                                                                                            {(numCoursesToDisplay >= 0) && 
                                                                                                                <div onClick={this.onClickCourseDetails(currentItem2)} class="calendarAnEvent calendarEventText" id={currentItem2.cpabcMeeting}>
                                                                                                                <div class="calendarTooltip">
                                                                                                                    {(currentItem2.courseStatus == "Cancelled") && <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                                                                                                        {currentItem2.title}
                                                                                                                    </span>}
                                                                                                                    {(currentItem2.courseStatus != "Cancelled") && <span>
                                                                                                                        {currentItem2.title}
                                                                                                                    </span>}
                                                                                                                
                                                                                                                <div class="calendarEventLengthDays">{endDate && (endDate != beginDate) && <span>(End date: {endDate}) </span>}</div>
                                                                                                            
                                                                                                                <span class={"tooltipText " + positionTB + " " + positionRL}>
                                                                                                                        <strong>{currentItem2.title}</strong>
                                                                                                                        <br /><em>{this.getCourseSubjectDescriptionCalendar(currentItem2.cpabcCourseSubject)}</em>
                                                                                                                    <br /><span class="calendarTooltipItemTitle">Begin Date:</span> {beginDate && beginDate}
                                                                                                                        <br /><span class="calendarTooltipItemTitle">Time:</span> {currentItem2.beginTime && currentItem2.endTime && <span>{currentItem2.beginTime} - {currentItem2.endTime}</span>}
                                                                                                                        <br /><span class="calendarTooltipItemTitle">Venue:</span> {this.getCity(currentItem2.cpabcVenueId)}
                                                                                                                        {currentItem2.cpabcCourseDeliveryMethod !== 'IP' && this.getDeliveryMethod(currentItem2.cpabcCourseDeliveryMethod)}
                                                                                                                        <br /><span class="calendarTooltipItemTitle">Status:</span> {currentItem2.courseStatus && currentItem2.courseStatus}
                                                                                                                        <br /><span class="calendarTooltipItemTitle">Seminar Type:</span> {this.getSeminarType(currentItem2.cpabcCourseProgram)}
                                                                                                                        <br /><span class="calendarTooltipItemTitle">Delivery Method:</span> {currentItem2.cpabcCourseDeliveryMethod && this.getDeliveryMethod(currentItem2.cpabcCourseDeliveryMethod)}


                                                                                                                </span>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                            }
                                                                                                    </>
                                                                                                    )

                                                                                                })
                                                                                                    }</div>
                                                                                                {(numCourses > 0) && <div class="calendarMoreText" onClick={cellCounter >= cellNumberOfMonthStart && (dayCounter - 1) < numDaysInMonth && this.displayCoursesForDay.bind(this, dayCounter - 1)}>View all {numCourses} courses</div>}
                                                                                            </>
                                                                                            }
                                                                                    </div>
                                                                                    }
                                                                                       


                                                                                    </div>
                                                                                )

                                                                            })
                                                                        }
                                                                    </div>
                                                                )

                                                            })
                                                        }

                                                    </div>
                                                    



                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </Responsive>
                            </React.Fragment>
                            }



                            <div className="row">
                             {selectedCourse && 	
                            <React.Fragment>	
                            <a name="courseDetailAnchor" id="courseDetailAnchor"></a>
                                <Responsive displayIn={["Tablet", "Laptop"]}>	
                                    <PDDetails	
                                        selectedCourse={selectedCourse}	
                                        pdDetails={pdDetails}	
                                        itemsTestimonials={itemsTestimonials}	
                                        selectedBookmark={selectedBookmark}	
                                        getCourseSubjectDescription={this.getCourseSubjectDescription}	
                                        onSelectTag={this.onSelectTag}	
                                        getDeliveryMethod={this.getDeliveryMethod}	
                                        coursePrograms={coursePrograms}	
                                        seminarLevels={seminarLevels}	
                                        availableButtonLink={availableButtonLink}	
                                        cancelledButtonLink={cancelledButtonLink}	
                                        closedButtonLink={closedButtonLink}	
                                        fullButtonLink={fullButtonLink}	
                                        pastButtonLink={pastButtonLink}	
                                        availableButtonLabel={availableButtonLabel}	
                                        cancelledButtonLabel={cancelledButtonLabel}	
                                        closedButtonLabel={closedButtonLabel}	
                                        fullButtonLabel={fullButtonLabel}	
                                        pastButtonLabel={pastButtonLabel}	
                                        leaders={leaders}	
                                        locations={locations}	
                                        tags={tags}	
                                        bookmark={this.bookmark}	
                                        isLoadingPdDetails={isLoadingPdDetails}	
                                    >	
                                    </PDDetails>	
                                </Responsive>
                                    <Responsive displayIn={["Mobile"]}>
                                        
                                    <div className={"pd-details-mobile"} ref={this.headPdDetails}>	
                                        {this.state.visiblePdDetails &&	
                                            <React.Fragment>	
                                                <div className="button-close">	
                                                    <button className="btn btn-primary btn-close text-center" onClick={this.onClickButtonClosePdDetails()}></button>	
                                                </div>	
                                                <PDDetails	
                                                    selectedCourse={selectedCourse}	
                                                    pdDetails={pdDetails}	
                                                    itemsTestimonials={itemsTestimonials}	
                                                    selectedBookmark={selectedBookmark}	
                                                    getCourseSubjectDescription={this.getCourseSubjectDescription}	
                                                    onSelectTag={this.onSelectTag}	
                                                    getDeliveryMethod={this.getDeliveryMethod}	
                                                    coursePrograms={coursePrograms}	
                                                    seminarLevels={seminarLevels}	
                                                    availableButtonLink={availableButtonLink}	
                                                    cancelledButtonLink={cancelledButtonLink}	
                                                    closedButtonLink={closedButtonLink}	
                                                    fullButtonLink={fullButtonLink}	
                                                    pastButtonLink={pastButtonLink}	
                                                    availableButtonLabel={availableButtonLabel}	
                                                    cancelledButtonLabel={cancelledButtonLabel}	
                                                    closedButtonLabel={closedButtonLabel}	
                                                    fullButtonLabel={fullButtonLabel}	
                                                    pastButtonLabel={pastButtonLabel}	
                                                    leaders={leaders}	
                                                    locations={locations}	
                                                    tags={tags}	
                                                    bookmark={this.bookmark}	
                                                    isLoadingPdDetails={isLoadingPdDetails}	
                                                >	
                                                </PDDetails>	
                                            </React.Fragment>	
                                        }	
                                    </div>	
                                </Responsive>
                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>;
    }
}

SearchResultCalendar.propTypes = {
    searchPd: PropTypes.func.isRequired,
    getPdDetails: PropTypes.func.isRequired,
    searchResult: PropTypes.object.isRequired,
    selectedBookmark: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    cpabcMeeting: PropTypes.string.isRequired,
    cpabcCourseSubject: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    Description: PropTypes.string.isRequired,
    Code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cpdHours: PropTypes.number.isRequired,
    passportUnit: PropTypes.number.isRequired,
    beginDate: PropTypes.string.isRequired,
    beginTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    cpabcCourseDeliveryMethod: PropTypes.string.isRequired,
    overview: PropTypes.string.isRequired,
    specialAttribute: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    courseDescription: PropTypes.string.isRequired,
    attendees: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    specialNotes: PropTypes.string.isRequired,
    ethicHours: PropTypes.number.isRequired,
    cpabcCourseLevelTooltip: PropTypes.string.isRequired,
    memberPrice: PropTypes.number.isRequired,
    nonMemberPrice: PropTypes.number.isRequired,
    passportValid: PropTypes.bool.isRequired,
    cpabcCourseProgram: PropTypes.string.isRequired,
    availableDates: PropTypes.array.isRequired,
    courseStatus: PropTypes.string.isRequired,
    cpabcVenueId: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    requiredCaption: PropTypes.string.isRequired,
    sortBy: PropTypes.number.isRequired,
    masterCode: PropTypes.string.isRequired,
    speakerIds: PropTypes.array.isRequired,
    setAllSearchCriteria: PropTypes.func.isRequired,
    setSortByValue: PropTypes.func.isRequired,
    resetSearchResultItems: PropTypes.func.isRequired,
    isLoadingPdDetails: PropTypes.bool.isRequired,	
    setIsLoadingPdDetails: PropTypes.func.isRequired,
    setCopySearchTagVisible: PropTypes.func.isRequired,	
    setSearchTag: PropTypes.func.isRequired,
};

export default SearchResultCalendar;

