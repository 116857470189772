import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function SeminarLeaderDropdown(props) {
    const { selectedLeaders, leaders, isItemSelected, onSelectAnySemLeader, onSelectSemLeader, setSelectedLeaders } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [seminarLeaderFirstSelected, setSeminarLeaderFirstSelected] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const toggle = () =>
        setDropdownOpen(prevState => !prevState);

    const hasResults = () =>
    {
        let itemSum = 0;
        for (let i = 0; i < leaders.length; i++) {
            const itemName = leaders[i].FirstName + " " + leaders[i].LastName;
            const boolVisible = itemName.toLowerCase().includes(searchTerm.toLowerCase());
            if (boolVisible) {
                itemSum += 1;
            }
        }

        if (itemSum === 0 && !"Any or All".toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
        }
        else {
            return false;
        }
    }

    const onClickResetSearchTerm = () => {
        if (!dropdownOpen) {
            setSearchTerm("");
            setSeminarLeaderFirstSelected(null);
        }
    }


    const onChange = (event) =>
    {
        const firstLeaderSelected = [];
        let boolFirst = false;
        if (event.target.value !== "" && "any or all".includes(event.target.value.toLowerCase())) {
            firstLeaderSelected.push(" focus-active");
            boolFirst = true;
        }
        else {
            firstLeaderSelected.push("");
        }

        for (let i = 0; i < leaders.length; i++) {
            const itemName = leaders[i].FirstName + " " + leaders[i].LastName;
            if (boolFirst) {
                firstLeaderSelected.push("");
            }
            else {
                (event.target.value !== "" && itemName.toLowerCase().includes(event.target.value.toLowerCase())
                    ? firstLeaderSelected.push(" focus-active")
                    : firstLeaderSelected.push(""));

                (itemName.toLowerCase().includes(event.target.value.toLowerCase()) ? boolFirst = true : boolFirst = false);
            }
        }

        setSearchTerm(event.target.value);
        setSeminarLeaderFirstSelected(firstLeaderSelected);
    }

    const onSelectAnySemLeaderKeyPress = () => {
        if (selectedLeaders.length === 1 && selectedLeaders[0].Name === 'Any or All') {
            const emptyArray = [];
            setSelectedLeaders(emptyArray);
        } else {
            const selectedArray = [];
            selectedArray.push({
                LeaderId: "",
                Name: 'Any or All',

            });
            setSelectedLeaders(selectedArray);
        }
    }

    const onSelectSemLeaderKeyPress = (leader) => {

        let selectedArray = selectedLeaders;

        let existParameter = true;
        for (var i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i].LeaderId === leader.LeaderId) {
                selectedArray.splice(i, 1);
                existParameter = false;
            }
        }

        if (existParameter) {
            selectedArray.push({
                LeaderId: leader.LeaderId,
                Name: leader.FirstName + " " + leader.LastName
            });

            for (var i = 0; i < selectedArray.length; i++) {
                if (selectedArray[i].Name === "Any or All") {
                    selectedArray.splice(i, 1);
                }
            }
        }
        setSelectedLeaders(selectedArray);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            let itemNumber;
            if (seminarLeaderFirstSelected && searchTerm !== "") {
                for (var i = 0; i < seminarLeaderFirstSelected.length; i++) {
                    if (seminarLeaderFirstSelected[i] !== "") {
                        itemNumber = i;
                    }
                }
                if (Number.isInteger(itemNumber)) {
                    (itemNumber === 0)
                        ? onSelectAnySemLeaderKeyPress()
                        : onSelectSemLeaderKeyPress(leaders[itemNumber - 1]);
                }
            }
            else if (seminarLeaderFirstSelected && searchTerm == "") {
                for (var i = 0; i < seminarLeaderFirstSelected.length; i++) {
                    if (seminarLeaderFirstSelected[i] !== '') {
                        itemNumber = i;
                    }
                }
                if (Number.isInteger(itemNumber)) {
                    (itemNumber === 0) ? onSelectAnySemLeaderKeyPress() : onSelectSemLeaderKeyPress(leaders[itemNumber - 1]);
                }
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            let firstLeaderSelected = [];
            if (seminarLeaderFirstSelected == null) {
                firstLeaderSelected.push(" focus-active");

                for (var i = 0; i < leaders.length; i++) {
                    firstLeaderSelected.push("");
                }

                setSeminarLeaderFirstSelected(firstLeaderSelected);
            }
            else {
                let activeId;
                for (var i = 0; i < seminarLeaderFirstSelected.length; i++) {
                    if (seminarLeaderFirstSelected[i] === " focus-active") {
                        activeId = i;
                    }
                }
                if (Number.isInteger(activeId)) {
                    let firstLeaderSelected = [];
                    for (var i = 0; i < activeId + 1; i++) {
                        firstLeaderSelected.push("");
                    }
                    let boolFirst = false;
                    for (var i = activeId; i < leaders.length; i++) {
                        let itemName = leaders[i].FirstName + " " + leaders[i].LastName;
                        if (boolFirst) {
                            firstLeaderSelected.push("");
                        }
                        else {
                            (itemName.toLowerCase().includes(searchTerm.toLowerCase()) ? firstLeaderSelected.push(' focus-active') : firstLeaderSelected.push(""));
                            (itemName.toLowerCase().includes(searchTerm.toLowerCase()) ? boolFirst = true : boolFirst = false);
                        }
                    }

                    let activeNextId;
                    for (var i = 0; i < firstLeaderSelected.length; i++) {
                        if (firstLeaderSelected[i] == ' focus-active') {
                            activeNextId = i;
                        }
                    }
                    let firstLeaderSelected2 = [];
                    if (!Number.isInteger(activeNextId)) {
                        let boolFirst2 = false;
                        if (searchTerm !== "" && "any or all".includes(searchTerm.toLowerCase())) {
                            firstLeaderSelected2.push(" focus-active");
                            boolFirst2 = true;
                        }
                        else {
                            firstLeaderSelected2.push("");
                        }

                        for (var i = 0; i < leaders.length; i++) {
                            let itemName = leaders[i].FirstName + " " + leaders[i].LastName;
                            if (boolFirst2) {
                                firstLeaderSelected2.push("");
                            }
                            else {
                                (event.target.value !== "" && !boolFirst2 && itemName.toLowerCase().includes(event.target.value.toLowerCase()) ? firstLeaderSelected2.push(" focus-active") : firstLeaderSelected2.push(''));
                                (itemName.toLowerCase().includes(event.target.value.toLowerCase()) ? boolFirst2 = true : boolFirst2 = false);
                            }
                        }
                    }

                    if (!Number.isInteger(activeNextId)) {
                        setSeminarLeaderFirstSelected(firstLeaderSelected);
                    }
                    else {
                        setSeminarLeaderFirstSelected(firstLeaderSelected);
                    }
                }
                else if (!Number.isInteger(activeId) && searchTerm === "") {
                    let firstLeaderSelected = [];
                    firstLeaderSelected.push(" focus-active");
                    for (var i = 0; i < leaders.length; i++) {
                        firstLeaderSelected.push("");
                    }

                    setSeminarLeaderFirstSelected(firstLeaderSelected);
                }
            }
        }
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-seminar-leader" onClick={onClickResetSearchTerm}>
            <DropdownToggle caret>
                {selectedLeaders.length === 0 && <span>Seminar leader </span>}
                {selectedLeaders.length > 0 && selectedLeaders
                    .map((item, index) => {
                        return <span>{(index ? ", " : "") + item.Name}</span>;
                    })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="sl-searchbox">
                    <input type="text"
                        className="form-control"
                        onChange={onChange}
                        value={searchTerm}
                        onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                    />
                </div> 
                <div className="items">
                    {"any or all".includes(searchTerm.toLowerCase()) &&
                        <DropdownItem toggle={false} className=
                            {(isItemSelected(selectedLeaders, "Name", "Any or All") ? " active" : "") + (seminarLeaderFirstSelected ? seminarLeaderFirstSelected[0] : "")}
                        onClick={onSelectAnySemLeader()}>Any or All</DropdownItem>
                    }
                    {leaders
                        .map((item, index) => {
                            const boolClassName = isItemSelected(selectedLeaders, "LeaderId", item.LeaderId);
                            const itemName = item.FirstName + " " + item.LastName;
                            const isVisible = itemName.toLowerCase().includes(searchTerm.toLowerCase());
                            return (
                                <DropdownItem toggle={false} className={"dropdown-item" +
                                    (boolClassName ? " active" : "") +
                                    (seminarLeaderFirstSelected ? seminarLeaderFirstSelected[index + 1] : "")}
                                    style={isVisible ? { display: "" } : { display: "none" }}
                                    onClick={onSelectSemLeader(item)}>
                                    {itemName}
                                </DropdownItem>
                            );
                        })
                    }
                    <DropdownItem
                        className="no-search-result"
                        style={(hasResults()) ? { display: "" } : { display: "none" }}>No results matched <span>"{searchTerm}"</span></DropdownItem>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
