const initialState = {
    searchResult: {
        items: [],
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0,
        hasResultNewCourses: false,
        isLoading: false
    },
    filtersSearch: {
        startDate: null,
        endDate: null,
        selectedLocations: [],
        selectedSubjects: [],
        selectedSeminarLength: [],
        selectedDeliveryFormats: [],
        selectedCoursePrograms: [],
        selectedEthicsHours: [],
        selectedCertificatePrograms: [],
        selectedLeaders: [],
        selectedPassportCredits: [],
        keywordsSearchValue: "",
        selectedCourse: null,
        selectedBookmark: [],
        listBookmarksVisible: false, 
        sortBy: 'Alpha',
        newCourses: null,
        pdDetails: null,
        allSearchCriteria: null,
        courseFromHashVisible: false,
        newCoursesOnlyVisible: false,
        hashAllSearchCriteria: '',
        isLoadingPdDetails: true,
        copySearchTagVisible: false,
        searchTag: '',
    },
    apiCallsInProgress: 0,
    loadingMore: false 
};

export default initialState;