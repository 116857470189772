import React from 'react';

export default function SelectedSubjects(props) {
    const { selectedSubjects, onRemoveSubject} = props;

    return (
        <>
            {selectedSubjects.length > 0 &&
                <div className="d-flex mr-4 mb-2 courseSubcategory-filters">
                    <div className="option-select">
                        Subjects: &nbsp;
                            {selectedSubjects.length > 0 && selectedSubjects.map((subject, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{subject.Description}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveSubject(subject,
                                        "Description",
                                        "Subject")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
