import React, { memo } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as searchPdActions from "../../../redux/actions/searchPdActions";
import PropTypes from "prop-types";
import SearchFiltersCalendar from './SearchFiltersCalendar';
import SearchResultCalendar from './SearchResultCalendar';

class SearchPdCalendar extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions, searchResult } = this.props;
    }

    render() {
        const {
            searchResult,
            locations,
            courseSubjects,
            seminarLength,
            deliveryFormats,
            coursePrograms,
            ethicsHours,
            certificatePrograms,
            leaders,
            availableButtonLabel,
            availableButtonLink,
            cancelledButtonLabel,
            cancelledButtonLink,
            closedButtonLabel,
            closedButtonLink,
            fullButtonLabel,
            fullButtonLink,
            pastButtonLabel,
            pastButtonLink,
            seminarLevels,
            passportCredits,
            loadingMore,
            ideasTitle,
            ideasAbstract,
            ideasIcon,
            ideasLink,
            tags
        } = this.props;

        const {
            searchPd,
            setLoadingFalse,
            setStartDate,
            setEndDate,
            setSelectedLocations,
            setSelectedSubjects,
            setSelectedSeminarLength,
            setSelectedDeliveryFormats,
            setSelectedCoursePrograms,
            setSelectedEthicsHours,
            setSelectedCertificatePrograms,
            setSelectedLeaders,
            setKeywordsSearchValue,
            selectCourse,
            setVisibleListBookmarks,
            getPdDetails,
            getPdDetailsHash,
            resetSearchResultItems,
            resetFiltersSearch,
            setAllSearchCriteria,
            setSelectedPassportCredits,
            setCourseFromHashVisible,
            setHashAllSearchCriteria,
            selectBookmark,
            setSortByValue,
            setSearchNewCourses,
            setNewCoursesOnlyVisible,
            setIsLoadingPdDetails,	
            setPdDetails,	
            setCopySearchTagVisible,	
            setSearchTag
        } = this.props.actions;

        const {
            startDate,
            endDate,
            selectedLocations,
            selectedSubjects,
            selectedSeminarLength,
            selectedDeliveryFormats,
            selectedCoursePrograms,
            selectedEthicsHours,
            selectedCertificatePrograms,
            selectedLeaders,
            selectedPassportCredits,
            selectedBookmark,
            selectedCourse,
            keywordsSearchValue,
            allSearchCriteria,
            courseFromHashVisible,
            hashAllSearchCriteria,
            sortBy,
            newCourses,
            listBookmarksVisible,
            pdDetails,
            newCoursesOnlyVisible,
            isLoadingPdDetails,	
            copySearchTagVisible,	
            searchTag
        } = this.props.filtersSearch;

        //console.log("startdate in search: " + startDate);
        //console.log("startdate in search2: " + this.props.filtersSearch.startDate);


        return <div className="search-pd">
            <hr />
            <div className="row">
                <div className="col">
                    <SearchFiltersCalendar
                        searchPd={searchPd}
                        setLoadingFalse={setLoadingFalse}
                        resetSearchResultItems={resetSearchResultItems}
                        resetFiltersSearch={resetFiltersSearch}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                        selectedSubjects={selectedSubjects}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSeminarLength={selectedSeminarLength}
                        setSelectedSeminarLength={setSelectedSeminarLength}
                        selectedDeliveryFormats={selectedDeliveryFormats}
                        setSelectedDeliveryFormats={setSelectedDeliveryFormats}
                        selectedCoursePrograms={selectedCoursePrograms}
                        setSelectedCoursePrograms={setSelectedCoursePrograms}
                        selectedEthicsHours={selectedEthicsHours}
                        setSelectedEthicsHours={setSelectedEthicsHours}
                        selectedCertificatePrograms={selectedCertificatePrograms}
                        setSelectedCertificatePrograms={setSelectedCertificatePrograms}
                        selectedLeaders={selectedLeaders}
                        setSelectedLeaders={setSelectedLeaders}
                        selectedPassportCredits={selectedPassportCredits}
                        setSelectedPassportCredits={setSelectedPassportCredits}
                        keywordsSearchValue={keywordsSearchValue}
                        setKeywordsSearchValue={setKeywordsSearchValue}
                        selectCourse={selectCourse}
                        searchResult={searchResult}
                        locations={locations}
                        courseSubjects={courseSubjects}
                        seminarLength={seminarLength}
                        deliveryFormats={deliveryFormats}
                        coursePrograms={coursePrograms}
                        ethicsHours={ethicsHours}
                        certificatePrograms={certificatePrograms}
                        leaders={leaders}
                        selectedBookmark={selectedBookmark}
                        setVisibleListBookmarks={setVisibleListBookmarks}
                        selectBookmark={selectBookmark}
                        sortBy={sortBy}
                        newCourses={newCourses}
                        getPdDetails={getPdDetails}
                        getPdDetailsHash={getPdDetailsHash}
                        setAllSearchCriteria={setAllSearchCriteria}
                        allSearchCriteria={allSearchCriteria}
                        locationsInit={locations}
                        setSortByValue={setSortByValue}
                        setSearchNewCourses={setSearchNewCourses}
                        passportCredits={passportCredits}
                        setCourseFromHashVisible={setCourseFromHashVisible}
                        hashAllSearchCriteria={hashAllSearchCriteria}
                        setHashAllSearchCriteria={setHashAllSearchCriteria}
                        newCoursesOnlyVisible={newCoursesOnlyVisible}
                        setNewCoursesOnlyVisible={setNewCoursesOnlyVisible}
                        setIsLoadingPdDetails={setIsLoadingPdDetails}
                        isLoadingPdDetails={isLoadingPdDetails}
                        setPdDetails={setPdDetails}	
                        tags={tags}	
                        copySearchTagVisible={copySearchTagVisible}	
                        setCopySearchTagVisible={setCopySearchTagVisible}	
                        searchTag={searchTag}
                    />
                </div>
            </div>
            <hr />
                <div className="row">
                    <div className="col">
                        <SearchResultCalendar
                        searchResult={searchResult}
                        courseSubjects={courseSubjects}
                        deliveryFormats={deliveryFormats}
                        coursePrograms={coursePrograms}
                        leaders={leaders}
                        locations={locations}
                        selectedCourse={selectedCourse}
                        selectCourse={selectCourse}
                        selectedBookmark={selectedBookmark}
                        selectBookmark={selectBookmark}
                        listBookmarksVisible={listBookmarksVisible}
                        sortBy={sortBy}
                        setSortByValue={setSortByValue}
                        searchPd={searchPd}
                        setLoadingFalse={setLoadingFalse}
                        setVisibleListBookmarks={setVisibleListBookmarks}
                        getPdDetails={getPdDetails}
                        pdDetails={pdDetails}
                        availableButtonLabel={availableButtonLabel}
                        availableButtonLink={availableButtonLink}
                        cancelledButtonLabel={cancelledButtonLabel}
                        cancelledButtonLink={cancelledButtonLink}
                        closedButtonLabel={closedButtonLabel}
                        closedButtonLink={closedButtonLink}
                        fullButtonLabel={fullButtonLabel}
                        fullButtonLink={fullButtonLink}
                        pastButtonLabel={pastButtonLabel}
                        pastButtonLink={pastButtonLink}
                        seminarLevels={seminarLevels}
                        allSearchCriteria={allSearchCriteria}
                        setAllSearchCriteria={setAllSearchCriteria}
                        resetSearchResultItems={resetSearchResultItems}
                        loadingMore={loadingMore}
                        courseFromHashVisible={courseFromHashVisible}
                        ideasTitle={ideasTitle}
                        ideasAbstract={ideasAbstract}
                        ideasIcon={ideasIcon}
                        ideasLink={ideasLink}
                        tags={tags}
                        setCourseFromHashVisible={setCourseFromHashVisible}
                        isLoadingPdDetails={isLoadingPdDetails}
                        setIsLoadingPdDetails={setIsLoadingPdDetails}
                        setPdDetails={setPdDetails}	
                        startDateForCalendar={startDate}
                        setCopySearchTagVisible={setCopySearchTagVisible}	
                        setSearchTag={setSearchTag}	
                        copySearchTagVisible={copySearchTagVisible}	
                        searchTag={searchTag}
                        />
                    </div>
                </div>
        </div>;
    }
}

SearchPdCalendar.propTypes = {
    coursePrograms: PropTypes.array.isRequired,
    certificatePrograms: PropTypes.array.isRequired,
    courseSubjects: PropTypes.array.isRequired,
    deliveryFormats: PropTypes.array.isRequired,
    leaders: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    seminarLength: PropTypes.array.isRequired,
    ethicsHours: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult ? state.searchResult : {
            pageIndex: 0,
            pageSize: 500, //test 9,
            items: [],
            totalCount: 0,
            isLoading: false,
            hasResultNewCourses: false
        },
        filtersSearch: state.filtersSearch,
        loadingMore: state.loadingMore ? state.loadingMore : false
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            searchPd: bindActionCreators(searchPdActions.searchPd, dispatch),
            getPdDetails: bindActionCreators(searchPdActions.getPdDetails, dispatch),
            getPdDetailsHash: bindActionCreators(searchPdActions.getPdDetailsHash, dispatch),
            setLoadingFalse: bindActionCreators(searchPdActions.setLoadingFalse, dispatch),
            resetSearchResultItems: bindActionCreators(searchPdActions.resetSearchResultItems, dispatch),
            resetFiltersSearch: bindActionCreators(searchPdActions.resetFiltersSearch, dispatch),
            setStartDate: bindActionCreators(searchPdActions.setStartDate, dispatch),
            setEndDate: bindActionCreators(searchPdActions.setEndDate, dispatch),
            setSelectedLocations: bindActionCreators(searchPdActions.setSelectedLocations, dispatch),
            setSelectedSubjects: bindActionCreators(searchPdActions.setSelectedSubjects, dispatch),
            setSelectedSeminarLength: bindActionCreators(searchPdActions.setSelectedSeminarLength, dispatch),
            setSelectedDeliveryFormats: bindActionCreators(searchPdActions.setSelectedDeliveryFormats, dispatch),
            setSelectedCoursePrograms: bindActionCreators(searchPdActions.setSelectedCoursePrograms, dispatch),
            setSelectedEthicsHours: bindActionCreators(searchPdActions.setSelectedEthicsHours, dispatch),
            setSelectedCertificatePrograms: bindActionCreators(searchPdActions.setSelectedCertificatePrograms, dispatch),
            setSelectedLeaders: bindActionCreators(searchPdActions.setSelectedLeaders, dispatch),
            setSelectedPassportCredits: bindActionCreators(searchPdActions.setSelectedPassportCredits, dispatch),
            setKeywordsSearchValue: bindActionCreators(searchPdActions.setKeywordsSearchValue, dispatch),
            selectCourse: bindActionCreators(searchPdActions.selectCourse, dispatch),
            selectBookmark: bindActionCreators(searchPdActions.selectBookmark, dispatch),
            setVisibleListBookmarks: bindActionCreators(searchPdActions.setVisibleListBookmarks, dispatch),
            setSortByValue: bindActionCreators(searchPdActions.setSortByValue, dispatch),
            setSearchNewCourses: bindActionCreators(searchPdActions.setSearchNewCourses, dispatch),
            setAllSearchCriteria: bindActionCreators(searchPdActions.setAllSearchCriteria, dispatch),
            setCourseFromHashVisible: bindActionCreators(searchPdActions.setCourseFromHashVisible, dispatch),
            setNewCoursesOnlyVisible: bindActionCreators(searchPdActions.setNewCoursesOnlyVisible, dispatch),
            setHashAllSearchCriteria: bindActionCreators(searchPdActions.setHashAllSearchCriteria, dispatch),
            setIsLoadingPdDetails:  bindActionCreators(searchPdActions.setIsLoadingPdDetails, dispatch),	
            setPdDetails:  bindActionCreators(searchPdActions.setPdDetails, dispatch),	
            setCopySearchTagVisible:  bindActionCreators(searchPdActions.setCopySearchTagVisible, dispatch),	
            setSearchTag:  bindActionCreators(searchPdActions.setSearchTag, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPdCalendar);


