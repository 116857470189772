import React from 'react';
import { getDeviceTypeInfo } from '../../../utils/utils';
import { getEmptySearchRequest } from './filterUtils';

export default function BookmarkDropdown(props) {
    const { selectedBookmark, selectCourse, setVisibleListBookmarks, setCourseFromHashVisible, onHomePage, getPdDetails, setIsLoadingPdDetails, onClickBookmarkMobile, urlSearchPd} = props;

    const emptySearchRequest = getEmptySearchRequest();

    const onClickBookmarkDetails = (item) => () => {
        setIsLoadingPdDetails(true);
        selectCourse(item);
        getPdDetails(item.masterCode, emptySearchRequest);
        setVisibleListBookmarks(true);
        setCourseFromHashVisible(false);
    };

    let device = getDeviceTypeInfo();
    let deviceType;
    if (device) {
        deviceType = device.deviceType;
    };


    return (
        <>
            <button className="btn btn-secondary dropdown-toggle bookmark" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" >
                <span>Bookmarked Seminars</span>
                {selectedBookmark.length > 0 &&
                    <span className="badge"> {selectedBookmark.length}</span>}
                <i className="fas fa-angle-down"></i>
            </button>
            <div className="dropdown-menu bookmark-menu" aria-labelledby="dropdownMenuButton">
                <div className="items">
                    {selectedBookmark.length > 0 && selectedBookmark
                        .map((item) => {
                            return (
                                <React.Fragment>
                                    {deviceType != 'Mobile' &&
                                        <a className="dropdown-item" href={onHomePage ? urlSearchPd+'#/' + item.cpabcMeeting.toLowerCase() + '/' + item.masterCode.toLowerCase() + '/' : '#/' + item.cpabcMeeting.toLowerCase() + '/' + item.masterCode.toLowerCase() + '/'} onClick={onClickBookmarkDetails(item)}>{item.title}</a>
                                    }
                                    {deviceType === 'Mobile' &&
                                        <a className="dropdown-item" href={onHomePage ? urlSearchPd + '#/' + item.cpabcMeeting.toLowerCase() + '/' + item.masterCode.toLowerCase() + '/' : '#/' + item.cpabcMeeting.toLowerCase() + '/' + item.masterCode.toLowerCase() + '/'} onClick={onClickBookmarkMobile(item)}>{item.title}</a>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                    {selectedBookmark.length == 0 &&
                        <span>No bookmarks.</span>
                    }

                </div>
            </div>
        </>
    );
}
