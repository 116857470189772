import React from 'react';

export default function SelectedDeliveryFormats(props) {
    const { selectedDeliveryFormats, onRemoveDelFor} = props;

    return (
        <>
            {selectedDeliveryFormats.length > 0 &&
                <div className="d-flex mr-4 mb-2 deliveryFormat-filters">
                    <div className="option-select">
                        Delivery Format: &nbsp;
                            {selectedDeliveryFormats.length > 0 && selectedDeliveryFormats.map((item, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{item.Description}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveDelFor(item,
                                        "Description",
                                        "DeliveryFormats")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
