import * as types from "./actionTypes";

export function beginApiCall() {
    return { type: types.BEGIN_API_CALL, isLoading: true  };
}

export function apiCallError() {
    return { type: types.API_CALL_ERROR };
}

export function beginApiCallPdDetails() {
    return { type: types.BEGIN_API_CALL_PD_DETAILS};
}

export function beginLoadMoreCall() {
    return { type: types.BEGIN_LOAD_MORE_CALL, loadingMore: true };
}

export function endLoadMoreCall() {
    return { type: types.END_LOAD_MORE_CALL, loadingMore: false };
}
