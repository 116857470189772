export default function KeywordsSearch(props) {
    const { keywordsSearchValue, setKeywordsSearchValue, handleChangeKeywords, searchButton } = props;

     return (
        <div className="input-group ">
            <span className="input-group-text"><i className="fas fa-search"></i></span>
             <input type="text" className="form-control" placeholder="Keywords Search" value={keywordsSearchValue} onChange={handleChangeKeywords}
                 onKeyPress={event => {
                 if (event.key === 'Enter') {
                     searchButton();
                 }
             }}/>
        </div>
    );
}
