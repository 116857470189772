import React from 'react';
import { connect } from "react-redux";
import NewsListingResult from "./NewsListingResult";
import GenericDropdown from '../search/common/GenericDropdown';
import FeaturedNews from './FeaturedNews';
import Spotlight from './Spotlight';

const sortItems = [{
    Title: "Alpha",
    Value: {
        field: "alpha",
        sortAsc: false
    }
}, {
    Title: "Date ASC",
    Value: {
        field: "date",
        sortAsc: true
    }
}, {
    Title: "Date DESC",
    Value: {
        field: "date",
        sortAsc: false
    }
}];

const defaultSortItem = sortItems[2];

class NewsListing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchFilter: {
                keywords: "",
                sortByField: defaultSortItem.Value.field,
                sortASC: defaultSortItem.Value.sortAsc,
                path: props.Path
            },
            orderBy: defaultSortItem.Value,
        };

        this.search = this.search.bind(this);
        this.cleanFilters = this.cleanFilters.bind(this);
    }

    search() {
        const { searchFilter, orderBy } = this.state;

        let sf = {
            keywords: searchFilter.keywords,
            sortByField: orderBy.field,
            sortASC: orderBy.sortAsc,
            path: this.props.Path
        };

        this.setState({ searchFilter: sf });
    }

    cleanFilters() {
        const sf = {
            keywords: "",
            sortByField: defaultSortItem.Value.field,
            sortASC: defaultSortItem.Value.sortAsc,
            path: this.props.Path
        };

        this.setState({ searchFilter: sf, orderBy: defaultSortItem.Value });
    }

    componentDidMount() {
        //this.search();
    }

    render() {

        return (<React.Fragment>
                <div className="description" dangerouslySetInnerHTML={{ __html: this.props.Summary }}></div>
                <div className="news-listing">
                    <div className="inline-filters d-flex flex-xl-row flex-column flex-lg-column">
                        <div className="d-xl-flex w-100">
                            <div className="search">
                                <div className="input-group w-100">
                                    <div className="search-icon">
                                        <i className="fas fa-search"></i>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control form-control"
                                        value={this.state.searchFilter.keywords}
                                        placeholder="Search for keywords"
                                        onChange={(e) => {
                                            var newState = this.state.searchFilter;
                                            newState.keywords = e.target.value;
                                            this.setState({ searchFilter: newState });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                this.search();
                                            }
                                        }}
                                    />
                                    <button type="button" className="btn btn-secondary" onClick={this.search}>Search</button>
                                    <button type="button" className="btn-clear btn btn-secondary" onClick={this.cleanFilters}>Clear Search</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="float-right">
                        <GenericDropdown
                            className="order-by"
                            dataSource={sortItems}
                            getId={x => x.Value}
                            renderItem={x => x.Title}
                            renderSelectedItem={x => `Sort by: ${x.Title}`}
                            nullSelectionText="All Events"
                            selectedValue={this.state.orderBy}
                            onSelectedItemChanged={x => {
                                var sf = { ...this.state.searchFilter };
                                sf.sortByField = x.Value.field;
                                sf.sortASC = x.Value.sortAsc;

                                this.setState({
                                    searchFilter: sf,
                                    orderBy: x.Value
                                });
                            }}
                        />
                    </div>
                    <div className="clearfix"></div>
                    <NewsListingResult searchFilter={this.state.searchFilter}/>
            </div>
        </React.Fragment>);
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsListing);