import * as React from "react";
import { Component } from "react";
import { FormGroup, Label, Input, Col, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

class CheckBoxInputFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.formElementRef = null;

        this.state = {
            model: this.props.model,
            tooltip: false
        }
    }

    getInputElement() {
        return this.formElementRef;
    }

    inputChange(event) {
        let inputElement = event.target;
        var columnModel = this.state.model;

        if (columnModel) {
            if (!columnModel.allowEmpty && !inputElement.checked) {
                columnModel.invalid = true;
                columnModel.errorMessage = columnModel.description;
                columnModel.value = false;
            } else {
                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied;
                columnModel.value = inputElement.checked;
                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({ model: columnModel }, () => {
                this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {

        const chekboxId = "chk" + this.props.field.column + "_" + this.props.field.guid;

        return (
            <FormGroup row key={this.props.field.guid} className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
                style={this.state.model.visible ? {} : { display: 'none' }}>
                <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                    <Label for={"chk" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                </Col>
                <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9}>
                    <Label for={"chk" + this.props.field.column + "_" + this.props.field.guid}
                        style={this.props.showLabel && this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}
                        className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                    <div>
                        <Input invalid={this.state.model.invalid}
                            type="checkbox" name={this.props.field.column}
                            id={chekboxId}
                            onChange={this.inputChange.bind(this)}
                            innerRef={ref => { this.formElementRef = ref; this.props.formElementRefs[this.props.field.column] = ref }}
                            checked={this.state.model.value} />
                        <Label check for={chekboxId}>
                            <span dangerouslySetInnerHTML={{ __html: this.props.field.text }}>
                            </span>
                        </Label>
                    </div>
                    <div className="explanation-text">{this.props.field.explanationText}</div>               
                    <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                        className="invalid-message">
                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                        {this.state.model.errorMessage}
                    </div>
                </Col>
            </FormGroup>
        )
    }
}

export default CheckBoxInputFieldComponent;