import React from 'react';

export default function SelectedCertificatePrograms(props) {
    const { selectedCertificatePrograms, onRemoveCertProg} = props;

    return (
        <>
            {selectedCertificatePrograms.length > 0 &&
                <div className="d-flex mr-4 mb-2 certificateProgram-filters">
                    <div className="option-select">
                        Certificate Program: &nbsp;
                        {selectedCertificatePrograms.length > 0 && selectedCertificatePrograms.map((item, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{item.Description}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveCertProg(item,
                                        "Description",
                                        "CertificatePrograms")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
