import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function CertificateProgramsDropdown(props) {
    const { selectedCertificatePrograms, onSelectAnyOrAll, onSelectItem, getActiveClassName, certificatePrograms } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-certificate-program">
            <DropdownToggle caret>
                {selectedCertificatePrograms.length === 0 &&
                    <span>Certificate Program</span>}
                {selectedCertificatePrograms.length > 0 && selectedCertificatePrograms
                    .map((item, index) => {
                        return <span>{(index ? ", " : "") + item.Description}</span>;
                    })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedCertificatePrograms, "Description", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("Description", "Code", "CertificatePrograms")}>Any or All</DropdownItem>
                    {certificatePrograms
                        .map((item) => {
                            let isActive = getActiveClassName(selectedCertificatePrograms, "Description", item.Description);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(item, "Description", "Code", "CertificatePrograms")}>
                                    {item.Description}
                                </DropdownItem>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
