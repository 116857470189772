import { combineReducers } from "redux";
import searchResult from "./searchPdReducer";
import filtersSearch from "./filtersPdReducer";
import loadingMore from "./loadingMoreReducer";

const rootReducer = combineReducers({
    searchResult,
    filtersSearch,
    loadingMore
});

export default rootReducer;