import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Input, Col, Tooltip } from 'reactstrap';
import MultiSelect from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class MultiselectDropdownFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model,
            tooltip: false
        }
        this.formElementRef = {
            value: "",
            state: {}
        };
        this.props.formElementRefs[this.props.field.column] = this.formElementRef;
    }

    getInputElement() {
        return this.formElementRef;
    }

    isEmptyAndRequired(selectedValues, inputModel) {
        if (!(selectedValues || "").length && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    inputChange(selectedValues) {
        var columnModel = this.state.model;
        if (columnModel) {
            columnModel.value = this.formElementRef.value = (selectedValues || []).map(option => option.value).join("|");

            if (this.isEmptyAndRequired(columnModel.value, columnModel)) {
                columnModel.invalid = true;
                if (!columnModel.errorMessage) {
                    columnModel.errorMessage = columnModel.description;
                }                
            }
            else {
                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied;
                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({ model: columnModel }, () => {
                this.props.onChanged && this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {

        const propFieldValues = (this.props.field.listValues || []).map((option) => {
            return { value: option.value, label: option.text };
        });

        var preselectedValues = (this.state.model.value || "").split("|").filter(val => { return val; }).map((val) => {
            return { value: val, label: val };
        });

        return (
            <FormGroup row key={this.props.field.guid} className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
                style={this.state.model.visible ? {} : { display: 'none' }}>
                <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                    <Label for={"dwnMultiSelect" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                </Col>
                <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9}>
                    <Label
                        id={"dwnLabel" + this.props.field.column + "_" + this.props.field.guid}
                        for={"dwnMultiSelect" + this.props.field.column + "_" + this.props.field.guid}
                        style={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}
                        className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                    <MultiSelect
                        invalid={this.state.model.invalid}
                        id={"dwnMultiSelect" + this.props.field.column + "_" + this.props.field.guid}
                        //ref={ref => { console.log("ref=", ref); this.formElementRef = ref; this.props.formElementRefs[this.props.field.column] = ref }} It's not working!
                        name={this.props.field.column}
                        hasSelectAll={false}
                        disableSearch={true}
                        onChange={this.inputChange.bind(this)}
                        labelledBy={"dwnLabel" + this.props.field.column + "_" + this.props.field.guid}
                        value={preselectedValues}
                        options={propFieldValues}
                    />
                    <div className="explanation-text">{this.props.field.explanationText}</div>
                    <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                        className="invalid-message">
                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                        {this.state.model.errorMessage}
                    </div>
                </Col>
            </FormGroup>
        )
    }
}

export default MultiselectDropdownFieldComponent;