import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { Zoom } from "react-toastify";
import LocationDropdown from "../common/LocationDropdown";
import SeminarLeaderDropdown from "../common/SeminarLeaderDropdown";
import SeminarLengthDropdown from "../common/SeminarLengthDropdown";
import DeliveryFormatsDropdown from "../common/DeliveryFormatsDropdown";
import CourseProgramsDropdown from "../common/CourseProgramsDropdown";
import { Responsive } from "../../../utils/Responsive";
import { getDeviceTypeInfo } from "../../../utils/utils";

import * as filterUtils from "./filterUtils";

import "react-datepicker/dist/react-datepicker.css";
import EthicsHoursDropdown from "../common/EthicsHoursDropdown";
import CertificateProgramsDropdown from "../common/CertificateProgramsDropdown";
import SubjectDropdown from "../common/SubjectDropdown";
import KeywordsSearch from "../common/KeywordsSearch";
import BookmarkDropdown from "../common/BookmarkDropdown";
import SelectedLocations from "../common/SelectedLocations";
import SelectedSubjects from "../common/SelectedSubjects";
import SelectedSeminarLength from "../common/SelectedSeminarLength";
import SelectedDeliveryFormats from "../common/SelectedDeliveryFormats";
import SelectedCoursePrograms from "../common/SelectedCoursePrograms";
import SelectedEthicsHours from "../common/SelectedEthicsHours";
import SelectedCertificatePrograms from "../common/SelectedCertificatePrograms";
import SelectedLeaders from "../common/SelectedLeaders";
import PassportCreditsDropdown from "../common/PassportCreditsDropdown";
import SelectedPassportCredits from "../common/SelectedPassportCredits";
import SelectedStartDate from "../common/SelectedStartDate";
import SelectedEndDate from "../common/SelectedEndDate";


class SearchFiltersCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            copyLinkVisible: false,
            newCoursesOnlyChecked: false,
            expandedMobile: false,
            selectedFiltersMobile: 0,
            expandedFiltersMobile: false
        };

        this.handleChangeTimeOne(this.props.startDate);
        this.searchButton();
    }

    getSelectedDeliveryFormatsCalendar() {
        return new Array("IP", "OL");
    }

    deliveryFormatsCalendar(df) {	
        let deliveryFormats2 = new Array();	
        if ((df != null) && (typeof df != 'undefined')) {	
            for (var i=0; i< df.length; i++){	
                //console.log("TESTTTTTTTTTT: " + df[i].Code);	
	            if ((df[i].Code == "OL") || (df[i].Code == "IP")){	
	            	deliveryFormats2.push(df[i]);	
	            }	
            }	
        }	
        return deliveryFormats2;	
    }

    onClickButtonMobileFilters = () => () => {
        this.onClickCloseFiltersMobile();
        this.searchButton();
    };

    onClickSeeFiltersMobile = () => () => {
        this.setState({
            expandedFiltersMobile: true
        });
    };
    onClickCloseFiltersMobile = () => () => {
        const { selectedLocations, selectedSubjects, selectedDeliveryFormats, selectedCoursePrograms,
            selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, selectedPassportCredits } = this.props;
        let selFiltersMobile = selectedLocations.length + selectedSubjects.length + selectedPassportCredits.length + selectedDeliveryFormats.length +
            selectedCoursePrograms.length + selectedEthicsHours.length + selectedCertificatePrograms.length + selectedLeaders.length;
        this.setState({
            selectedFiltersMobile: selFiltersMobile,
            expandedFiltersMobile: false
        });
        this.searchButton();
    };
    onClickCloseFiltersMobileSearch = () => () => {
        const { selectedLocations, selectedSubjects, selectedDeliveryFormats, selectedCoursePrograms,
            selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, selectedPassportCredits } = this.props;
        let selFiltersMobile = selectedLocations.length + selectedSubjects.length + selectedPassportCredits.length + selectedDeliveryFormats.length +
            selectedCoursePrograms.length + selectedEthicsHours.length + selectedCertificatePrograms.length + selectedLeaders.length;
        this.setState({
            selectedFiltersMobile: selFiltersMobile,
            expandedFiltersMobile: false
        });
        this.searchButton();
    };
    onClickMobileSummary = () => () => {
        this.setState({
            expandedMobile: true
        });
    };
    onClickButtonMobileSummary = () => () => {
        this.setState({
            expandedMobile: false
        });
    };
    newCoursesOnly = () => {
        const searchCriteria = this.props.allSearchCriteria;	
        let newCourses = this.state.newCoursesOnlyChecked ? false : true;
        //console.log("new courses only: " + newCourses);


        // okay the calendar Needs a start and end date.
        // make sure we have start and end dates
        // check for null date
        var startDate2 = this.props.startDate;
        if ((startDate2 == null) || (typeof startDate2 == 'undefined')) {
            startDate2 = new Date();
            //console.log("search button start date2a: " + startDate2);
        }

        // check for date before today
        if (startDate2 < new Date()) {
            startDate2 = new Date();
            //console.log("search button start date2c: " + startDate2);
        }

        // check for date that isn't first of the month
        // really, just set day to '1'
        // but setDate returns seconds so we won't use that
        startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

        // lets set the end date as well
        var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

        //in case it was changed above
        this.props.setStartDate(startDate2);
        this.props.setEndDate(endDate2);

        //console.log("search button start date2: " + startDate2);
        //console.log("search button end date2: " + endDate2);

        let startDateIso = startDate2 ? moment(startDate2).format() : null;
        let endDateIso = endDate2 ? moment(endDate2).format() : null;


        const searchRequest = {
            StartDate: startDateIso,
            EndDate: endDateIso,
            PageIndex: 0,
            PageSize: searchCriteria && searchCriteria.PageSize ? searchCriteria.PageSize : 9,
            CpabcVenueId: searchCriteria && searchCriteria.CpabcVenueId ? searchCriteria.CpabcVenueId : [],
            CpabcCourseSubcategory: searchCriteria && searchCriteria.CpabcCourseSubcategory ? searchCriteria.CpabcCourseSubcategory : [],
            DurationHours: searchCriteria && searchCriteria.DurationHours ? searchCriteria.DurationHours : [],
            CpabcCourseDeliveryMethod: searchCriteria && searchCriteria.CpabcCourseDeliveryMethod ? searchCriteria.CpabcCourseDeliveryMethod : [], //test this.getSelectedDeliveryFormatsCalendar(), //test searchCriteria && searchCriteria.CpabcCourseDeliveryMethod ? searchCriteria.CpabcCourseDeliveryMethod : [],
            CpabcCourseProgram: searchCriteria && searchCriteria.CpabcCourseProgram ? searchCriteria.CpabcCourseProgram : [],
            EthicsHours: searchCriteria && searchCriteria.EthicsHours ? searchCriteria.EthicsHours : [],
            EthicsHoursGreaterThan0: searchCriteria && searchCriteria.EthicsHoursGreaterThan0 ? searchCriteria.EthicsHoursGreaterThan0 : null,
            CpabcCertificateProgram: searchCriteria && searchCriteria.CpabcCertificateProgram ? searchCriteria.CpabcCertificateProgram : [],
            CpabcSeminarLeaderId: searchCriteria && searchCriteria.CpabcSeminarLeaderId ? searchCriteria.CpabcSeminarLeaderId : [],
            PassportCredits: searchCriteria && searchCriteria.PassportCredits ? searchCriteria.PassportCredits : [],
            Keywords: searchCriteria && searchCriteria.Keywords ? searchCriteria.Keywords : "",
            FromInit: false,
            NewCourses: newCourses,
            Sort: searchCriteria && searchCriteria.Sort ? searchCriteria.Sort : 3
        }
        this.props.setAllSearchCriteria(searchRequest);
        this.props.setLoadingFalse(true);
        this.props.selectCourse(null);
        this.props.resetSearchResultItems();
        this.props.setVisibleListBookmarks(false);
        this.props.setCourseFromHashVisible(false);
        this.props.searchPd(searchRequest);
        this.setState({
            newCoursesOnlyChecked: !this.state.newCoursesOnlyChecked
        });
        //console.log("new courses only2: " + this.state.newCoursesOnlyChecked);
    };



    reduceLocations() {
        const { locations } = this.props;

        const listLocations = Object.values(locations.reduce((acc, item) => {
            if (!acc[item.City])
                acc[item.City] = {
                    City: item.City,
                    LocationId: []
                };
            acc[item.City].LocationId.push(item.LocationId);
            return acc;
        },
            {}));

        listLocations.sort((a, b) => a.City.localeCompare(b.City));

        this.setState({
            locations: listLocations
        });
    }

    fillBookmarks() {
        //const storageBookmarks = JSON.parse(localStorage.getItem("ngStorage-bookmarksCalendar"));
        const storageBookmarks = JSON.parse(localStorage.getItem("ngStorage-bookmarks"));
        this.props.selectBookmark(storageBookmarks ? storageBookmarks : []);
    }

    componentDidMount() {
        const { searchResult, sortBy, setAllSearchCriteria, courseSubjects, selectedSubjects, seminarLength, selectedSeminarLength, deliveryFormats, selectedDeliveryFormats,
            coursePrograms, selectedCoursePrograms, ethicsHours, selectedEthicsHours, certificatePrograms, selectedCertificatePrograms, leaders, selectedLeaders,
            locations, selectedLocations, passportCredits, selectedPassportCredits, redirectUrl, setHashAllSearchCriteria, tags, setIsLoadingPdDetails } = this.props;

        this.reduceLocations();
        this.fillBookmarks();

        let device = getDeviceTypeInfo();	
        let deviceType;	
        if (device) {	
            deviceType = device.deviceType;	
        };

        let hashSplitIn = window.location.hash;
        if (window.location.hash.includes("=")) {
            hashSplitIn = window.location.hash.split('#/').join('#');
        }
        let hashSplit = hashSplitIn.split('/');
        if (hashSplit[0].includes("=") && hashSplit != "") {
            let queryString = hashSplit[0].split('#').join('?');
            const urlParams = new URLSearchParams(decodeURI(queryString));

            const startDateHash = urlParams.get('from');
            let startDate;
            if (startDateHash) {
                startDate = new Date(startDateHash);
                this.props.setStartDate(startDate);
            }

            const endDateHash = urlParams.get('to');
            let endDate;
            if (endDateHash) {
                endDate = new Date(endDateHash);
                this.props.setEndDate(endDate);
            }

            const keywordsHash = urlParams.get('keywords');
            let keywordsFromHash = keywordsHash ? keywordsHash : '';
            this.props.setKeywordsSearchValue(keywordsFromHash);

            const locationHash = urlParams.get('location');
            let listLocations = [];
            if (locationHash) {
                const { locations } = this.props;
                const locationsArray = locationHash.split('|');
                for (var i = 0; i < locationsArray.length; i++) {
                    let locationIdArray = [];
                    for (var j = 0; j < locations.length; j++) {
                        if (locationsArray[i] == locations[j].City) {
                            locationIdArray.push(locations[j].LocationId
                            );
                        }
                    }
                    listLocations.push({
                        City: locationsArray[i],
                        LocationId: locationIdArray
                    });
                }
                this.props.setSelectedLocations(listLocations);
            }

            const subjectHash = urlParams.get('subject');
            let listSubjects = [];
            if (subjectHash) {
                const subjectArray = subjectHash.split('|');
                listSubjects = filterUtils.getListFromQueryWithChildren(subjectArray, courseSubjects, "Code", "Description");
                this.props.setSelectedSubjects(listSubjects);
            }

            const passportCreditsHash = urlParams.get('passportcredits');
            let listPassportCredits = [];
            if (passportCreditsHash) {
                const passportCreditsArray = passportCreditsHash.split('|');
                listPassportCredits = filterUtils.getListFromQuery(passportCreditsArray, passportCredits, "Value", "Text");
                this.props.setSelectedPassportCredits(listPassportCredits);
            }

            const deliveryFormatHash = urlParams.get('deliveryformat');
            let listDeliveryFormat = [];
            if (deliveryFormatHash) {
                const deliveryFormatArray = deliveryFormatHash.split('|');
                listDeliveryFormat = filterUtils.getListFromQuery(deliveryFormatArray, deliveryFormats, "Code", "Description");
                this.props.setSelectedDeliveryFormats(listDeliveryFormat);
            }

            const seminarTypeHash = urlParams.get('seminartype');
            let listSeminarType = [];
            if (seminarTypeHash) {
                const seminarTypeArray = seminarTypeHash.split('|');
                listSeminarType = filterUtils.getListFromQuery(seminarTypeArray, coursePrograms, "Code", "Description");
                this.props.setSelectedCoursePrograms(listSeminarType);

            }

            const ethicsHoursHash = urlParams.get('ethichours');
            let listEthicsHours = [];
            if (ethicsHoursHash) {
                const ethicsHoursArray = ethicsHoursHash.split('|');
                listEthicsHours = filterUtils.getListFromQuery(ethicsHoursArray, ethicsHours, "Value", "Text");
                this.props.setSelectedEthicsHours(listEthicsHours);

            }

            const certificateProgramHash = urlParams.get('certificateprogram');
            let listCertificateProgram = [];
            if (certificateProgramHash) {
                const certificateProgramArray = certificateProgramHash.split('|');
                listCertificateProgram = filterUtils.getListFromQuery(certificateProgramArray, certificatePrograms, "Code", "Description");
                this.props.setSelectedCertificatePrograms(listCertificateProgram);
            }

            const seminarLeaderHash = urlParams.get('seminarleader');
            let listSeminarLeader = [];
            if (seminarLeaderHash) {
                const seminarLeaderArray = seminarLeaderHash.split('|');
                listSeminarLeader = filterUtils.getListFromQuerySemLeader(seminarLeaderArray, leaders, "Value", "Text");
                this.props.setSelectedLeaders(listSeminarLeader);
            }
            //this.searchButton();

           let listTags = [];	
            if (hashSplit[0].includes('#tag=')) {	
                let queryTag = hashSplit[0].split('&').join('%26');	
                let queryTagString = queryTag.split('#').join('?');	
                const urlParamsTag = new URLSearchParams(queryTagString);	
                const tagHash = urlParamsTag.get('tag');	
                if (tagHash) {	
                    const tagHashArray = tagHash.split('|');	
                    for (var i = 0; i < tagHashArray.length; i++) {	
                        for (var j = 0; j < tags.length; j++) {	
                            if (tagHashArray[i].toLowerCase() === tags[j].Description.toLowerCase()) {	
                                listTags.push(tags[j].CpaBcCourseTagCd);	
                                break;	
                            }	
                        }	
                    }	
                }	
            }


            const listCpabcVenueId = filterUtils.getListLocationIds(listLocations, "LocationId");
            const listCpabcCourseSubcategory = filterUtils.getListIds(listSubjects, "Code");
            const listDurationHours = filterUtils.getSimpleListIds(selectedSeminarLength, "Value");
            const listPassportsCredits = filterUtils.getSimpleListIds(listPassportCredits, "Value");
            const listDeliveryFormats = filterUtils.getListIds(listDeliveryFormat, "Code");
            const listCoursePrograms = filterUtils.getListIds(listSeminarType, "Code");
            const listEthicHours = filterUtils.getSimpleListIds(listEthicsHours, "Value");
            const ethicsHoursGreaterThan0 = (listEthicHours[0] == "allorany") ? true : null;
            const listCertificatePrograms = filterUtils.getListIds(listCertificateProgram, "Code");
            const listLeaders = filterUtils.getListIds(listSeminarLeader, "LeaderId");
            const sortByValue = 1; //test (sortBy === "Alpha") ? 3 : 1;

            // okay the calendar Needs a start and end date.
            // make sure we have start and end dates
            // check for null date
            var startDate2 = startDate;
            if ((startDate2 == null) || (typeof startDate == 'undefined')) {
                startDate2 = new Date();
                //console.log("search button start date2a: " + startDate2);
            }

            // check for date before today
            if (startDate2 < new Date()) {
                startDate2 = new Date();
                //console.log("search button start date2c: " + startDate2);
            }

            // check for date that isn't first of the month
            // really, just set day to '1'
            // but setDate returns seconds so we won't use that
            startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

            // lets set the end date as well
            var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

            //in case it was changed above
            this.props.setStartDate(startDate2);
            this.props.setEndDate(endDate2);

            //console.log("search button start date2: " + startDate2);
            //console.log("search button end date2: " + endDate2);

            let startDateIso = startDate2 ? moment(startDate2).format() : null;
            let endDateIso = endDate2 ? moment(endDate2).format() : null;

            //test let startDateIso = startDate ? moment(startDate).format() : null;
            //test let endDateIso = endDate ? moment(endDate).format() : null;

            const searchRequest = {
                StartDate: startDateIso,
                EndDate: endDateIso,
                PageIndex: 0,
                PageSize: 500, //test searchResult.pageSize,
                CpabcVenueId: listCpabcVenueId,
                CpabcCourseSubcategory: listCpabcCourseSubcategory,
                DurationHours: listDurationHours,
                CpabcCourseDeliveryMethod: this.getSelectedDeliveryFormatsCalendar(), //test listDeliveryFormats,
                CpabcCourseProgram: listCoursePrograms,
                EthicsHours: listEthicHours,
                EthicsHoursGreaterThan0: ethicsHoursGreaterThan0,
                CpabcCertificateProgram: listCertificatePrograms,
                CpabcSeminarLeaderId: listLeaders,
                PassportCredits: listPassportsCredits,
                Keywords: keywordsFromHash,
                Sort: sortByValue,
            }

            if (listTags.length>0) {	
                searchRequest.Tags = listTags;	
            }	
            if (deviceType === "Mobile") {	
                searchRequest.deviceTypeinfo = "Mobile";	
            }

            let sessionStorageSearchRequest = JSON.stringify(searchRequest);
            sessionStorage.setItem("ngStorage-searchCriteriaCalendar", sessionStorageSearchRequest);
            setAllSearchCriteria(searchRequest);

            this.props.setLoadingFalse(true);
            //this.props.selectCourse(null);
            this.props.resetSearchResultItems();
            this.props.setVisibleListBookmarks(false);
            this.props.setCourseFromHashVisible(false);


            let hashPage = window.location.search;
            let hrefPageHash = window.location.href;
            hrefPageHash = hrefPageHash.split(hashPage).join('');
            window.location.href = hrefPageHash;

            //window.location.hash = '';
            this.props.searchPd(searchRequest);

            let searchPage = window.location.search;
            let hrefPage = window.location.href;
            hrefPage = hrefPage.split(searchPage).join('');
            window.location.href = hrefPage;
        }

        else if (!hashSplit[0].includes("=") && hashSplit != "") {
            const emptySearchRequest = filterUtils.getEmptySearchRequest();	
            this.props.getPdDetailsHash(hashSplit[2], emptySearchRequest);	
            this.props.setCourseFromHashVisible(true);	
        }	
        else {	
            let arraySearchCriteria = JSON.parse(sessionStorage.getItem('ngStorage-searchCriteriaCalendar'));	
            let emptySearchRequest = filterUtils.getEmptySearchRequest();	
            let finalarraySearchCriteria = (arraySearchCriteria) ? arraySearchCriteria : emptySearchRequest;	
            if (finalarraySearchCriteria) {	
                finalarraySearchCriteria.PageIndex = 0;	
            }	
            setAllSearchCriteria(finalarraySearchCriteria);

            if (finalarraySearchCriteria) {
                let listLocation = [];
                if (finalarraySearchCriteria.CpabcVenueId.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.CpabcVenueId.length; i++) {
                        for (var j = 0; j < locations.length; j++) {
                            if (finalarraySearchCriteria.CpabcVenueId[i] == locations[j].LocationId) {
                                listLocation.push({
                                    LocationId: locations[j].LocationId,
                                    City: locations[j].City
                                });

                            }
                        }
                    }
                    let finalListLocations = Object.values(listLocation.reduce((acc, item) => {
                        if (!acc[item.City])
                            acc[item.City] = {
                                City: item.City,
                                LocationId: []
                            };
                        acc[item.City].LocationId.push(item.LocationId);
                        return acc;
                    },
                        {}))

                    for (var i = 0; i < finalListLocations.length; i++) {
                        selectedLocations.push({
                            LocationId: finalListLocations[i].LocationId,
                            City: finalListLocations[i].City
                        });
                    }
                }

                if (finalarraySearchCriteria.DurationHours.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.DurationHours.length; i++) {
                        for (var j = 0; j < seminarLength.length; j++) {
                            if (finalarraySearchCriteria.DurationHours[i] == seminarLength[j].Value) {
                                selectedSeminarLength.push({
                                    Value: seminarLength[j].Value,
                                    Text: seminarLength[j].Text
                                });
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.CpabcCourseSubcategory.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.CpabcCourseSubcategory.length; i++) {
                        for (var j = 0; j < courseSubjects.length; j++) {
                            for (var k = 0; k < courseSubjects[j].Children.length; k++) {
                                if (finalarraySearchCriteria.CpabcCourseSubcategory[i] ==
                                    courseSubjects[j].Children[k].Code) {
                                    selectedSubjects.push({
                                        Code: courseSubjects[j].Children[k].Code,
                                        Description: courseSubjects[j].Children[k].Description
                                    });
                                }
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.CpabcCourseDeliveryMethod.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.CpabcCourseDeliveryMethod.length; i++) {
                        for (var j = 0; j < deliveryFormats.length; j++) {
                            if (finalarraySearchCriteria.CpabcCourseDeliveryMethod[i] == deliveryFormats[j].Code) {
                                selectedDeliveryFormats.push({
                                    Code: deliveryFormats[j].Code,
                                    Description: deliveryFormats[j].Description
                                });
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.CpabcCourseProgram.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.CpabcCourseProgram.length; i++) {
                        for (var j = 0; j < coursePrograms.length; j++) {
                            if (finalarraySearchCriteria.CpabcCourseProgram[i] == coursePrograms[j].Code) {
                                selectedCoursePrograms.push({
                                    Code: coursePrograms[j].Code,
                                    Description: coursePrograms[j].Description
                                });
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.EthicsHours.length > 0) {
                    if (finalarraySearchCriteria.EthicsHours.length == 1 &&
                        finalarraySearchCriteria.EthicsHours[0] === "allorany") {
                        selectedEthicsHours.push({
                            Value: "allorany",
                            Text: "Any or All"
                        });
                    } else {
                        for (var i = 0; i < finalarraySearchCriteria.EthicsHours.length; i++) {
                            for (var j = 0; j < ethicsHours.length; j++) {
                                if (finalarraySearchCriteria.EthicsHours[i] == ethicsHours[j].Value) {
                                    selectedEthicsHours.push({
                                        Value: ethicsHours[j].Value,
                                        Text: ethicsHours[j].Text
                                    });
                                }
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.CpabcCertificateProgram.length > 0) {
                    if (finalarraySearchCriteria.CpabcCertificateProgram.length == 1 &&
                        finalarraySearchCriteria.CpabcCertificateProgram[0] === "allorany") {
                        selectedCertificatePrograms.push({
                            Code: "allorany",
                            Description: "Any or All"
                        });
                    } else {
                        for (var i = 0; i < finalarraySearchCriteria.CpabcCertificateProgram.length; i++) {
                            for (var j = 0; j < certificatePrograms.length; j++) {
                                if (finalarraySearchCriteria.CpabcCertificateProgram[i] == certificatePrograms[j].Code) {
                                    selectedCertificatePrograms.push({
                                        Code: certificatePrograms[j].Code,
                                        Description: certificatePrograms[j].Description
                                    });
                                }
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.CpabcSeminarLeaderId.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.CpabcSeminarLeaderId.length; i++) {
                        for (var j = 0; j < leaders.length; j++) {
                            if (finalarraySearchCriteria.CpabcSeminarLeaderId[i] == leaders[j].LeaderId) {
                                selectedLeaders.push({
                                    LeaderId: leaders[j].LeaderId,
                                    Name: leaders[j].FirstName + ' ' + leaders[j].LastName
                                });
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.PassportCredits.length > 0) {
                    for (var i = 0; i < finalarraySearchCriteria.PassportCredits.length; i++) {
                        for (var j = 0; j < passportCredits.length; j++) {
                            if (finalarraySearchCriteria.PassportCredits[i] == passportCredits[j].Value) {
                                selectedPassportCredits.push({
                                    Value: passportCredits[j].Value,
                                    Text: passportCredits[j].Text
                                });
                            }
                        }
                    }
                }

                if (finalarraySearchCriteria.Keywords) {
                    this.props.setKeywordsSearchValue(finalarraySearchCriteria.Keywords)
                }

                if (finalarraySearchCriteria.StartDate) {
                    let startDate = new Date(finalarraySearchCriteria.StartDate);
                    this.props.setStartDate(startDate);
                }

                if (finalarraySearchCriteria.EndDate) {
                    let endDate = new Date(finalarraySearchCriteria.EndDate);
                    this.props.setEndDate(endDate);
                }

                if (finalarraySearchCriteria.Sort) {
                    let sortBy = "Date"; //test (finalarraySearchCriteria.Sort === 3) ? "Alpha" : "Date";
                    this.props.setSortByValue(sortBy);
                }

                if ((finalarraySearchCriteria.CpabcVenueId.length > 0 ||
                    finalarraySearchCriteria.DurationHours.length > 0 ||
                    finalarraySearchCriteria.CpabcCourseSubcategory.length > 0 ||
                    finalarraySearchCriteria.CpabcCourseDeliveryMethod.length > 0 ||
                    finalarraySearchCriteria.CpabcCourseProgram.length > 0 ||
                    finalarraySearchCriteria.EthicsHours.length > 0 ||
                    finalarraySearchCriteria.CpabcCertificateProgram.length > 0 ||
                    finalarraySearchCriteria.CpabcSeminarLeaderId.length > 0 ||
                    finalarraySearchCriteria.PassportCredits.length > 0 ||
                    finalarraySearchCriteria.Keywords != '' ||
                    finalarraySearchCriteria.StartDate ||
                    finalarraySearchCriteria.EndDate) && redirectUrl === undefined) {
                    this.setState({
                        copyLinkVisible: true
                    });
                }

                //this.setSearchCriteria();
                let firstCompleted = false;
                let startDate2 = finalarraySearchCriteria.StartDate ? new Date(finalarraySearchCriteria.StartDate) : null;
                let hashStartDate = startDate2 ? moment(startDate2).format("YYYY-MM-D") : '';
                let finalHashStartDate = hashStartDate == '' ? '' : 'from=' + hashStartDate;
                if (hashStartDate != '') {
                    firstCompleted = true;
                }

                let endDate2 = finalarraySearchCriteria.EndDate ? new Date(finalarraySearchCriteria.EndDate) : null;
                let hashEndDate = endDate2 ? moment(endDate2).format("YYYY-MM-D") : '';
                let finalHashEndDate = hashEndDate == '' ? '' : 'to=' + hashEndDate;
                if (firstCompleted && hashEndDate != '') {
                    finalHashEndDate = '&to=' + hashEndDate;
                }
                else if (!firstCompleted && hashEndDate != '') {
                    firstCompleted = true;
                }

                //#region HashKeywords
                let hashKeywords = finalarraySearchCriteria.Keywords != '' ? finalarraySearchCriteria.Keywords : '';
                hashKeywords = hashKeywords.split("&").join("%26");
                let finalHashKeywords = hashKeywords == '' ? '' : 'keywords=' + hashKeywords;
                if (firstCompleted && hashKeywords != '') {
                    finalHashKeywords = '&keywords=' + hashKeywords;
                }
                else if (!firstCompleted && hashKeywords != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashLocations
                const listLocations = filterUtils.getListIds(selectedLocations, "City");
                let listHashLocations = '';
                if (listLocations.length > 0) {
                    listHashLocations = filterUtils.getHashList(listLocations);
                }
                if (listHashLocations === 'Any or All') {
                    listHashLocations = '';
                }
                let hashLocations = listHashLocations != '' ? listHashLocations : '';
                let finalHashLocations = hashLocations == '' ? '' : 'location=' + hashLocations;
                if (firstCompleted && hashLocations != '') {
                    finalHashLocations = '&location=' + hashLocations;
                }
                else if (!firstCompleted && hashLocations != '') {
                    firstCompleted = true;
                }

                //#endregion

                //#region ListHashSubjects
                const listSubjects = filterUtils.getListIds(selectedSubjects, "Description");
                let listHashSubjects = '';
                if (listSubjects.length > 0) {
                    listHashSubjects = filterUtils.getHashList(listSubjects);
                }
                if (listHashSubjects === 'Any or All') {
                    listHashSubjects = '';
                }

                listHashSubjects = listHashSubjects.split("&").join("%26");

                let hashSubjects = listHashSubjects != '' ? listHashSubjects : '';
                let finalHashSubjects = hashSubjects == '' ? '' : 'subject=' + hashSubjects;
                if (firstCompleted && hashSubjects != '') {

                    finalHashSubjects = '&subject=' + hashSubjects;
                }
                else if (!firstCompleted && hashSubjects != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashPassportCredits
                const listPassCredits = filterUtils.getListIds(selectedPassportCredits, "Text");
                let listHashPassportCredits = '';
                if (listPassCredits.length > 0) {
                    listHashPassportCredits = filterUtils.getHashList(listPassCredits);
                }
                if (listHashPassportCredits === 'Any or All') {
                    listHashPassportCredits = '';
                }
                let hashPassportCredits = listHashPassportCredits != '' ? listHashPassportCredits : '';
                let finalHashPassportCredits = hashPassportCredits == '' ? '' : 'passportcredits=' + hashPassportCredits;
                if (firstCompleted && hashPassportCredits != '') {
                    finalHashPassportCredits = '&passportcredits=' + hashPassportCredits;
                }
                else if (!firstCompleted && hashPassportCredits != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashDeliveryFormat
                const listDelivFormats = filterUtils.getListIds(selectedDeliveryFormats, "Description");
                let listHashDeliveryFormat = '';
                if (listDelivFormats.length > 0) {
                    listHashDeliveryFormat = filterUtils.getHashList(listDelivFormats);
                }
                if (listHashDeliveryFormat === 'Any or All') {
                    listHashDeliveryFormat = '';
                }
                listHashDeliveryFormat = listHashDeliveryFormat.split("&").join("%26");

                let hashDeliveryFormat = listHashDeliveryFormat != '' ? listHashDeliveryFormat : '';
                let finalHashDeliveryFormat = hashDeliveryFormat == '' ? '' : 'deliveryformat=' + hashDeliveryFormat;
                if (firstCompleted && hashDeliveryFormat != '') {
                    finalHashDeliveryFormat = '&deliveryformat=' + hashDeliveryFormat;
                }
                else if (!firstCompleted && hashDeliveryFormat != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashSeminarType
                const listSeminarType = filterUtils.getListIds(selectedCoursePrograms, "Description");
                let listHashSeminarType = '';
                if (listSeminarType.length > 0) {
                    listHashSeminarType = filterUtils.getHashList(listSeminarType);
                }
                if (listHashSeminarType === 'Any or All') {
                    listHashSeminarType = '';
                }

                listHashSeminarType = listHashSeminarType.split("&").join("%26");

                let hashSeminarType = listHashSeminarType != '' ? listHashSeminarType : '';
                let finalHashSeminarType = hashSeminarType == '' ? '' : 'seminartype=' + hashSeminarType;
                if (firstCompleted && hashSeminarType != '') {
                    finalHashSeminarType = '&seminartype=' + hashSeminarType;
                }
                else if (!firstCompleted && hashSeminarType != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashEthicsHours
                const listEthHours = filterUtils.getListIds(selectedEthicsHours, "Text");
                let listHashEthHours = '';
                if (listEthHours.length > 0) {
                    listHashEthHours = filterUtils.getHashList(listEthHours);
                }
                let hashEthHours = listHashEthHours != '' ? listHashEthHours : '';
                let finalHashEthHours = hashEthHours == '' ? '' : 'ethichours=' + hashEthHours;
                if (firstCompleted && hashEthHours != '') {
                    finalHashEthHours = '&ethichours=' + hashEthHours;
                }
                else if (!firstCompleted && hashEthHours != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashCertificateProgram
                const listCertifProgram = filterUtils.getListIds(selectedCertificatePrograms, "Description");
                let listHashCertifProgram = '';
                if (listCertifProgram.length > 0) {
                    listHashCertifProgram = filterUtils.getHashList(listCertifProgram);
                }

                listHashCertifProgram = listHashCertifProgram.split("&").join("%26");

                let hashCertifProgram = listHashCertifProgram != '' ? listHashCertifProgram : '';
                let finalHashCertifProgram = hashCertifProgram == '' ? '' : 'certificateprogram=' + hashCertifProgram;
                if (firstCompleted && hashCertifProgram != '') {
                    finalHashCertifProgram = '&certificateprogram=' + hashCertifProgram;
                }
                else if (!firstCompleted && hashCertifProgram != '') {
                    firstCompleted = true;
                }
                //#endregion

                //#region ListHashSeminarLeader
                const listSeminarLeader = filterUtils.getListIds(selectedLeaders, "Name");
                let listHashSeminarLeader = '';
                if (listSeminarLeader.length > 0) {
                    listHashSeminarLeader = filterUtils.getHashList(listSeminarLeader);
                }
                if (listHashSeminarLeader === 'Any or All') {
                    listHashSeminarLeader = '';
                }

                let hashSeminarLeader = listHashSeminarLeader != '' ? listHashSeminarLeader : '';
                let finalHashSeminarLeader = hashSeminarLeader == '' ? '' : 'seminarleader=' + hashSeminarLeader;
                if (firstCompleted && hashSeminarLeader != '') {
                    finalHashSeminarLeader = '&seminarleader=' + hashSeminarLeader;
                }
                else if (!firstCompleted && hashSeminarLeader != '') {
                    firstCompleted = true;
                }
                //#endregion


                let finalHashAllSearchFilters =
                    finalHashStartDate +
                    finalHashEndDate +
                    finalHashKeywords +
                    finalHashLocations +
                    finalHashSubjects +
                    finalHashPassportCredits +
                    finalHashDeliveryFormat +
                    finalHashSeminarType +
                    finalHashEthHours +
                    finalHashCertifProgram +
                    finalHashSeminarLeader;

                if (finalHashAllSearchFilters != "") {
                    setHashAllSearchCriteria(finalHashAllSearchFilters);
                }

                if (deviceType === "Mobile") {	
                    finalarraySearchCriteria.deviceTypeinfo = "Mobile";	
                }

                this.props.setLoadingFalse(true);
                //this.props.selectCourse(null);
                this.props.resetSearchResultItems();
                this.props.setVisibleListBookmarks(false);
                this.props.setCourseFromHashVisible(false);

                if (redirectUrl === undefined) {
                    setTimeout(() => {
                        this.props.searchPd(finalarraySearchCriteria);
                    },
                        100);
                }


            } else {

                // okay the calendar Needs a start and end date.
                // make sure we have start and end dates
                // check for null date
                var startDate2 = new Date();

                // check for date that isn't first of the month
                // really, just set day to '1'
                // but setDate returns seconds so we won't use that
                startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

                // lets set the end date as well
                var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

                //in case it was changed above
                this.props.setStartDate(startDate2);
                this.props.setEndDate(endDate2);

                //console.log("search button start date2: " + startDate2);
                //console.log("search button end date2: " + endDate2);

                let startDateIso = startDate2 ? moment(startDate2).format() : null;
                let endDateIso = endDate2 ? moment(endDate2).format() : null;

                const emptySearchRequest = {
                    StartDate: startDateIso,
                    EndDate: endDateIso,
                    PageIndex: 0,
                    PageSize: 500, //test 9,
                    CpabcVenueId: [],
                    CpabcCourseSubcategory: [],
                    DurationHours: [],
                    CpabcCourseDeliveryMethod: [], //test this.getSelectedDeliveryFormatsCalendar(), //test [],
                    CpabcCourseProgram: [],
                    EthicsHours: [],
                    EthicsHoursGreaterThan0: null,
                    CpabcCertificateProgram: [],
                    CpabcSeminarLeaderId: [],
                    PassportCredits: [],
                    Keywords: "",
                    Sort: 1 //test 3
                };

                if (deviceType === "Mobile") {	
                    emptySearchRequest.deviceTypeinfo = "Mobile";	
                }

                this.props.setCourseFromHashVisible(false);
                if (redirectUrl === undefined) {
                    setTimeout(() => { this.props.searchPd(emptySearchRequest); }, 100);
                }
            }
        }
        let selFiltersMobile = selectedLocations.length + selectedSubjects.length + selectedPassportCredits.length + selectedDeliveryFormats.length +
            selectedCoursePrograms.length + selectedEthicsHours.length + selectedCertificatePrograms.length + selectedLeaders.length;
        this.setState({
            selectedFiltersMobile: selFiltersMobile
        });
    }

    //#region onClick Search Button
    searchButton(monthChange = 0) {

        const { searchResult, selectedLocations, selectedSubjects, selectedSeminarLength, selectedDeliveryFormats, selectedCoursePrograms,
            selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, keywordsSearchValue, sortBy, startDate, endDate, setAllSearchCriteria, selectedPassportCredits, setHashAllSearchCriteria,
            redirectUrl, setNewCoursesOnlyVisible, setCopySearchTagVisible} = this.props

        // okay the calendar Needs a start and end date.
        // problem: they're constants here, so we can't change them.
        // solution ... create new variables! 

        // make sure we have start and end dates
        // check for null date
        var startDate2 = startDate;
        if ((startDate2 == null) || (typeof startDate == 'undefined')) {
            startDate2 = new Date();
            //console.log("search button start date2a: " + startDate2);
        }

        if (monthChange != 0) {
            startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + monthChange, 1);
            //console.log("search button start date2b: " + startDate2);
            //console.log("search button start date2bb: " + monthChange);
        }

        // check for date before today
        if (startDate2 < new Date()) {
            startDate2 = new Date();
            //console.log("search button start date2c: " + startDate2);
        }

        // check for date that isn't first of the month
        // really, just set day to '1'
        // but setDate returns seconds so we won't use that
        startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

        // lets set the end date as well
        var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

        //in case it was changed above
        this.props.setStartDate(startDate2);
        this.props.setEndDate(endDate2);

        //console.log("search button start date2: " + startDate2);
        //console.log("search button end date2: " + endDate2);

        setNewCoursesOnlyVisible(false);

        const listCpabcVenueId = filterUtils.getListLocationIds(selectedLocations, "LocationId");
        const listCpabcCourseSubcategory = filterUtils.getListIds(selectedSubjects, "Code");
        const listDurationHours = filterUtils.getSimpleListIds(selectedSeminarLength, "Value");
        const listPassportCredits = filterUtils.getSimpleListIds(selectedPassportCredits, "Value");
        const listDeliveryFormats = filterUtils.getListIds(selectedDeliveryFormats, "Code");
        const listCoursePrograms = filterUtils.getListIds(selectedCoursePrograms, "Code");
        const listEthicsHours = filterUtils.getSimpleListIds(selectedEthicsHours, "Value");
        const ethicsHoursGreaterThan0 = (listEthicsHours[0] == "allorany") ? true : null;
        const listCertificatePrograms = filterUtils.getListIds(selectedCertificatePrograms, "Code");
        const listLeaders = filterUtils.getListIds(selectedLeaders, "LeaderId");
        const sortByValue = 1; //test (sortBy === "Alpha") ? 3 : 1;

        let startDateIso = startDate2 ? moment(startDate2).format() : null;
        let endDateIso = endDate2 ? moment(endDate2).format() : null;

        const searchRequest = {
            StartDate: startDateIso,
            EndDate: endDateIso,
            PageIndex: 0,
            PageSize: 500, //test searchResult.pageSize,
            CpabcVenueId: listCpabcVenueId,
            CpabcCourseSubcategory: listCpabcCourseSubcategory,
            DurationHours: listDurationHours,
            CpabcCourseDeliveryMethod: listDeliveryFormats, //test this.getSelectedDeliveryFormatsCalendar(), //new Array("IP", "OL"), //test listDeliveryFormats,
            CpabcCourseProgram: listCoursePrograms,
            EthicsHours: listEthicsHours,
            EthicsHoursGreaterThan0: ethicsHoursGreaterThan0,
            CpabcCertificateProgram: listCertificatePrograms,
            CpabcSeminarLeaderId: listLeaders,
            PassportCredits: listPassportCredits,
            Keywords: keywordsSearchValue,
            Sort: sortByValue,
        }

        //console.log("format: " + searchRequest.CpabcCourseDeliveryMethod);

        let sessionStorageSearchRequest = JSON.stringify(searchRequest);
        sessionStorage.setItem("ngStorage-searchCriteriaCalendar", sessionStorageSearchRequest);
        setAllSearchCriteria(searchRequest);

        let device = getDeviceTypeInfo();	
        let deviceType;	
        if (device) {	
            deviceType = device.deviceType;	
        };	
        if (deviceType === "Mobile") {	
            searchRequest.deviceTypeinfo = "Mobile";	
        }

        if (redirectUrl == "") {
            alert("Redirect URL is not founded");
        }
        else if (redirectUrl) {
            let finalUrl = redirectUrl.split("/").join("");
            window.location.href = window.location.href + finalUrl + '/';
        }
        else {

            this.props.setLoadingFalse(true);
            this.props.selectCourse(null);
            this.props.resetSearchResultItems();
            this.props.setVisibleListBookmarks(false);
            this.props.setCourseFromHashVisible(false);
            this.props.setCopySearchTagVisible(false);

            let selFiltersMobile = selectedLocations.length + selectedSubjects.length + selectedPassportCredits.length + selectedDeliveryFormats.length +
                selectedCoursePrograms.length + selectedEthicsHours.length + selectedCertificatePrograms.length + selectedLeaders.length;
            this.setState({
                selectedFiltersMobile: selFiltersMobile
            });

            this.props.searchPd(searchRequest);

            this.setSearchCriteria();

            if (listCpabcVenueId.length === 0 &&
                listDurationHours.length === 0 &&
                listCpabcCourseSubcategory.length === 0 &&
                listDeliveryFormats.length === 0 &&
                listCoursePrograms.length === 0 &&
                listEthicsHours.length === 0 &&
                listCertificatePrograms.length === 0 &&
                listLeaders.length === 0 &&
                listPassportCredits.length === 0 &&
                keywordsSearchValue === '' &&
                startDateIso === null &&
                endDateIso === null) {
                this.setState({
                    copyLinkVisible: false,
                    newCoursesOnlyChecked: false,
                });
            }
            else {
                this.setState({
                    copyLinkVisible: true,
                    newCoursesOnlyChecked: false,
                });
            }
        }

    }
    //#endregion

    setSearchCriteria() {

        const { searchResult, selectedLocations, selectedSubjects, selectedSeminarLength, selectedDeliveryFormats, selectedCoursePrograms,
            selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, keywordsSearchValue, sortBy, startDate, endDate, setAllSearchCriteria, selectedPassportCredits, setHashAllSearchCriteria,
            redirectUrl } = this.props


        let firstCompleted = false;
        let hashStartDate = startDate ? moment(startDate).format("YYYY-MM-D") : '';
        let finalHashStartDate = hashStartDate == '' ? '' : 'from=' + hashStartDate;
        if (hashStartDate != '') {
            firstCompleted = true;
        }

        let hashEndDate = endDate ? moment(endDate).format("YYYY-MM-D") : '';
        let finalHashEndDate = hashEndDate == '' ? '' : 'to=' + hashEndDate;
        if (firstCompleted && hashEndDate != '') {
            finalHashEndDate = '&to=' + hashEndDate;
        }
        else if (!firstCompleted && hashEndDate != '') {
            firstCompleted = true;
        }

        //#region HashKeywords
        let hashKeywords = keywordsSearchValue != '' ? keywordsSearchValue : '';
        hashKeywords = hashKeywords.split("&").join("%26");
        let finalHashKeywords = hashKeywords == '' ? '' : 'keywords=' + hashKeywords;
        if (firstCompleted && hashKeywords != '') {
            finalHashKeywords = '&keywords=' + hashKeywords;
        }
        else if (!firstCompleted && hashKeywords != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashLocations
        const listLocations = filterUtils.getListIds(selectedLocations, "City");
        let listHashLocations = '';
        if (listLocations.length > 0) {
            listHashLocations = filterUtils.getHashList(listLocations);
        }
        if (listHashLocations === 'Any or All') {
            listHashLocations = '';
        }
        let hashLocations = listHashLocations != '' ? listHashLocations : '';
        let finalHashLocations = hashLocations == '' ? '' : 'location=' + hashLocations;
        if (firstCompleted && hashLocations != '') {
            finalHashLocations = '&location=' + hashLocations;
        }
        else if (!firstCompleted && hashLocations != '') {
            firstCompleted = true;
        }

        //#endregion

        //#region ListHashSubjects
        const listSubjects = filterUtils.getListIds(selectedSubjects, "Description");
        let listHashSubjects = '';
        if (listSubjects.length > 0) {
            listHashSubjects = filterUtils.getHashList(listSubjects);
        }
        if (listHashSubjects === 'Any or All') {
            listHashSubjects = '';
        }

        listHashSubjects = listHashSubjects.split("&").join("%26");

        let hashSubjects = listHashSubjects != '' ? listHashSubjects : '';
        let finalHashSubjects = hashSubjects == '' ? '' : 'subject=' + hashSubjects;
        if (firstCompleted && hashSubjects != '') {

            finalHashSubjects = '&subject=' + hashSubjects;
        }
        else if (!firstCompleted && hashSubjects != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashPassportCredits
        const listPassCredits = filterUtils.getListIds(selectedPassportCredits, "Text");
        let listHashPassportCredits = '';
        if (listPassCredits.length > 0) {
            listHashPassportCredits = filterUtils.getHashList(listPassCredits);
        }
        if (listHashPassportCredits === 'Any or All') {
            listHashPassportCredits = '';
        }
        let hashPassportCredits = listHashPassportCredits != '' ? listHashPassportCredits : '';
        let finalHashPassportCredits = hashPassportCredits == '' ? '' : 'passportcredits=' + hashPassportCredits;
        if (firstCompleted && hashPassportCredits != '') {
            finalHashPassportCredits = '&passportcredits=' + hashPassportCredits;
        }
        else if (!firstCompleted && hashPassportCredits != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashDeliveryFormat
        const listDelivFormats = filterUtils.getListIds(selectedDeliveryFormats, "Description");
        let listHashDeliveryFormat = '';
        if (listDelivFormats.length > 0) {
            listHashDeliveryFormat = filterUtils.getHashList(listDelivFormats);
        }
        if (listHashDeliveryFormat === 'Any or All') {
            listHashDeliveryFormat = '';
        }
        listHashDeliveryFormat = listHashDeliveryFormat.split("&").join("%26");

        let hashDeliveryFormat = listHashDeliveryFormat != '' ? listHashDeliveryFormat : '';
        let finalHashDeliveryFormat = hashDeliveryFormat == '' ? '' : 'deliveryformat=' + hashDeliveryFormat;
        if (firstCompleted && hashDeliveryFormat != '') {
            finalHashDeliveryFormat = '&deliveryformat=' + hashDeliveryFormat;
        }
        else if (!firstCompleted && hashDeliveryFormat != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashSeminarType
        const listSeminarType = filterUtils.getListIds(selectedCoursePrograms, "Description");
        let listHashSeminarType = '';
        if (listSeminarType.length > 0) {
            listHashSeminarType = filterUtils.getHashList(listSeminarType);
        }
        if (listHashSeminarType === 'Any or All') {
            listHashSeminarType = '';
        }

        listHashSeminarType = listHashSeminarType.split("&").join("%26");

        let hashSeminarType = listHashSeminarType != '' ? listHashSeminarType : '';
        let finalHashSeminarType = hashSeminarType == '' ? '' : 'seminartype=' + hashSeminarType;
        if (firstCompleted && hashSeminarType != '') {
            finalHashSeminarType = '&seminartype=' + hashSeminarType;
        }
        else if (!firstCompleted && hashSeminarType != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashEthicsHours
        const listEthHours = filterUtils.getListIds(selectedEthicsHours, "Text");
        let listHashEthHours = '';
        if (listEthHours.length > 0) {
            listHashEthHours = filterUtils.getHashList(listEthHours);
        }
        let hashEthHours = listHashEthHours != '' ? listHashEthHours : '';
        let finalHashEthHours = hashEthHours == '' ? '' : 'ethichours=' + hashEthHours;
        if (firstCompleted && hashEthHours != '') {
            finalHashEthHours = '&ethichours=' + hashEthHours;
        }
        else if (!firstCompleted && hashEthHours != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashCertificateProgram
        const listCertifProgram = filterUtils.getListIds(selectedCertificatePrograms, "Description");
        let listHashCertifProgram = '';
        if (listCertifProgram.length > 0) {
            listHashCertifProgram = filterUtils.getHashList(listCertifProgram);
        }

        listHashCertifProgram = listHashCertifProgram.split("&").join("%26");

        let hashCertifProgram = listHashCertifProgram != '' ? listHashCertifProgram : '';
        let finalHashCertifProgram = hashCertifProgram == '' ? '' : 'certificateprogram=' + hashCertifProgram;
        if (firstCompleted && hashCertifProgram != '') {
            finalHashCertifProgram = '&certificateprogram=' + hashCertifProgram;
        }
        else if (!firstCompleted && hashCertifProgram != '') {
            firstCompleted = true;
        }
        //#endregion

        //#region ListHashSeminarLeader
        const listSeminarLeader = filterUtils.getListIds(selectedLeaders, "Name");
        let listHashSeminarLeader = '';
        if (listSeminarLeader.length > 0) {
            listHashSeminarLeader = filterUtils.getHashList(listSeminarLeader);
        }
        if (listHashSeminarLeader === 'Any or All') {
            listHashSeminarLeader = '';
        }

        let hashSeminarLeader = listHashSeminarLeader != '' ? listHashSeminarLeader : '';
        let finalHashSeminarLeader = hashSeminarLeader == '' ? '' : 'seminarleader=' + hashSeminarLeader;
        if (firstCompleted && hashSeminarLeader != '') {
            finalHashSeminarLeader = '&seminarleader=' + hashSeminarLeader;
        }
        else if (!firstCompleted && hashSeminarLeader != '') {
            firstCompleted = true;
        }
        //#endregion


        let finalHashAllSearchFilters =
            finalHashStartDate +
            finalHashEndDate +
            finalHashKeywords +
            finalHashLocations +
            finalHashSubjects +
            finalHashPassportCredits +
            finalHashDeliveryFormat +
            finalHashSeminarType +
            finalHashEthHours +
            finalHashCertifProgram +
            finalHashSeminarLeader;

        if (finalHashAllSearchFilters != "") {
            setHashAllSearchCriteria(finalHashAllSearchFilters);
        }

    }

    //#region onChange Select Time
    handleChangeTimeOne(date) {

        // check for null date
        if (date == null) {
            date = new Date();
        }

        // check for date before today
        if (date < new Date()) {
            date = new Date();
        }

        // check for date that isn't first of the month
        // really, just set day to '1'
        // but setDate returns seconds so we won't use that
        date = new Date(date.getFullYear(), date.getMonth(), 1);

        // lets set the end date as well
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

        this.props.setStartDate(date);
        this.props.setEndDate(endDate);

        //console.log("startdate: " + date);
        //console.log("enddate: " + endDate);

        this.setState({
            copyLinkVisible: false
        });

        //this.setHashAllSearchFilters();
    }

    handleChangeTimeTwo(date) {

        // just use the other function for now, cause we this will be redone for calendar.
        this.handleChangeTimeOne(date);

        //this.props.setEndDate(date);
        //this.setState({
        //    copyLinkVisible: false
        //});

    }

    handleBookmarkClick() {
        //console.log("bookmark click");
    }


    //#endregion

    //#region onClick Select Item
    onSelect = (parameter, propName, propId, arrayName) => () => {

        const { selectedLocations, setSelectedLocations, selectedSubjects, setSelectedSubjects, selectedSeminarLength, setSelectedSeminarLength, selectedDeliveryFormats, setSelectedDeliveryFormats,
            selectedCoursePrograms, setSelectedCoursePrograms, selectedEthicsHours, setSelectedEthicsHours, selectedCertificatePrograms, setSelectedCertificatePrograms, selectedPassportCredits, setSelectedPassportCredits } = this.props

        let selectedArray = [];
        let nameArray;

        switch (arrayName) {
            case "Location":
                selectedArray = selectedLocations;
                nameArray = setSelectedLocations;
                break;
            case "Subject":
                selectedArray = selectedSubjects;
                nameArray = setSelectedSubjects;
                break;
            case "SeminarLength":
                selectedArray = selectedSeminarLength;
                nameArray = setSelectedSeminarLength;
                break;
            case "DeliveryFormats":
                selectedArray = selectedDeliveryFormats;
                nameArray = setSelectedDeliveryFormats;
                break;
            case "CoursePrograms":
                selectedArray = selectedCoursePrograms;
                nameArray = setSelectedCoursePrograms;
                break;
            case "EthicsHours":
                selectedArray = selectedEthicsHours;
                nameArray = setSelectedEthicsHours;
                break;
            case "CertificatePrograms":
                selectedArray = selectedCertificatePrograms;
                nameArray = setSelectedCertificatePrograms;
                break;
            case "PassportCredits":
                selectedArray = selectedPassportCredits;
                nameArray = setSelectedPassportCredits;
                break;

            default:
                selectedArray = [];
        }

        let existParameter = true;
        for (var i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i][propName] == parameter[propName]) {
                selectedArray.splice(i, 1);
                existParameter = false;
            }
        }

        if (existParameter) {
            selectedArray.push({
                [propId]: parameter[propId],
                [propName]: parameter[propName]
            });

            for (var i = 0; i < selectedArray.length; i++) {
                if (selectedArray[i][propName] == 'Any or All') {
                    selectedArray.splice(i, 1);
                }
            }
        }
        nameArray(selectedArray);
        this.setState({
            copyLinkVisible: false
        });

    };

    //#endregion

    //#region onClick Select Seminar Leader
    onSelectSemLeader = (parameter) => () => {

        const { selectedLeaders, setSelectedLeaders } = this.props;

        let selectedArray = selectedLeaders;

        let existParameter = true;
        for (var i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i].LeaderId == parameter.LeaderId) {
                selectedArray.splice(i, 1);
                existParameter = false;
            }
        }

        if (existParameter) {
            selectedArray.push({
                LeaderId: parameter.LeaderId,
                Name: parameter.FirstName + ' ' + parameter.LastName
            });

            for (var i = 0; i < selectedArray.length; i++) {
                if (selectedArray[i].Name == 'Any or All') {
                    selectedArray.splice(i, 1);
                }
            }
        }
        setSelectedLeaders(selectedArray);
        this.setState({
            copyLinkVisible: false
        });
    };

    //#endregion

    //#region onClick Select AnyOrAll
    onSelectAny = (propName, propId, arrayName) => () => {

        const { selectedLocations, setSelectedLocations, selectedSubjects, setSelectedSubjects, selectedSeminarLength, setSelectedSeminarLength, selectedDeliveryFormats, setSelectedDeliveryFormats,
            selectedCoursePrograms, setSelectedCoursePrograms, selectedEthicsHours, setSelectedEthicsHours, selectedCertificatePrograms, setSelectedCertificatePrograms, selectedPassportCredits, setSelectedPassportCredits } = this.props

        let selectedArray = [];
        let nameArray;

        switch (arrayName) {
            case "Location":
                selectedArray = selectedLocations;
                nameArray = setSelectedLocations;
                break;
            case "Subject":
                selectedArray = selectedSubjects;
                nameArray = setSelectedSubjects;
                break;
            case "SeminarLength":
                selectedArray = selectedSeminarLength;
                nameArray = setSelectedSeminarLength;
                break;
            case "DeliveryFormats":
                selectedArray = selectedDeliveryFormats;
                nameArray = setSelectedDeliveryFormats;
                break;
            case "CoursePrograms":
                selectedArray = selectedCoursePrograms;
                nameArray = setSelectedCoursePrograms;
                break;
            case "EthicsHours":
                selectedArray = selectedEthicsHours;
                nameArray = setSelectedEthicsHours;
                break;
            case "CertificatePrograms":
                selectedArray = selectedCertificatePrograms;
                nameArray = setSelectedCertificatePrograms;
                break;
            case "PassportCredits":
                selectedArray = selectedPassportCredits;
                nameArray = setSelectedPassportCredits;
                break;
            default:
                selectedArray = [];
        }

        if (selectedArray.length === 1 && selectedArray[0][propName] === "Any or All") {
            let selectedArray = [];
            nameArray(selectedArray);
        }

        else {
            const anyOrAllArray = [];
            if (arrayName === "Location") {
                anyOrAllArray.push({
                    [propId]: [],
                    [propName]: "Any or All"
                });
            } else {
                anyOrAllArray.push({
                    [propId]: "",
                    [propName]: "Any or All"
                });
            }

            switch (arrayName) {
                case "CertificatePrograms":
                    anyOrAllArray[0][propId] = "allorany";
                    break;
                case "EthicsHours":
                    anyOrAllArray[0][propId] = "allorany";
                    break;
                default:
                    anyOrAllArray[0][propId] = "";
            }
            nameArray(anyOrAllArray);
        }
        this.setState({
            copyLinkVisible: false
        });
    };

    //#endregion

    //#region onClick Select AnyOrAll SemLeader
    onSelectAnySemLeader = () => () => {

        const { selectedLeaders, setSelectedLeaders } = this.props;

        if (selectedLeaders.length === 1 && selectedLeaders[0].Name === "Any or All") {
            const emptyArray = [];
            setSelectedLeaders(emptyArray);
        } else {
            const selectedArray = [];
            selectedArray.push({
                LeaderId: "",
                Name: "Any or All"

            });
            setSelectedLeaders(selectedArray);
        }
        this.setState({
            copyLinkVisible: false
        });
    };

    //#endregion

    //#region onClick Remove 
    onRemove = (parameter, propName, arrayName) => () => {

        const { selectedLocations, setSelectedLocations, selectedSubjects, setSelectedSubjects, selectedSeminarLength, setSelectedSeminarLength, selectedDeliveryFormats, setSelectedDeliveryFormats,
            selectedCoursePrograms, setSelectedCoursePrograms, selectedEthicsHours, setSelectedEthicsHours, selectedCertificatePrograms, setSelectedCertificatePrograms, selectedLeaders, setSelectedLeaders, selectedPassportCredits, setSelectedPassportCredits, redirectUrl } = this.props

        let selectedArray = [];
        let nameArray;

        switch (arrayName) {
            case "Location":
                selectedArray = selectedLocations;
                nameArray = setSelectedLocations;
                break;
            case "Subject":
                selectedArray = selectedSubjects;
                nameArray = setSelectedSubjects;
                break;
            case "SeminarLength":
                selectedArray = selectedSeminarLength;
                nameArray = setSelectedSeminarLength;
                break;
            case "DeliveryFormats":
                selectedArray = selectedDeliveryFormats;
                nameArray = setSelectedDeliveryFormats;
                break;
            case "CoursePrograms":
                selectedArray = selectedCoursePrograms;
                nameArray = setSelectedCoursePrograms;
                break;
            case "EthicsHours":
                selectedArray = selectedEthicsHours;
                nameArray = setSelectedEthicsHours;
                break;
            case "CertificatePrograms":
                selectedArray = selectedCertificatePrograms;
                nameArray = setSelectedCertificatePrograms;
                break;
            case "Leaders":
                selectedArray = selectedLeaders;
                nameArray = setSelectedLeaders;
                break;
            case "PassportCredits":
                selectedArray = selectedPassportCredits;
                nameArray = setSelectedPassportCredits;
                break;
            default:
                selectedArray = [];
        }

        for (var i = 0; i < selectedArray.length; i++) {
            if (selectedArray[i][propName] === parameter[propName]) {
                selectedArray.splice(i, 1);
            }
        }
        nameArray(selectedArray);
        if (redirectUrl === undefined) {
            this.searchButton();
        }


    };
    //#endregion

    //#region onClick RemoveDate 
    //onRemoveDate = (date) => () => {

        //const { startDate, setStartDate, setEndDate, allSearchCriteria, searchResult, selectedLocations, selectedSubjects, selectedSeminarLength,
        //    selectedDeliveryFormats, selectedCoursePrograms, selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, keywordsSearchValue,
        //    sortBy, endDate, setAllSearchCriteria, selectedPassportCredits, setHashAllSearchCriteria, redirectUrl } = this.props

        //switch (date) {
        //    case "StartDate":
        //        setStartDate(null);
        //        break;
        //    case "EndDate":
        //        setEndDate(null);
        //        break;
        //    default:
        //        setStartDate(null);
        //}

        //const listCpabcVenueId = filterUtils.getListLocationIds(selectedLocations, "LocationId");
        //const listCpabcCourseSubcategory = filterUtils.getListIds(selectedSubjects, "Code");
        //const listDurationHours = filterUtils.getSimpleListIds(selectedSeminarLength, "Value");
        //const listPassportCredits = filterUtils.getSimpleListIds(selectedPassportCredits, "Value");
        //const listDeliveryFormats = filterUtils.getListIds(selectedDeliveryFormats, "Code");
        //const listCoursePrograms = filterUtils.getListIds(selectedCoursePrograms, "Code");
        //const listEthicsHours = filterUtils.getSimpleListIds(selectedEthicsHours, "Value");
        //const ethicsHoursGreaterThan0 = (listEthicsHours[0] == "allorany") ? true : null;
        //const listCertificatePrograms = filterUtils.getListIds(selectedCertificatePrograms, "Code");
        //const listLeaders = filterUtils.getListIds(selectedLeaders, "LeaderId");
        //const sortByValue = 1; //test (sortBy === "Alpha") ? 3 : 1;

        //let firstDate = date === "StartDate" ? null : startDate;
        //let secondDate = date === "EndDate" ? null : endDate;

        //let startDateIso = firstDate ? moment(startDate).format() : null;
        //let endDateIso = secondDate ? moment(endDate).format() : null;

        // okay the calendar Needs a start and end date.
        // make sure we have start and end dates
        // check for null date
        //var startDate2 = new Date();

        // check for date that isn't first of the month
        // really, just set day to '1'
        // but setDate returns seconds so we won't use that
        //startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

        // lets set the end date as well
        //var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

        //in case it was changed above
        //this.props.setStartDate(startDate2);
        //this.props.setEndDate(endDate2);

        //console.log("search button start date2: " + startDate2);
        //console.log("search button end date2: " + endDate2);

        //let startDateIso = startDate2 ? moment(startDate2).format() : null;
        //let endDateIso = endDate2 ? moment(endDate2).format() : null;



        //const searchRequest = {
            //StartDate: startDateIso,
            //EndDate: endDateIso,
            //PageIndex: 0,
            //PageSize: 500, //test searchResult.pageSize,
            //CpabcVenueId: listCpabcVenueId,
            //CpabcCourseSubcategory: listCpabcCourseSubcategory,
            //DurationHours: listDurationHours,
            //CpabcCourseDeliveryMethod: listDeliveryFormats, //test this.getSelectedDeliveryFormatsCalendar(), //test listDeliveryFormats,
            //CpabcCourseProgram: listCoursePrograms,
            //EthicsHours: listEthicsHours,
            //EthicsHoursGreaterThan0: ethicsHoursGreaterThan0,
            //CpabcCertificateProgram: listCertificatePrograms,
            //CpabcSeminarLeaderId: listLeaders,
            //PassportCredits: listPassportCredits,
            //Keywords: keywordsSearchValue,
            //Sort: sortByValue,
        //}

        //let sessionStorageSearchRequest = JSON.stringify(searchRequest);
        //sessionStorage.setItem("ngStorage-searchCriteriaCalendar", sessionStorageSearchRequest);
        //setAllSearchCriteria(searchRequest);

        //if (redirectUrl === undefined) {
            //this.props.setLoadingFalse(true);
            //this.props.selectCourse(null);
            //this.props.resetSearchResultItems();
            //this.props.setVisibleListBookmarks(false);
            //this.props.setCourseFromHashVisible(false);

            //setTimeout(() => {
            //    this.props.searchPd(searchRequest);
            //},
            //    100);

            //this.setSearchCriteria();

            //if (listCpabcVenueId.length === 0 &&
                //listDurationHours.length === 0 &&
                //listCpabcCourseSubcategory.length === 0 &&
                //listDeliveryFormats.length === 0 &&
                //listCoursePrograms.length === 0 &&
                //listEthicsHours.length === 0 &&
                //listCertificatePrograms.length === 0 &&
                //listLeaders.length === 0 &&
                //listPassportCredits.length === 0 &&
                //keywordsSearchValue === '' &&
                //startDateIso === null &&
                //endDateIso === null) {
                //this.setState({
                //    copyLinkVisible: false
                //});
            //}
            //else {
            //    this.setState({
            //        copyLinkVisible: true
            //    });
            //}

            //let firstCompleted = false;
            //let hashStartDate = firstDate ? moment(firstDate).format("YYYY-MM-D") : '';
            //let finalHashStartDate = hashStartDate == '' ? '' : 'from=' + hashStartDate;
            //if (hashStartDate != '') {
            //    firstCompleted = true;
            //}

            //let hashEndDate = secondDate ? moment(secondDate).format("YYYY-MM-D") : '';
            //let finalHashEndDate = hashEndDate == '' ? '' : 'to=' + hashEndDate;
            //if (firstCompleted && hashEndDate != '') {
            //    finalHashEndDate = '&to=' + hashEndDate;
            //}
            //else if (!firstCompleted && hashEndDate != '') {
            //    firstCompleted = true;
            //}

            //#region HashKeywords
            //let hashKeywords = keywordsSearchValue != '' ? keywordsSearchValue : '';
            //hashKeywords = hashKeywords.split("&").join("%26");
            //let finalHashKeywords = hashKeywords == '' ? '' : 'keywords=' + hashKeywords;
            //if (firstCompleted && hashKeywords != '') {
            //    finalHashKeywords = '&keywords=' + hashKeywords;
            //}
            //else if (!firstCompleted && hashKeywords != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashLocations
            //const listLocations = filterUtils.getListIds(selectedLocations, "City");
            //let listHashLocations = '';
            //if (listLocations.length > 0) {
            //    listHashLocations = filterUtils.getHashList(listLocations);
            //}
            //if (listHashLocations === 'Any or All') {
            //    listHashLocations = '';
            //}
            //let hashLocations = listHashLocations != '' ? listHashLocations : '';
            //let finalHashLocations = hashLocations == '' ? '' : 'location=' + hashLocations;
            //if (firstCompleted && hashLocations != '') {
            //    finalHashLocations = '&location=' + hashLocations;
            //}
            //else if (!firstCompleted && hashLocations != '') {
            //    firstCompleted = true;
            //}

            //#endregion

            //#region ListHashSubjects
            //const listSubjects = filterUtils.getListIds(selectedSubjects, "Description");
            //let listHashSubjects = '';
            //if (listSubjects.length > 0) {
            //    listHashSubjects = filterUtils.getHashList(listSubjects);
            //}
            //if (listHashSubjects === 'Any or All') {
            //    listHashSubjects = '';
            //}

            //listHashSubjects = listHashSubjects.split("&").join("%26");

            //let hashSubjects = listHashSubjects != '' ? listHashSubjects : '';
            //let finalHashSubjects = hashSubjects == '' ? '' : 'subject=' + hashSubjects;
            //if (firstCompleted && hashSubjects != '') {

            //    finalHashSubjects = '&subject=' + hashSubjects;
            //}
            //else if (!firstCompleted && hashSubjects != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashPassportCredits
            //const listPassCredits = filterUtils.getListIds(selectedPassportCredits, "Text");
            //let listHashPassportCredits = '';
            //if (listPassCredits.length > 0) {
            //    listHashPassportCredits = filterUtils.getHashList(listPassCredits);
            //}
            //if (listHashPassportCredits === 'Any or All') {
            //    listHashPassportCredits = '';
            //}
            //let hashPassportCredits = listHashPassportCredits != '' ? listHashPassportCredits : '';
            //let finalHashPassportCredits = hashPassportCredits == '' ? '' : 'passportcredits=' + hashPassportCredits;
            //if (firstCompleted && hashPassportCredits != '') {
            //    finalHashPassportCredits = '&passportcredits=' + hashPassportCredits;
            //}
            //else if (!firstCompleted && hashPassportCredits != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashDeliveryFormat
            //const listDelivFormats = filterUtils.getListIds(selectedDeliveryFormats, "Description");
            //let listHashDeliveryFormat = '';
            //if (listDelivFormats.length > 0) {
            //    listHashDeliveryFormat = filterUtils.getHashList(listDelivFormats);
            //}
            //if (listHashDeliveryFormat === 'Any or All') {
            //    listHashDeliveryFormat = '';
            //}
            //listHashDeliveryFormat = listHashDeliveryFormat.split("&").join("%26");

            //let hashDeliveryFormat = listHashDeliveryFormat != '' ? listHashDeliveryFormat : '';
            //let finalHashDeliveryFormat = hashDeliveryFormat == '' ? '' : 'deliveryformat=' + hashDeliveryFormat;
            //if (firstCompleted && hashDeliveryFormat != '') {
            //    finalHashDeliveryFormat = '&deliveryformat=' + hashDeliveryFormat;
            //}
            //else if (!firstCompleted && hashDeliveryFormat != '') {
            //    firstCompleted = true;
           // }
            //#endregion

            //#region ListHashSeminarType
            //const listSeminarType = filterUtils.getListIds(selectedCoursePrograms, "Description");
            //let listHashSeminarType = '';
            //if (listSeminarType.length > 0) {
            //    listHashSeminarType = filterUtils.getHashList(listSeminarType);
            //}
            //if (listHashSeminarType === 'Any or All') {
            //    listHashSeminarType = '';
            //}

            //listHashSeminarType = listHashSeminarType.split("&").join("%26");

            //let hashSeminarType = listHashSeminarType != '' ? listHashSeminarType : '';
            //let finalHashSeminarType = hashSeminarType == '' ? '' : 'seminartype=' + hashSeminarType;
            //if (firstCompleted && hashSeminarType != '') {
            //    finalHashSeminarType = '&seminartype=' + hashSeminarType;
            //}
            //else if (!firstCompleted && hashSeminarType != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashEthicsHours
            //const listEthHours = filterUtils.getListIds(selectedEthicsHours, "Text");
            //let listHashEthHours = '';
            //if (listEthHours.length > 0) {
            //    listHashEthHours = filterUtils.getHashList(listEthHours);
            //}
            //let hashEthHours = listHashEthHours != '' ? listHashEthHours : '';
            //let finalHashEthHours = hashEthHours == '' ? '' : 'ethichours=' + hashEthHours;
            //if (firstCompleted && hashEthHours != '') {
            //    finalHashEthHours = '&ethichours=' + hashEthHours;
            //}
            //else if (!firstCompleted && hashEthHours != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashCertificateProgram
            //const listCertifProgram = filterUtils.getListIds(selectedCertificatePrograms, "Description");
            //let listHashCertifProgram = '';
            //if (listCertifProgram.length > 0) {
            //    listHashCertifProgram = filterUtils.getHashList(listCertifProgram);
            //}

            //listHashCertifProgram = listHashCertifProgram.split("&").join("%26");

            //let hashCertifProgram = listHashCertifProgram != '' ? listHashCertifProgram : '';
            //let finalHashCertifProgram = hashCertifProgram == '' ? '' : 'certificateprogram=' + hashCertifProgram;
            //if (firstCompleted && hashCertifProgram != '') {
            //    finalHashCertifProgram = '&certificateprogram=' + hashCertifProgram;
            //}
            //else if (!firstCompleted && hashCertifProgram != '') {
            //    firstCompleted = true;
            //}
            //#endregion

            //#region ListHashSeminarLeader
            //const listSeminarLeader = filterUtils.getListIds(selectedLeaders, "Name");
            //let listHashSeminarLeader = '';
            //if (listSeminarLeader.length > 0) {
            //    listHashSeminarLeader = filterUtils.getHashList(listSeminarLeader);
            //}
            //if (listHashSeminarLeader === 'Any or All') {
            //    listHashSeminarLeader = '';
            //}

            //let hashSeminarLeader = listHashSeminarLeader != '' ? listHashSeminarLeader : '';
            //let finalHashSeminarLeader = hashSeminarLeader == '' ? '' : 'seminarleader=' + hashSeminarLeader;
            //if (firstCompleted && hashSeminarLeader != '') {
            //    finalHashSeminarLeader = '&seminarleader=' + hashSeminarLeader;
            //}
            //else if (!firstCompleted && hashSeminarLeader != '') {
            //    firstCompleted = true;
            //}
            //#endregion


            //let finalHashAllSearchFilters =
            //    finalHashStartDate +
            //    finalHashEndDate +
            //    finalHashKeywords +
            //    finalHashLocations +
            //    finalHashSubjects +
            //    finalHashPassportCredits +
            //    finalHashDeliveryFormat +
            //    finalHashSeminarType +
            //    finalHashEthHours +
            //    finalHashCertifProgram +
            //    finalHashSeminarLeader;

            //if (finalHashAllSearchFilters != "") {
            //    setHashAllSearchCriteria(finalHashAllSearchFilters);
            //}

        //}
    //};
    //#endregion


    resetFilters() {
        const { sortBy, redirectUrl, setNewCoursesOnlyVisible, setCopySearchTagVisible } = this.props;

        setNewCoursesOnlyVisible(false);

        this.props.selectCourse(null);	
        if (redirectUrl === undefined) {	
            this.props.setPdDetails(null);	
            this.props.setIsLoadingPdDetails(true);	
        }

        this.props.resetFiltersSearch();
        const sortByValue = 1; //test (sortBy === "Alpha") ? 3 : 1;

        // make sure we have start and end dates
        // check for null date
        var startDate2 = this.props.startDate;
        if ((startDate2 == null) || (typeof startDate2 == 'undefined')) {
            startDate2 = new Date();
        }

        // check for date before today
        if (startDate2 < new Date()) {
            startDate2 = new Date();
        }

        // check for date that isn't first of the month
        // really, just set day to '1'
        // but setDate returns seconds so we won't use that
        startDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth(), 1);

        // lets set the end date as well
        var endDate2 = new Date(startDate2.getFullYear(), startDate2.getMonth() + 1, 0);  // the "zero" gives the last day of the previous month

        //in case it was changed above
        this.props.setStartDate(startDate2);
        this.props.setEndDate(endDate2);

        //console.log("search button start date2: " + startDate2);
        //console.log("search button end date2: " + endDate2);

        let startDateIso = startDate2 ? moment(startDate2).format() : null;
        let endDateIso = endDate2 ? moment(endDate2).format() : null;

        const searchRequest = {
            StartDate: startDateIso,
            EndDate: endDateIso,
            PageIndex: 0,
            PageSize: 500, //test 9,
            CpabcVenueId: [],
            CpabcCourseSubcategory: [],
            DurationHours: [],
            CpabcCourseDeliveryMethod: [],
            CpabcCourseProgram: [],
            EthicsHours: [],
            EthicsHoursGreaterThan0: null,
            CpabcCertificateProgram: [],
            CpabcSeminarLeaderId: [],
            PassportCredits: [],
            Keywords: "",
            Sort: sortByValue
        };

        this.props.setAllSearchCriteria(searchRequest);

        const sessionStorageSearchRequest = JSON.stringify(searchRequest);
        sessionStorage.setItem("ngStorage-searchCriteriaCalendar", sessionStorageSearchRequest);

        let device = getDeviceTypeInfo();	
        let deviceType = "Device";	
        if (device) {	
            deviceType = device.deviceType;	
        };

        this.props.setLoadingFalse(true);
        //this.props.selectCourse(null);
        this.props.resetSearchResultItems();
        this.props.setVisibleListBookmarks(false);
        this.props.setCourseFromHashVisible(false);
        this.props.setHashAllSearchCriteria('');
        this.props.setCopySearchTagVisible(false);

        this.setState({
            copyLinkVisible: false,
            newCoursesOnlyChecked: false,
            selectedFiltersMobile: 0
        });

        if (redirectUrl === undefined && deviceType != "Mobile") {	
           this.props.searchPd(searchRequest);	
        }

        this.handleChangeTimeOne(this.props.startDate);
    }

    copyLink() {
        const { hashAllSearchCriteria, startDate, endDate } = this.props;

        let hashPage = window.location.hash;
        let hrefPage = window.location.href;
        hrefPage = hrefPage.split(hashPage).join("");
        hrefPage = hrefPage.split("#").join("");
        let searchPage;
        if (hashAllSearchCriteria === "") {
            searchPage = hrefPage;
        }
        else {
            searchPage = hrefPage + "#" + hashAllSearchCriteria;
        }

        searchPage = encodeURI(searchPage);

        const el = document.createElement("textarea");
        el.value = searchPage;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        toast.info('Copy link in the clipboard!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    copyTag() {	
        const { searchTag } = this.props;	
        let hashPage = window.location.hash;	
        let hrefPage = window.location.href;	
        hrefPage = hrefPage.split(hashPage).join("");	
        hrefPage = hrefPage.split("#").join("");	
        let searchPage;	
        if (searchTag === "") {	
            searchPage = hrefPage;	
        }	
        else {	
            searchPage = hrefPage + "#tag=" + searchTag.toLowerCase();	
        }	
        const el = document.createElement("textarea");	
        el.value = searchPage;	
        document.body.appendChild(el);	
        el.select();	
        document.execCommand("copy");	
        document.body.removeChild(el);	
        toast.info('Copy tag in the clipboard!', {	
            position: "top-right",	
            autoClose: 2000,	
            hideProgressBar: true,	
            closeOnClick: true,	
            pauseOnHover: true,	
            draggable: true,	
            progress: undefined	
        });	
    }

    handleChangeKeywords = (event) => {
        this.props.setKeywordsSearchValue(event.target.value);
        this.setState({
            copyLinkVisible: false
        });
    }


    getDateFormat = (firstDate, secondDate) => {
        let currentDate = new Date();
        let currentDateYear = moment(currentDate).format("YYYY");
        let currentDateMonth = moment(currentDate).format("MMM");
        let firstDateYear = moment(firstDate).format("YYYY");
        let firstDateMonth = moment(firstDate).format("MMM");
        let firstDateFormatFull = moment(firstDate).format("MMM DD, YYYY");
        let firstDateFormatM = moment(firstDate).format("MMM DD");
        let firstDateFormatD = moment(firstDate).format("DD");
        let secondDateYear = moment(secondDate).format("YYYY");
        let secondDateMonth = moment(secondDate).format("MMM");
        let secondDateFormatFull = moment(secondDate).format("MMM DD, YYYY");
        let secondDateFormatM = moment(secondDate).format("MMM DD");
        let secondDateFormatD = moment(secondDate).format("DD");
        if (firstDate === null && secondDate === null) {
            return "";
        }
        else if (firstDate != null && secondDate === null && currentDateYear != firstDateYear) {
            return "From " + firstDateFormatFull;
        }
        else if (firstDate != null && secondDate === null && currentDateYear === firstDateYear) {
            return "From " + firstDateFormatM;
        }
        else if (firstDate === null && secondDate != null && currentDateYear != secondDateYear) {
            return "To " + secondDateFormatFull;
        }
        else if (firstDate === null && secondDate != null && currentDateYear === secondDateYear) {
            return "To " + secondDateFormatM;
        }
        else if ((currentDateYear === firstDateYear) && (currentDateYear === secondDateYear) && (firstDateMonth != secondDateMonth)) {
            return firstDateFormatM + " - " + secondDateFormatM;
        }
        else if ((firstDateYear === secondDateYear) && (firstDateMonth === secondDateMonth) && (firstDateYear != currentDateYear)) {
            return firstDateFormatM + " - " + secondDateFormatD + ", " + firstDateYear;
        }
        else if ((firstDateYear === secondDateYear) && (firstDateMonth === secondDateMonth) && (firstDateYear === currentDateYear)) {
            return firstDateFormatM + " - " + secondDateFormatD;
        }
        else if ((currentDateYear != firstDateYear) || (currentDateYear != secondDateYear) || (firstDateYear != secondDateYear)) {
            return firstDateFormatFull + " - " + secondDateFormatFull;
        }
        //else if (currentDateYear != firstDateYear || (secondDate != null && firstDateYear != secondDateYear)) {	
        //    return firstDateFormatFull;	
        //}	
    }



    render() {
        const { courseSubjects, seminarLength, deliveryFormats, coursePrograms, ethicsHours, certificatePrograms, leaders, selectedLocations, selectedSubjects, selectedSeminarLength,
            selectedDeliveryFormats, selectedCoursePrograms, selectedEthicsHours, selectedCertificatePrograms, selectedLeaders, keywordsSearchValue, selectedBookmark, setKeywordsSearchValue,
            selectCourse, setVisibleListBookmarks, passportCredits, selectedPassportCredits, setSelectedPassportCredits, setCourseFromHashVisible, hashAllSearchCriteria, searchResult, startDate,
            endDate, onHomePage, allSearchCriteria, getPdDetails, setIsLoadingPdDetails, isLoadingPdDetails, copySearchTagVisible, searchTag, urlSearchPd } = this.props;

        const { locations } = this.state;
        //console.log("new courses top render: " + this.props.newCoursesOnlyVisible + "|" + this.props.searchResult.hasResultNewCourses);
        //console.log("search fiilter render");
        return <div>

            <Responsive displayIn={["Mobile"]}>
                <div className="search-filters-mobile">
                    <div className="filters-head d-md-flex flex-grow-1">
                        {false && <div className="mobile-summary-search" onClick={this.onClickMobileSummary()}>
                            <i className="fas fa-search"></i>
                            {startDate === null && endDate === null && keywordsSearchValue === '' &&	
                                <span className="placeholder-mobile">Keyword / Search Dates</span>	
                            }	
                            {(startDate != null || endDate != null || keywordsSearchValue != '') &&	
                                <React.Fragment>	
                                    <span className="start-date">{this.getDateFormat(startDate, endDate)}</span>	
                                    {keywordsSearchValue &&	
                                        <span className="keywords-search-value">	
                                            {keywordsSearchValue}</span>	
                                    }	
                                    {(startDate !== null ||	
                                        endDate !== null ||	
                                        keywordsSearchValue !== '') &&	
                                        <div className="align-self-center">	
                                            <a className="see-all" href="javascript:void(0);" onClick={this.resetFiltersMobile.bind(this)}>Clear Filters</a>	
                                        </div>	
                                    }	
                                </React.Fragment>	
                            }
                        </div>}
                        <div className={"basic-filters show"}>
                            <div className="filter-cell mr-md-3 mb-3" style={{ display: "none", }}>
                                <div className="react-datepicker-wrapper" style={{ display: "none", }}>
                                    <div className="from-date" style={{ display: "none", }}>
                                        <DatePicker selected={startDate} onChange={this.handleChangeTimeOne.bind(this)} placeholderText="From Date" />
                                    </div>
                                    <div className="to-date" style={{ display: "none", }}>
                                        <DatePicker selected={this.props.endDate} onChange={this.handleChangeTimeTwo.bind(this)} placeholderText="To Date" />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-cell pr-md-4 mb-3 mr-auto">
                                <KeywordsSearch
                                    keywordsSearchValue={keywordsSearchValue}
                                    setKeywordsSearchValue={setKeywordsSearchValue}
                                    handleChangeKeywords={this.handleChangeKeywords}
                                />
                            </div>
                            {false && <div className="button-close">
                                <button className="btn btn-primary btn-close text-center" onClick={this.onClickButtonMobileSummary()}>Close</button>
                            </div>}
                        </div>
                    </div>
                    <div className="buttons-mobile">	
                        <div className="button-see-filters">	
                            <button className="btn btn-secondary dropdown-toggle w-100 see-filters" type="button" data-toggle=" " aria-expanded=" " onClick={this.onClickSeeFiltersMobile()}>	
                                More Filters {this.state.selectedFiltersMobile === 0 ? "" : "(" + this.state.selectedFiltersMobile + ")"}	
                            </button>	
                        </div>	
                        <div className={"additional-filters" + (this.state.expandedFiltersMobile ? "" : " d-none")}>	
                            <div className="title-filters">	
                                <button className="btn btn-primary btn-close text-center" onClick={this.onClickCloseFiltersMobile()}></button>	
                                <span className="title-filters-mobile">Filters</span>	
                            </div>	
                            <div className="filter-container-mobile">	
                                <div className="d-flex flex-row flex-wrap mr-auto">	
                                    <div className="filter">	
                                        <LocationDropdown	
                                            selectedLocations={selectedLocations}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            locations={locations}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <SubjectDropdown	
                                            selectedSubjects={selectedSubjects}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            courseSubjects={courseSubjects}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <PassportCreditsDropdown	
                                            selectedPassportCredits={selectedPassportCredits}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            passportCredits={passportCredits}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <DeliveryFormatsDropdown	
                                            selectedDeliveryFormats={selectedDeliveryFormats}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            deliveryFormats={deliveryFormats}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <CourseProgramsDropdown	
                                            selectedCoursePrograms={selectedCoursePrograms}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            coursePrograms={coursePrograms}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <EthicsHoursDropdown	
                                            selectedEthicsHours={selectedEthicsHours}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            ethicsHours={ethicsHours}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <CertificateProgramsDropdown	
                                            selectedCertificatePrograms={selectedCertificatePrograms}	
                                            onSelectAnyOrAll={this.onSelectAny}	
                                            onSelectItem={this.onSelect}	
                                            getActiveClassName={filterUtils.isItemSelected}	
                                            certificatePrograms={certificatePrograms}	
                                        />	
                                    </div>	
                                    <div className="filter">	
                                        <SeminarLeaderDropdown	
                                            selectedLeaders={selectedLeaders}	
                                            leaders={leaders}	
                                            isItemSelected={filterUtils.isItemSelected}	
                                            onSelectAnySemLeader={this.onSelectAnySemLeader}	
                                            onSelectSemLeader={this.onSelectSemLeader}	
                                            setSelectedLeaders={this.props.setSelectedLeaders}	
                                        />	
                                    </div>	
                                </div>	
                                <div className="selected-filters-mobile">	
                                    <SelectedLocations selectedLocations={selectedLocations} onRemoveLocation={this.onRemove} />	
                                    <SelectedSubjects selectedSubjects={selectedSubjects} onRemoveSubject={this.onRemove} />	
                                    <SelectedPassportCredits selectedPassportCredits={selectedPassportCredits} onRemovePassCred={this.onRemove} />	
                                    <SelectedDeliveryFormats selectedDeliveryFormats={selectedDeliveryFormats} onRemoveDelFor={this.onRemove} />	
                                    <SelectedCoursePrograms selectedCoursePrograms={selectedCoursePrograms} onRemoveCourseProg={this.onRemove} />	
                                    <SelectedEthicsHours selectedEthicsHours={selectedEthicsHours} onRemoveEthHour={this.onRemove} />	
                                    <SelectedCertificatePrograms selectedCertificatePrograms={selectedCertificatePrograms} onRemoveCertProg={this.onRemove} />	
                                    <SelectedLeaders selectedLeaders={selectedLeaders} onRemoveLeader={this.onRemove} />	
                                    {(selectedLocations.length > 0 ||	
                                        selectedSubjects.length > 0 ||	
                                        selectedPassportCredits.length > 0 ||	
                                        selectedDeliveryFormats.length > 0 ||	
                                        selectedCoursePrograms.length > 0 ||	
                                        selectedEthicsHours.length > 0 ||	
                                        selectedCertificatePrograms.length > 0 ||	
                                        selectedLeaders.length > 0) &&	
                                        <div className="align-self-center">	
                                            <a className="see-all" href="javascript:void(0);" onClick={this.resetFilters.bind(this)}>Clear Filters</a>	
                                        </div>	
                                    }	
                                </div>	
                            </div>	
                            <div className="button-search">	
                                <button className="btn btn-primary btn-search text-center" onClick={this.onClickCloseFiltersMobileSearch()}>Search</button>	
                            </div>	
                        </div>	
                    
                    <div className="button-search">
                        <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, 0)}>Search</button>
                    </div>	
                    
                    </div>	
                    <div className="button-search">
                        <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, -1)} style={{ marginBottom: "20px", }}>Previous Month</button>
                    </div>
                    <div className="button-search">
                        <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, +1)} style={{ marginBottom: "20px", }}>Next Month</button>
                    </div>
                </div>	
                	
            </Responsive>
            <Responsive displayIn={["Tablet", "Laptop"]}>
                <div className="search-filters d-none d-md-block">
                    <div className="d-md-flex align-items-center flex-row flex-wrap">
                        <div className="filter-cell mr-4 mb-3">
                            <div className="heading">Search PD Offerings</div>
                        </div>
                        <div className="d-md-flex flex-grow-1">
                            <div className="filter-cell mr-md-3 mb-3" style={{ display: "none", }}>
                                <DatePicker selected={startDate} onChange={this.handleChangeTimeOne.bind(this)} placeholderText="From Date" />
                            </div>
                            <div className="filter-cell mr-md-3 mb-3" style={{ display: "none", }}>
                                <DatePicker selected={this.props.endDate} onChange={this.handleChangeTimeTwo.bind(this)} placeholderText="To Date" />
                            </div>
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, -1)} style={{ marginTop: "0px", }}>Previous Month</button>
                            </div>
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, +1)} style={{ marginTop: "0px", }}>Next Month</button>
                            </div>
                            <div className="filter-cell pr-md-4 mb-3 mr-auto">
                                <div className="d-flex">
                                    <KeywordsSearch
                                        keywordsSearchValue={keywordsSearchValue}
                                        setKeywordsSearchValue={setKeywordsSearchValue}
                                        handleChangeKeywords={this.handleChangeKeywords}
                                        searchButton={this.searchButton.bind(this, 0)}
                                    />
                                    {this.props.newCoursesOnlyVisible && this.props.searchResult.hasResultNewCourses &&
                                        <div className="new-courses custom-control custom-switch d-flex">
                                            <input id="customSwitches" style={{ "left": "0" }} type="checkbox" checked={this.state.newCoursesOnlyChecked} className="custom-control-input" onChange={this.newCoursesOnly} />
                                            <label for="customSwitches" className="custom-control-label">View New Courses Only</label>
                                            </div>	
                                    }	
                                    {(selectedLocations.length > 0 ||	
                                        selectedSubjects.length > 0 ||	
                                        selectedPassportCredits.length > 0 ||	
                                        selectedDeliveryFormats.length > 0 ||	
                                        selectedCoursePrograms.length > 0 ||	
                                        selectedEthicsHours.length > 0 ||	
                                        selectedCertificatePrograms.length > 0 ||	
                                        selectedLeaders.length > 0 ||	
                                        startDate !== null ||	
                                        endDate !== null ||	
                                        keywordsSearchValue !== '') &&	
                                        <div className="align-self-center">	
                                            <a className="see-all" href="javascript:void(0);" onClick={this.resetFilters.bind(this)}>Clear Filters</a>

                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="filter-cell mb-3">
                                <BookmarkDropdown
                                    selectedBookmark={selectedBookmark}
                                    selectCourse={selectCourse}
                                    setVisibleListBookmarks={setVisibleListBookmarks}
                                    setCourseFromHashVisible={setCourseFromHashVisible}
                                    onHomePage={onHomePage}
                                    getPdDetails={getPdDetails}
                                    setIsLoadingPdDetails={setIsLoadingPdDetails}
                                    urlSearchPd={urlSearchPd}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-container d-none d-sm-flex flex-row flex-wrap">
                        <div className="d-flex flex-row flex-wrap mr-auto">
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <LocationDropdown
                                    selectedLocations={selectedLocations}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    locations={locations}
                                />
                            </div>
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <SubjectDropdown
                                    selectedSubjects={selectedSubjects}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    courseSubjects={courseSubjects}
                                />
                            </div>
                            <div className="filter  mr-0 mr-sm-2 mb-2">
                                <PassportCreditsDropdown
                                    selectedPassportCredits={selectedPassportCredits}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    passportCredits={passportCredits}
                                />
                            </div>
                            <div className="filter mb-2  mr-0 mr-sm-2">
                                <DeliveryFormatsDropdown
                                    selectedDeliveryFormats={selectedDeliveryFormats}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    deliveryFormats={this.deliveryFormatsCalendar(deliveryFormats)}
                                />
                            </div>
                            <div className="filter  mb-2 mr-0 mr-sm-2">
                                <CourseProgramsDropdown
                                    selectedCoursePrograms={selectedCoursePrograms}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    coursePrograms={coursePrograms}
                                />
                            </div>
                            <div className="filter mb-2  mr-0 mr-sm-2">
                                <EthicsHoursDropdown
                                    selectedEthicsHours={selectedEthicsHours}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    ethicsHours={ethicsHours}
                                />
                            </div>
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <CertificateProgramsDropdown
                                    selectedCertificatePrograms={selectedCertificatePrograms}
                                    onSelectAnyOrAll={this.onSelectAny}
                                    onSelectItem={this.onSelect}
                                    getActiveClassName={filterUtils.isItemSelected}
                                    certificatePrograms={certificatePrograms}
                                />
                            </div>
                            <div className="filter mb-2 mr-0 mr-sm-2">
                                <SeminarLeaderDropdown
                                    selectedLeaders={selectedLeaders}
                                    leaders={leaders}
                                    isItemSelected={filterUtils.isItemSelected}
                                    onSelectAnySemLeader={this.onSelectAnySemLeader}
                                    onSelectSemLeader={this.onSelectSemLeader}
                                    setSelectedLeaders={this.props.setSelectedLeaders}
                                />
                            </div>
                        </div>
                        <div className="filter search mb-2 ">
                            <button className="btn btn-primary btn-search text-center" onClick={this.searchButton.bind(this, 0)}>Search</button>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap mt-4 align-items-start">
                        <div className="selected-filters">
                            <SelectedLocations selectedLocations={selectedLocations} onRemoveLocation={this.onRemove} />
                            <SelectedSubjects selectedSubjects={selectedSubjects} onRemoveSubject={this.onRemove} />
                            <SelectedPassportCredits selectedPassportCredits={selectedPassportCredits} onRemovePassCred={this.onRemove} />
                            <SelectedDeliveryFormats selectedDeliveryFormats={selectedDeliveryFormats} onRemoveDelFor={this.onRemove} />
                            <SelectedCoursePrograms selectedCoursePrograms={selectedCoursePrograms} onRemoveCourseProg={this.onRemove} />
                            <SelectedEthicsHours selectedEthicsHours={selectedEthicsHours} onRemoveEthHour={this.onRemove} />
                            <SelectedCertificatePrograms selectedCertificatePrograms={selectedCertificatePrograms} onRemoveCertProg={this.onRemove} />
                            <SelectedLeaders selectedLeaders={selectedLeaders} onRemoveLeader={this.onRemove} />
                            
                        </div>
                        <div className="copy-search-link">
                            {this.state.copyLinkVisible && searchResult.totalCount > 0 && !searchResult.isLoading && !copySearchTagVisible &&
                                <button className="btn btn-primary btn-copy-link text-center" onClick={this.copyLink.bind(this)}><i class="far fa-clone"></i> Copy Search Link</button>
                            }	
                            {searchResult.totalCount > 0 && !searchResult.isLoading && copySearchTagVisible &&	
                                <button className="btn btn-primary btn-copy-link text-center" onClick={this.copyTag.bind(this)}><i class="far fa-clone"></i> Copy Search Tag</button>
                            }
                        </div>
                    </div>
                </div>
            </Responsive>

        </div>;

    }
}

SearchFiltersCalendar.propTypes = {
    searchResult: PropTypes.object.isRequired,
    hasResultNewCourses: PropTypes.bool.isRequired,
    searchPd: PropTypes.func.isRequired,
    setLoadingFalse: PropTypes.func.isRequired,
    resetSearchResultItems: PropTypes.func.isRequired,
    resetFiltersSearch: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired,
    courseSubjects: PropTypes.array.isRequired,
    City: PropTypes.string.isRequired,
    LocationId: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    Code: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired,
    Value: PropTypes.number.isRequired,
    LeaderId: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    Children: PropTypes.array.isRequired,
    setAllSearchCriteria: PropTypes.func.isRequired,
    setSortByValue: PropTypes.func.isRequired,
    setSearchNewCourses: PropTypes.func.isRequired,
    setPdDetails: PropTypes.func.isRequired,	
    setIsLoadingPdDetails: PropTypes.func.isRequired,
};

export default SearchFiltersCalendar;



//<div className="filter  mr-0 mr-sm-2 mb-2">
//    <SeminarLengthDropdown
//        selectedSeminarLength={selectedSeminarLength}
//        onSelectAnyOrAll={this.onSelectAny}
//        onSelectItem={this.onSelect}
//        getActiveClassName={filterUtils.isItemSelected}
//        seminarLength={seminarLength}
//    />
//</div> 

//<SelectedSeminarLength selectedSeminarLength={selectedSeminarLength} onRemoveSemLen={this.onRemove} />
//<SelectedStartDate startDate={startDate} onRemoveStartDate={this.onRemoveDate} />
//<SelectedEndDate endDate={endDate} onRemoveEndDate={this.onRemoveDate} />