import React, { memo } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as searchPdActions from "../../../redux/actions/searchPdActions";
import PropTypes from "prop-types";
import SearchFilters from '../common/SearchFilters';

class SearchPdHomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onSearchPdHomePage: true,
        };
    }

    componentDidMount() {
        const { actions, searchResult } = this.props;
    }

    render() {
        const {
            searchResult,
            locations,
            courseSubjects,
            seminarLength,
            deliveryFormats,
            coursePrograms,
            ethicsHours,
            certificatePrograms,
            leaders,
            availableButtonLabel,
            availableButtonLink,
            cancelledButtonLabel,
            cancelledButtonLink,
            closedButtonLabel,
            closedButtonLink,
            fullButtonLabel,
            fullButtonLink,
            pastButtonLabel,
            pastButtonLink,
            seminarLevels,
            passportCredits,
            loadingMore,
            ideasTitle,
            ideasAbstract,
            ideasIcon,
            redirectUrl,
            urlSearchPd
        } = this.props;

        const {
            searchPd,
            setLoadingFalse,
            setStartDate,
            setEndDate,
            setSelectedLocations,
            setSelectedSubjects,
            setSelectedSeminarLength,
            setSelectedDeliveryFormats,
            setSelectedCoursePrograms,
            setSelectedEthicsHours,
            setSelectedCertificatePrograms,
            setSelectedLeaders,
            setKeywordsSearchValue,
            selectCourse,
            setVisibleListBookmarks,
            getPdDetails,
            resetSearchResultItems,
            resetFiltersSearch,
            setAllSearchCriteria,
            setSelectedPassportCredits,
            setCourseFromHashVisible,
            setHashAllSearchCriteria,
            selectBookmark,
            setSortByValue,
            setNewCoursesOnlyVisible,
            setCopySearchTagVisible
        } = this.props.actions;

        const {
            startDate,
            endDate,
            selectedLocations,
            selectedSubjects,
            selectedSeminarLength,
            selectedDeliveryFormats,
            selectedCoursePrograms,
            selectedEthicsHours,
            selectedCertificatePrograms,
            selectedLeaders,
            selectedPassportCredits,
            selectedBookmark,
            selectedCourse,
            keywordsSearchValue,
            allSearchCriteria,
            courseFromHashVisible,
            hashAllSearchCriteria,
            sortBy,
            listBookmarksVisible,
            pdDetails,
            newCoursesOnlyVisible
        } = this.props.filtersSearch;

        return <div className="search-pd bg-gray">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <SearchFilters
                            isHomePage={false}
                            searchPd={searchPd}
                            setLoadingFalse={setLoadingFalse}
                            resetSearchResultItems={resetSearchResultItems}
                            resetFiltersSearch={resetFiltersSearch}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={setSelectedLocations}
                            selectedSubjects={selectedSubjects}
                            setSelectedSubjects={setSelectedSubjects}
                            selectedSeminarLength={selectedSeminarLength}
                            setSelectedSeminarLength={setSelectedSeminarLength}
                            selectedDeliveryFormats={selectedDeliveryFormats}
                            setSelectedDeliveryFormats={setSelectedDeliveryFormats}
                            selectedCoursePrograms={selectedCoursePrograms}
                            setSelectedCoursePrograms={setSelectedCoursePrograms}
                            selectedEthicsHours={selectedEthicsHours}
                            setSelectedEthicsHours={setSelectedEthicsHours}
                            selectedCertificatePrograms={selectedCertificatePrograms}
                            setSelectedCertificatePrograms={setSelectedCertificatePrograms}
                            selectedLeaders={selectedLeaders}
                            setSelectedLeaders={setSelectedLeaders}
                            selectedPassportCredits={selectedPassportCredits}
                            setSelectedPassportCredits={setSelectedPassportCredits}
                            keywordsSearchValue={keywordsSearchValue}
                            setKeywordsSearchValue={setKeywordsSearchValue}
                            selectCourse={selectCourse}
                            searchResult={searchResult}
                            locations={locations}
                            courseSubjects={courseSubjects}
                            seminarLength={seminarLength}
                            deliveryFormats={deliveryFormats}
                            coursePrograms={coursePrograms}
                            ethicsHours={ethicsHours}
                            certificatePrograms={certificatePrograms}
                            leaders={leaders}
                            selectedBookmark={selectedBookmark}
                            setVisibleListBookmarks={setVisibleListBookmarks}
                            selectBookmark={selectBookmark}
                            sortBy={sortBy}
                            getPdDetails={getPdDetails}
                            setAllSearchCriteria={setAllSearchCriteria}
                            allSearchCriteria={allSearchCriteria}
                            locationsInit={locations}
                            setSortByValue={setSortByValue}
                            passportCredits={passportCredits}
                            setCourseFromHashVisible={setCourseFromHashVisible}
                            hashAllSearchCriteria={hashAllSearchCriteria}
                            setHashAllSearchCriteria={setHashAllSearchCriteria}
                            redirectUrl={redirectUrl}
                            onHomePage={this.state.onSearchPdHomePage}
                            newCoursesOnlyVisible={newCoursesOnlyVisible}
                            setNewCoursesOnlyVisible={setNewCoursesOnlyVisible}
                            setCopySearchTagVisible={setCopySearchTagVisible}
                            urlSearchPd={urlSearchPd}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }
}

SearchPdHomePage.propTypes = {
    coursePrograms: PropTypes.array.isRequired,
    certificatePrograms: PropTypes.array.isRequired,
    courseSubjects: PropTypes.array.isRequired,
    deliveryFormats: PropTypes.array.isRequired,
    leaders: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    seminarLength: PropTypes.array.isRequired,
    ethicsHours: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult ? state.searchResult : {
            pageIndex: 0,
            pageSize: 9,
            items: [],
            totalCount: 0,
            isLoading: false
        },
        filtersSearch: state.filtersSearch,
        loadingMore: state.loadingMore ? state.loadingMore : false
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            searchPd: bindActionCreators(searchPdActions.searchPd, dispatch),
            getPdDetails: bindActionCreators(searchPdActions.getPdDetails, dispatch),
            setLoadingFalse: bindActionCreators(searchPdActions.setLoadingFalse, dispatch),
            resetSearchResultItems: bindActionCreators(searchPdActions.resetSearchResultItems, dispatch),
            resetFiltersSearch: bindActionCreators(searchPdActions.resetFiltersSearch, dispatch),
            setStartDate: bindActionCreators(searchPdActions.setStartDate, dispatch),
            setEndDate: bindActionCreators(searchPdActions.setEndDate, dispatch),
            setSelectedLocations: bindActionCreators(searchPdActions.setSelectedLocations, dispatch),
            setSelectedSubjects: bindActionCreators(searchPdActions.setSelectedSubjects, dispatch),
            setSelectedSeminarLength: bindActionCreators(searchPdActions.setSelectedSeminarLength, dispatch),
            setSelectedDeliveryFormats: bindActionCreators(searchPdActions.setSelectedDeliveryFormats, dispatch),
            setSelectedCoursePrograms: bindActionCreators(searchPdActions.setSelectedCoursePrograms, dispatch),
            setSelectedEthicsHours: bindActionCreators(searchPdActions.setSelectedEthicsHours, dispatch),
            setSelectedCertificatePrograms: bindActionCreators(searchPdActions.setSelectedCertificatePrograms, dispatch),
            setSelectedLeaders: bindActionCreators(searchPdActions.setSelectedLeaders, dispatch),
            setSelectedPassportCredits: bindActionCreators(searchPdActions.setSelectedPassportCredits, dispatch),
            setKeywordsSearchValue: bindActionCreators(searchPdActions.setKeywordsSearchValue, dispatch),
            selectCourse: bindActionCreators(searchPdActions.selectCourse, dispatch),
            selectBookmark: bindActionCreators(searchPdActions.selectBookmark, dispatch),
            setVisibleListBookmarks: bindActionCreators(searchPdActions.setVisibleListBookmarks, dispatch),
            setSortByValue: bindActionCreators(searchPdActions.setSortByValue, dispatch),
            setAllSearchCriteria: bindActionCreators(searchPdActions.setAllSearchCriteria, dispatch),
            setCourseFromHashVisible: bindActionCreators(searchPdActions.setCourseFromHashVisible, dispatch),
            setNewCoursesOnlyVisible: bindActionCreators(searchPdActions.setNewCoursesOnlyVisible, dispatch),
            setHashAllSearchCriteria: bindActionCreators(searchPdActions.setHashAllSearchCriteria, dispatch),
            setCopySearchTagVisible: bindActionCreators(searchPdActions.setCopySearchTagVisible, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPdHomePage);


