import * as moment from "moment";

export function getListLocationIds(array, property) {
    const arrayList = [];
    for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i][property].length; j++) {
            arrayList.push(array[i][property][j]);
        }
    }
    return arrayList;
}

export function getListIds(array, property) {
    const arrayList = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i][property] !== "") {
            arrayList.push(array[i][property]);
        }
    }
    return arrayList;
}

export function getSimpleListIds(array, property) {
    let arrayList = [];
    let finalArrayList = [];

    for (let i = 0; i < array.length; i++) {
        if (array[i][property] !== "") {
            arrayList.push(array[i][property]);
        }
    }

    if (arrayList.length > 0) {
        arrayList = arrayList.sort((a, b) => a - b);
        finalArrayList = arrayList.map(function (e) { return e.toString() });
    }
    return finalArrayList;
}

export function isItemSelected(object, propName, value) {
    const arrayList = [];
    for (let i = 0; i < object.length; i++) {
        arrayList.push(object[i][propName]);
    }

    if (arrayList.includes(value)) {
        return true;
    }
    else { return false }
}

export function getHashList(array) {
    let arrayHash = '';
    for (var i = 0; i < array.length; i++) {
        arrayHash = arrayHash.concat(array[i]);
        if (i + 1 < array.length) {
            arrayHash = arrayHash.concat('|');
        }
    }
    return arrayHash;
}


export function getListFromQueryWithChildren(arrayFromUrl, allArray, propId, propName) {
    let listSubjects = [];
    for (var i = 0; i < arrayFromUrl.length; i++) {
        let code = '';
        for (var j = 0; j < allArray.length; j++) {
            for (var k = 0; k < allArray[j].Children.length; k++) {
                if (arrayFromUrl[i] === allArray[j].Children[k][propName]) {
                    code = allArray[j].Children[k][propId]
                }
            }
            
        }
        listSubjects.push({
            [propId]: code,
            [propName]: arrayFromUrl[i]
        });
    }
    return listSubjects;
}


export function getListFromQuery(arrayFromUrl, allArray, propId, propName) {
    let listSubjects = [];
    if (arrayFromUrl == "Any or All") {
        listSubjects.push({
            [propId]: 'allorany',
            [propName]: 'Any or All'
        });
    }
    else {
        for (var i = 0; i < arrayFromUrl.length; i++) {
            let subjectCode = '';
            for (var j = 0; j < allArray.length; j++) {
                if (arrayFromUrl[i] == allArray[j][propName]) {
                    subjectCode = allArray[j][propId]
                }
            }
            listSubjects.push({
                [propId]: subjectCode,
                [propName]: arrayFromUrl[i]
            });
        }
    }
    return listSubjects;
}

export function getListFromQuerySemLeader(arrayFromUrl, allArray, propId) {
    let listSubjects = [];
    for (var i = 0; i < arrayFromUrl.length; i++) {
        let subjectCode = '';
        for (var j = 0; j < allArray.length; j++) {
            let fullName = allArray[j].FirstName + ' ' + allArray[j].LastName
            if (arrayFromUrl[i] == fullName) {
                subjectCode = allArray[j].LeaderId
            }
        }
        listSubjects.push({
            LeaderId: subjectCode,
            Name: arrayFromUrl[i]
        });
    }
    return listSubjects;
}

export function getEmptySearchRequest() {

    const emptySearchRequest = {
        StartDate: null,
        EndDate: null,
        PageIndex: 0,
        PageSize: 9,
        CpabcVenueId: [],
        CpabcCourseSubcategory: [],
        DurationHours: [],
        CpabcCourseDeliveryMethod: [],
        CpabcCourseProgram: [],
        EthicsHours: [],
        EthicsHoursGreaterThan0: null,
        CpabcCertificateProgram: [],
        CpabcSeminarLeaderId: [],
        PassportCredits: [],
        Keywords: "",
        Sort: 3
    };

    return emptySearchRequest;
}

export function getDatesFormat (firstDate, secondDate) {

    let currentDate = new Date();
    let currentDateYear = moment(currentDate).format("YYYY");
    let currentDateMonth = moment(currentDate).format("MMM");

    let firstDateYear = moment(firstDate).format("YYYY");
    let firstDateMonth = moment(firstDate).format("MMM");
    let firstDateFormatFull = moment(firstDate).format("MMM D, YYYY");
    let firstDateFormatM = moment(firstDate).format("MMM D");
    let firstDateFormatD = moment(firstDate).format("D");

    let secondDateYear = moment(secondDate).format("YYYY");
    let secondDateMonth = moment(secondDate).format("MMM");
    let secondDateFormatFull = moment(secondDate).format("MMM D, YYYY");
    let secondDateFormatM = moment(secondDate).format("MMM D");
    let secondDateFormatD = moment(secondDate).format("D");

    if (firstDate === null && secondDate === null) {
        return "";
    }
    else if (firstDateFormatFull === secondDateFormatFull) {
        return firstDateFormatFull;
    }

    else if (firstDate != null && secondDate === null) {
        return firstDateFormatFull;
    }

    else if (firstDate != null && secondDate != null && (firstDateYear === secondDateYear) && (firstDateMonth === secondDateMonth)) {
        return firstDateFormatM + " - " + secondDateFormatD + ", " + firstDateYear;
    }

    else if (firstDate != null && secondDate != null && (firstDateYear === secondDateYear) && (firstDateMonth != secondDateMonth)) {
        return firstDateFormatM + " - " + secondDateFormatFull;
    }

    else if (firstDate != null && secondDate != null && (firstDateYear != secondDateYear) && (firstDateMonth === secondDateMonth)) {
        return firstDateFormatFull + " - " + secondDateFormatFull;
    }

    else if (firstDate != null && secondDate != null && (firstDateYear != secondDateYear) && (firstDateMonth != secondDateMonth)) {
        return firstDateFormatFull + " - " + secondDateFormatFull;
    }
}
