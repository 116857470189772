import React from 'react';

export default function SelectedEthicsHours(props) {
    const { selectedEthicsHours, onRemoveEthHour} = props;

    return (
        <>
            {selectedEthicsHours.length > 0 &&
                <div className="d-flex mr-4 mb-2 ethic-hours-filters">
                    <div className="option-select">
                        Ethics Hours: &nbsp;
                            {selectedEthicsHours.length > 0 && selectedEthicsHours
                            .sort((a, b) => a.Value - b.Value)
                            .map((item, index) => {
                                return (
                                    <div className="d-flex flex-row align-items-center">
                                        <span>{item.Text}</span>
                                        <a href="javascript:void(0)">
                                            <span aria-hidden="true" onClick={onRemoveEthHour(item,
                                                "Text",
                                                "EthicsHours")}> &times;</span>
                                        </a>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            }
        </>
    );
}
