import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Col, Tooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class DatePickerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model,
            tooltip: false,
            dateTime: null
        }

        this.handleChange = this.handleChange.bind(this);
    }

    isEmptyAndRequired(value, columnModel) {
        if (!value && !columnModel.allowEmpty) {
            return true;
        }
        return false;
    }

    handleChange(date) {
        var columnModel = this.state.model;
        if (columnModel) {
            if (this.isEmptyAndRequired(date, columnModel)) {
                columnModel.invalid = true;
                columnModel.errorMessage = columnModel.description;
            } else {
                if (date) {
                    var month = date.getMonth() + 1;
                    var day = date.getDate();
                    var year = date.getFullYear();
                    columnModel.value = month + "/" + day + "/" + year;
                } else {
                    columnModel.value = "";
                }

                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied; 

                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({
                dateTime: date,
                model: columnModel
            }, () => {
                this.props.onChanged && this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {
        return <FormGroup row key={this.props.field.guid}
            className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
            style={this.props.type !== "hidden" ? this.state.model.visible ? {} : { display: 'none' } : { display: 'none' }}>
            <Col sm={3} style={this.props.labelOnTop ? { display: 'none' } : {}}>
                <Label for={"txt" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
            </Col>
            <Col sm={this.props.labelOnTop ? 12 : 9}>
                <Label for={"txt" + this.props.field.column + "_" + this.props.field.guid} className="input-label"
                    style={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}>
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
                <DatePicker
                    selected={this.state.dateTime}
                    onChange={this.handleChange}
                    dateFormat={"MM/dd/yyyy"}
                    className={this.state.model.invalid ? "is-invalid form-control" : "form-control"}
                />
                <div className="explanation-text">{this.props.field.explanationText}</div>
                <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                    className="invalid-message">
                    <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                    {this.state.model.errorMessage}
                </div>

            </Col>
        </FormGroup>
    }
}

export default DatePickerComponent;