import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function loadingMoreReducer(state = initialState.loadingMore, action) {
    switch (action.type) {
        case types.BEGIN_LOAD_MORE_CALL:
        case types.END_LOAD_MORE_CALL:
            return action.loadingMore;
        default:
            return state;
    }
}