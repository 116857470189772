import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Col, Label, Tooltip, CustomInput } from 'reactstrap';

class FileUploaderFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.formElementRef = null;

        this.state = {
            model: this.props.model,
            fileNames: "",
            tooltip: false
        }

        this.handleFileChange = this.handleFileChange.bind(this);
        this.fileWrongFileType = this.fileWrongFileType.bind(this);
    }

    getInputElement() {
        return this.formElementRef;
    }

    isFileRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value.length === 0) && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    fileExeedLimit(file, inputModel) {
        var fileSize = file.size / 1024 / 1024; // in MB
        if (fileSize > parseInt(this.props.field.fileSize)) {
            return true;
        } else {
            return false;
        }
    }

    fileWrongFileType(file, inputModel) {
        if (!file || !(this.props.field.fileTypes || "").length) return false;
        const lastDot = file.name.lastIndexOf('.');
        const ext = file.name.substring(lastDot + 1);
        return this.props.field.fileTypes.indexOf(ext) < 0;
    }

    handleFileChange(event) {
        const cancel = !event.target.files.length;
        if (cancel) return;

        let inputElement = event.target;
        var tempModel = this.state.model;
        tempModel.invalid = false;

        if (this.isFileRequired(inputElement, tempModel)) {
            tempModel.invalid = true;
            tempModel.errorMessage = this.props.field.description || "Required";
        }


        if (!tempModel.invalid && this.fileExeedLimit(event.target.files[0], tempModel)) {
            tempModel.invalid = true;
            tempModel.errorMessage = this.props.field.fileSizeErrorMessage;
        }


        if (!tempModel.invalid) {
            if (this.fileWrongFileType(event.target.files[0], tempModel)) {
                tempModel.invalid = true;
                tempModel.errorMessage = this.props.field.fileTypeErrorMessage || "Wrong file format!";
            }
        }

        if (!tempModel.invalid) {
            tempModel.invalid = false;
            tempModel.errorMessage = "";
        }

        tempModel.value = event.target.files;
        let fileNames = event.target.files[0].name;

        this.setState({ model: tempModel, fileNames: fileNames });
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {
        return <FormGroup row key={this.props.field.guid} className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
            style={this.state.model.visible ? {} : { display: 'none' }}>
            <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                <Label for={"file" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
            </Col>
            <Col sm={this.props.labelOnTop ? 12 : 9}>
                <Label for={"file" + this.props.field.column + "_" + this.props.field.guid} className="input-label"
                    style={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}>
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
                <CustomInput
                    type="file"
                    id={"file" + this.props.field.column + "_" + this.props.field.guid}
                    name={this.props.field.column}
                    label={this.state.fileNames || this.props.field.placeholder}
                    onChange={this.handleFileChange}
                    innerRef={ref => { this.formElementRef = ref; this.props.formElementRefs[this.props.field.column] = ref }}
                    invalid={this.state.model.invalid}
                    accept={this.props.field.fileTypes ? this.props.field.fileTypes : "*.*"} />
                <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                    className="alert alert-danger">
                    {this.state.model.errorMessage}
                </div>

                <div className="explanation-text">{this.props.field.explanationText}</div>

            </Col>
        </FormGroup>
    }
}

export default FileUploaderFieldComponent;