import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Input, Col, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class RadioButtonsFieldComponent extends Component {
    constructor(props) {
        super(props);

        this.formElementRef = null;

        this.state = {
            model: this.props.model,
            tooltip: false
        }
    }

    getInputElement() {
        return this.formElementRef;
    }

    isEmptyAndRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value === "") && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    inputChange(event) {
        let inputElement = event.target;
        var columnModel = this.state.model;
        if (columnModel) {
            if (this.isEmptyAndRequired(inputElement, columnModel)) {
                columnModel.invalid = true;
            }
            else {
                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied;
                columnModel.value = inputElement.value;
                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({ model: columnModel }, () => {
                this.props.onChanged && this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if (this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {
        return (
            <FormGroup row key={this.props.field.guid} className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""}
                style={this.state.model.visible ? {} : { display: 'none' }}>
                <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                    <Label for={"rbl" + this.props.field.column + "_" + this.props.field.guid} className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                </Col>
                <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9} className="radio-buttons">
                    <Label for={"rbl" + this.props.field.column + "_" + this.props.field.guid}
                        style={this.props.showLabel && this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}
                        className="input-label">
                        {this.props.field.caption}
                        <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                    </Label>
                    {
                        this.props.field.listValues.map((option, index) => {
                            return (<div key={this.props.field.guid + '-' + index} className="custom-control-inline">
                                <Input
                                    id={this.props.field.guid + '-' + index}
                                    //className="custom-control-input"
                                    invalid={this.state.model.invalid}
                                    type="radio"
                                    name={"rbl" + this.props.field.column + "_" + this.props.field.guid}
                                    value={option.value}
                                    onChange={this.inputChange.bind(this)}
                                    checked={option.value === this.state.model.value}
                                    innerRef={ref => {
                                        if (!this.formElementRef) {
                                            this.formElementRef = {};
                                        }
                                        if (ref !== null) {
                                            this.formElementRef[index] = ref;
                                        }

                                        this.props.formElementRefs[this.props.field.column] = this.formElementRef;
                                    }
                                    }
                                />
                                <Label
                                    //className="custom-control-label"
                                    for={this.props.field.guid + '-' + index}
                                >
                                    {option.text}
                                </Label>
                            </div>);
                        })
                    }
                    <div className="explanation-text">{this.props.field.explanationText}</div>
                    <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }}
                        className="invalid-message">
                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                        {this.state.model.errorMessage}
                    </div>
                </Col>
            </FormGroup>
        )
    }
}

export default RadioButtonsFieldComponent;