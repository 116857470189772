import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Input, Col, Tooltip } from 'reactstrap';
import TextInputFieldComponent from './textInputFieldComponent';
import CheckBoxInputFieldComponent from './checkBoxInputFieldComponent';
import DropdownWithDataSourceFieldComponent from './dropdownWithDataSourceFieldComponent';
import ProvinceStateDropDownComponent from './provinceStateDropDownComponent';
import DropdownFieldComponent from './dropdownFieldComponent';
import MultiSelectDropdownFieldComponent from './multiselectDropdownFieldComponent';
import RadioButtonsFieldComponent from './radioButtonsFieldComponent';
import SubmitButtonComponent from './submitButtonComponent';
import FileUploaderFieldComponent from './fileUploaderFieldComponent';
import DatePickerComponent from "./datePickerComponent";
import ReCAPTCHA from "react-google-recaptcha";
import Parser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class DefaultColumnFormSection extends Component {
    constructor(props) {
        super(props);
    }

    isEmailField(field) {
        let isEmailField = false;

        if (field.validationRules && field.validationRules.length > 0) {
            for (var index = 0; index < field.validationRules.length; index++) {
                if (field.validationRules[index].identifier === "Kentico.Email") {
                    isEmailField = true;
                    break;
                }
            }
        }

        return isEmailField;
    }

    onReCaptchaChange(value) {
        let tempModel = this.props.formComponent.state.model;
        if (value !== null && value !== "") {
            tempModel[this.recaptchaColumnName].invalid = false;
            tempModel[this.recaptchaColumnName].value = true;
        }
        else {
            tempModel[this.recaptchaColumnName].invalid = true;
            tempModel[this.recaptchaColumnName].value = false;
        }

        this.props.formComponent.setState({ model: tempModel });
    }

    getFormComponent(formComponent) {
        if (this.props.fields && this.props.fields.length > 0) {
            var field = null;
            for (var i = 0; i < this.props.fields.length; i++) {
                if (this.props.fields[i].guid === formComponent.guid) {
                    field = this.props.fields[i];
                    break;
                }
            }

            if (field) {
                if (field.visible) {
                    if (field.componentIdentifier === "Kentico.TextInput") {
                        var isEmailField = this.isEmailField(field);

                        if (field.visibilityIdentifier !== "Kentico.NeverVisible") {
                            if (!isEmailField) {
                                return <TextInputFieldComponent
                                    key={'TextInputFieldComponent-' + field.guid}
                                    field={field}
                                    model={this.props.model[field.column]}
                                    formElementRefs={this.props.formElementRefs}
                                    labelOnTop={this.props.labelsOnTop}
                                    showLabel={this.props.showLabels}
                                    globalMaxTextLength={this.props.formInfo.formMaxTextInputLenght}
                                    globalMaxTextLengthErrorMessage={this.props.formInfo.formMaxTextInputLenghtErrorMessage}
                                    onChanged={this.props.checkVisibilityCondition}
                                    type="text" />
                            } else {
                                return <TextInputFieldComponent
                                    key={'TextInputFieldComponent-' + field.guid}
                                    field={field}
                                    model={this.props.model[field.column]}
                                    formElementRefs={this.props.formElementRefs}
                                    labelOnTop={this.props.labelsOnTop}
                                    showLabel={this.props.showLabels}
                                    globalMaxTextLength={this.props.formInfo.formMaxTextInputLenght}
                                    globalMaxTextLengthErrorMessage={this.props.formInfo.formMaxTextInputLenghtErrorMessage}
                                    onChanged={this.props.checkVisibilityCondition}
                                    type="email" />
                            }
                        }
                    } if (field.componentIdentifier === "Kentico.EmailInput" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <TextInputFieldComponent
                            key={'TextInputFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            globalMaxTextLength={this.props.formInfo.formMaxTextInputLenght}
                            globalMaxTextLengthErrorMessage={this.props.formInfo.formMaxTextInputLenghtErrorMessage}
                            onChanged={this.props.checkVisibilityCondition}
                            type="email" />
                    } else if (field.componentIdentifier === "Kentico.TextInput" && field.visibilityIdentifier === "Kentico.NeverVisible") {
                        return <TextInputFieldComponent
                            key={'TextInputFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            globalMaxTextLength={this.props.formInfo.formMaxTextInputLenght}
                            globalMaxTextLengthErrorMessage={this.props.formInfo.formMaxTextInputLenghtErrorMessage}
                            onChanged={this.props.checkVisibilityCondition}
                            type="hidden" />
                    } else if (field.componentIdentifier === "Kentico.TextArea" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <TextInputFieldComponent
                            key={'TextAreaInputFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            globalMaxTextLength={this.props.formInfo.formMaxTextAreaLenght}
                            globalMaxTextLengthErrorMessage={this.props.formInfo.formMaxTextAreaLenghtErrorMessage}
                            onChanged={this.props.checkVisibilityCondition}
                            type="textarea" />
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.DataSourceDropDownComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <DropdownWithDataSourceFieldComponent
                            key={'DropdownWithDataSourceFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            onChanged={this.props.checkVisibilityCondition} />
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.ProvinceStateDropDownComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <ProvinceStateDropDownComponent
                            key={'ProvinceStateDropDownComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            onChanged={this.props.checkVisibilityCondition}
                            showLabel={this.props.showLabels} />
                    } else if ((field.componentIdentifier === "Kentico.DropDown" || field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.DropDownComponent") && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <DropdownFieldComponent
                            key={'DropdownFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            onChanged={this.props.checkVisibilityCondition} />
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.MultiSelectDropDownComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <MultiSelectDropdownFieldComponent
                            key={'MultiSelectDropdownFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            onChanged={this.props.checkVisibilityCondition} />
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.SubmitButtonComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <SubmitButtonComponent key={field.guid}
                            submit={this.props.submit}
                            loading={this.props.loading}
                            buttonText={field.submitButtonText}>
                        </SubmitButtonComponent>
                    } else if (field.componentIdentifier === "Kentico.Recaptcha") {
                        return <FormGroup row key={field.guid}>
                            <Col sm={3} style={this.props.labelsOnTop ? { display: 'none' } : {}}></Col>
                            <Col sm={this.props.labelsOnTop ? 12 : 9}>
                                <ReCAPTCHA className={this.props.model[field.column].invalid ? "is-mandatory" : ""}
                                    sitekey={this.props.gooleRecaptchaSitekey}
                                    onChange={this.onReCaptchaChange.bind(this)}
                                    ref={ref => {
                                        if (ref) {
                                            this.props.fillRecaptchaColumnName(field.column);
                                            this.props.formElementRefs[field.column] = ref;
                                            this.recaptchaColumnName = field.column;
                                        }
                                    }} />
                                <div id={field.column + "_errorMessage_" + field.guid} style={this.props.model[field.column].invalid ? {} : { display: 'none' }}
                                    className="invalid-message">
                                    <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                                    Please verify
                                </div>
                            </Col>
                        </FormGroup>
                    } else if (field.componentIdentifier === "Kentico.CheckBox" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <CheckBoxInputFieldComponent
                            key={'CheckBoxInputFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            onChanged={this.props.checkVisibilityCondition} />
                    } else if ((field.componentIdentifier === "Kentico.RadioButtons" || field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.RadioButtonsComponent") && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <RadioButtonsFieldComponent
                            key={'RadioButtonsFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            onChanged={this.props.checkVisibilityCondition} />
                    } else if (field.componentIdentifier === "CustomDev.FormComponents.TextLabel" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <FormGroup row key={'labelFormGroup-' + field.guid}
                            style={this.props.model[field.column].visible ? {} : { display: 'none' }}>
                            <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                                <Label key={'labelCaption-' + field.guid}>{field.caption}</Label>
                            </Col>
                            <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9}>
                                <Label className="text-label" key={'labelDisplayText-' + field.guid}>{field.displayText}</Label>
                            </Col>
                        </FormGroup>
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.TextComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <FormGroup row key={'labelFormGroup-' + field.guid}
                            style={this.props.model[field.column].visible ? {} : { display: 'none' }}>
                            <Col sm={3} style={this.props.showLabel && !this.props.labelOnTop ? {} : { display: 'none' }}>
                                <Label key={'labelCaption-' + field.guid}>{field.caption}</Label>
                            </Col>
                            <Col sm={!this.props.showLabel || this.props.labelOnTop ? 12 : 9}>
                                <div className="text-label" key={'textComponent-' + field.guid}>{Parser(field.text)}</div>
                            </Col>
                        </FormGroup>
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.DatePickerComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <DatePickerComponent
                            key={'DatePickerComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            ref={ref => {
                                if (ref) {
                                    this.props.formElementRefs[field.column] = ref
                                }
                            }
                            }
                            type="datetime"
                            onChanged={this.props.checkVisibilityCondition} />;
                    } else if (field.componentIdentifier === "Kentico.FileUploader" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <FileUploaderFieldComponent
                            key={'FileUploaderFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            type="hidden" />
                    } else if (field.componentIdentifier === "CPABCPD.WebCore.FormBuilder.FormComponents.CustomFileUploaderComponent" && field.visibilityIdentifier !== "Kentico.NeverVisible") {
                        return <FileUploaderFieldComponent
                            key={'FileUploaderFieldComponent-' + field.guid}
                            field={field}
                            model={this.props.model[field.column]}
                            formElementRefs={this.props.formElementRefs}
                            labelOnTop={this.props.labelsOnTop}
                            showLabel={this.props.showLabels}
                            type="hidden" />
                    }
                } else {
                    return (<div></div>);
                }
            } else {
                return (<div></div>);
            }
        } else {
            return (<div></div>);
        }
    }

    fileUploadChanged(event) {
        debugger;
        const files = Array.from(event.target.files);
    }

    render() {
        const components = (<div className="form-fields">
            {
                this.props.section.zones[0].formComponents.map((formComponent, key) => {
                    return this.getFormComponent(formComponent);
                })
            }
        </div>);
        return (
            <div style={this.props.visible && !this.props.posted ? {} : { display: "none" }} className="form-section">
                <div className="row">
                    <div className="col-md-12">{components}</div>
                </div>
            </div>
        )
    }
}

export default DefaultColumnFormSection;