import * as React from 'react';
import { Component } from 'react';
import { FormGroup, Label, Input, Col, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class TextInputFieldComponent extends Component {
    constructor(props) {
        super(props);
        this.formElementRef = null;

        this.state = {
            model: this.props.model,
            tooltip: false
        }
    }

    getInputElement() {
        return this.formElementRef;
    }

    isEmptyAndRequired(inputElement, inputModel) {
        if ((!inputElement.value || inputElement.value === "") && !inputModel.allowEmpty) {
            return true;
        }
        return false;
    }

    isValidMaxLength(inputElement, inputModel) {
        var length = inputElement.value ? inputElement.value.length : 0;
        if (this.props.globalMaxTextLength >= length) {
            return false;
        }
        else {
            return true;
        }
    }

    inputChange(event) {
        let inputElement = event.target;
        var columnModel = this.state.model;
        if (columnModel) {
            if (this.isEmptyAndRequired(inputElement, columnModel)) {
                columnModel.invalid = true;
                columnModel.errorMessage = columnModel.description;
            }
            else if (this.isValidMaxLength(inputElement, columnModel)) {
                columnModel.invalid = true;
                columnModel.errorMessage = this.props.globalMaxTextLengthErrorMessage;
            }
            else {
                var notSatisfiedObject = columnModel.isNotValid();
                columnModel.invalid = false || notSatisfiedObject.notSatisfied;
                columnModel.value = inputElement.value;
                if (columnModel.invalid) {
                    columnModel.errorMessage = notSatisfiedObject.message;
                }
                else {
                    columnModel.errorMessage = "";
                }
            }

            this.setState({ model: columnModel }, () => {
                this.props.onChanged && this.props.onChanged();
            });
        }
    }

    toggleTooltip() {
        var tempModel = this.state.tooltip;

        if(this.state.model.errorMessage && this.state.model.errorMessage != "") {
            tempModel = !tempModel;
            this.setState({
                tooltip: tempModel
            });
        }
    }

    render() {
      return (
        <FormGroup row key={this.props.field.guid} 
                   className={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? "label-top" : ""} 
                   style={this.props.type !== "hidden" ? this.state.model.visible ? {} : { display: 'none' } : { display: 'none' }}
                   data-visibility-dependee-field={this.props.field.visibilityCondition ? this.props.field.visibilityCondition.dependeeFieldGuid : ""}
                   data-visibility-expected-value={this.props.field.visibilityCondition ? this.props.field.visibilityCondition.expectedValue : ""}>
            <Col sm={3} style={this.props.labelOnTop ? { display: 'none' } : {}}>
                <Label for={"txt" + this.props.field.column + "_" + this.props.field.guid} className="input-label"> 
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty  && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
            </Col>
            <Col sm={this.props.labelOnTop ? 12 : 9}>
                <Label for={"txt" + this.props.field.column + "_" + this.props.field.guid} className="input-label"
                       style={this.props.labelOnTop && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}>
                    {this.props.field.caption}
                    <span className="required" style={!this.props.field.allowEmpty && this.props.field.caption && this.props.field.caption !== "" ? {} : { display: 'none' }}> *</span>
                </Label>
                <Input invalid={this.state.model.invalid} 
                    type={this.props.type} name={this.props.field.column} 
                    id={"txt" + this.props.field.column + "_" + this.props.field.guid} 
                    placeholder={this.props.field.placeholder} 
                    onChange={this.inputChange.bind(this)}
                    innerRef={ref => {
                            if (ref) {
                                this.formElementRef = ref; 
                                this.props.formElementRefs[this.props.field.column] = ref
                            }
                        }
                    }
                    defaultValue={this.state.model.value}
                    />
                <div className="explanation-text">{this.props.field.explanationText}</div>
                <div id={this.props.field.column + "_errorMessage_" + this.props.field.guid} style={this.state.model.invalid ? {} : { display: 'none' }} 
                    className="invalid-message">
                      <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                      {this.state.model.errorMessage}                    
                </div>
            </Col>
        </FormGroup>
      )
    }
}

export default TextInputFieldComponent;