import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Zoom } from "react-toastify";
import PDDetails from "./PDDetails"; 
import LoadingIndicator from "./LoadingIndicator";
import InfiniteScroll from 'react-infinite-scroller';
import Dotdotdot from 'react-dotdotdot';
import { Responsive } from "../../../utils/Responsive";
import { getDeviceTypeInfo } from "../../../utils/utils";
import { getDatesFormat, getEmptySearchRequest } from "./filterUtils";
import BookmarkDropdown from "./BookmarkDropdown";

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.searchItemsRef = React.createRef();
        this.headPdDetails = React.createRef();
        this.cardsContainerRef = React.createRef();
        this.loadMore = this.loadMore.bind(this);
        this.getCourseSubjectDescription = this.getCourseSubjectDescription.bind(this);

        this.onSelectTag = this.onSelectTag.bind(this);
        this.bookmark = this.bookmark.bind(this);
        this.getDeliveryMethod = this.getDeliveryMethod.bind(this);

        this.state = {
            loadingMore: false,
            visiblePdDetails: false,
            pdDetailsMobileVisible: false
        };
    }

    seeHeadPdDetails = () => {
        this.headPdDetails.current.scrollIntoView({ block: "start" });
    }

    onClickButtonClosePdDetails = () => () => {

        const { selectedCourse } = this.props;

        this.setState({
            visiblePdDetails: false
        });
        document.body.className = "";

        var pdDetailsElement = document.getElementById(selectedCourse.cpabcMeeting);
        if (pdDetailsElement) {
            pdDetailsElement.scrollIntoView();
        }
    };

    //#region load More
    loadMore() {
        if (!this.state.loadMore && !this.props.searchResult.isLoading) {
            this.setState({ loadingMore: true }, () => {

                const searchCriteria = this.props.allSearchCriteria;
                const pageIndex = (searchCriteria ? searchCriteria.PageIndex : 0) + 1;

                const searchRequest = {
                    StartDate: searchCriteria && searchCriteria.StartDate ? searchCriteria.StartDate : null,
                    EndDate: searchCriteria && searchCriteria.EndDate ? searchCriteria.EndDate : null,
                    PageIndex: pageIndex,
                    PageSize: searchCriteria && searchCriteria.PageSize ? searchCriteria.PageSize : 9,
                    CpabcVenueId: searchCriteria && searchCriteria.CpabcVenueId ? searchCriteria.CpabcVenueId : [],
                    CpabcCourseSubcategory: searchCriteria && searchCriteria.CpabcCourseSubcategory ? searchCriteria.CpabcCourseSubcategory : [],
                    DurationHours: searchCriteria && searchCriteria.DurationHours ? searchCriteria.DurationHours : [],
                    CpabcCourseDeliveryMethod: searchCriteria && searchCriteria.CpabcCourseDeliveryMethod ? searchCriteria.CpabcCourseDeliveryMethod : [],
                    CpabcCourseProgram: searchCriteria && searchCriteria.CpabcCourseProgram ? searchCriteria.CpabcCourseProgram : [],
                    EthicsHours: searchCriteria && searchCriteria.EthicsHours ? searchCriteria.EthicsHours : [],
                    EthicsHoursGreaterThan0: searchCriteria && searchCriteria.EthicsHoursGreaterThan0 ? searchCriteria.EthicsHoursGreaterThan0 : null,
                    CpabcCertificateProgram: searchCriteria && searchCriteria.CpabcCertificateProgram ? searchCriteria.CpabcCertificateProgram : [],
                    CpabcSeminarLeaderId: searchCriteria && searchCriteria.CpabcSeminarLeaderId ? searchCriteria.CpabcSeminarLeaderId : [],
                    PassportCredits: searchCriteria && searchCriteria.PassportCredits ? searchCriteria.PassportCredits : [],
                    Keywords: searchCriteria && searchCriteria.Keywords ? searchCriteria.Keywords : "",
                    FromInit: false,
                    Sort: searchCriteria && searchCriteria.Sort ? searchCriteria.Sort : 3,
                    NewCourses: searchCriteria && searchCriteria.NewCourses ? searchCriteria.NewCourses : null,
                    IncludeCancelledCourses: searchCriteria && searchCriteria.IncludeCancelledCourses ? searchCriteria.IncludeCancelledCourses : false,
                    IncludePastCourses: searchCriteria && searchCriteria.IncludePastCourses ? searchCriteria.IncludePastCourses : false,
                    ViewCpaProCoursesOnly: searchCriteria && searchCriteria.ViewCpaProCoursesOnly ? searchCriteria.ViewCpaProCoursesOnly : false,
                }

                if (searchCriteria.Tags && searchCriteria.Tags.length > 0) {
                    searchRequest.Tags = searchCriteria.Tags;
                }

                const sessionStorageSearchRequest = JSON.stringify(searchRequest);
                try {
                    sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);
                } catch (error) {
                    console.log(error.name);
                }

                
                this.props.setAllSearchCriteria(searchRequest);


                setTimeout(() => {
                    var state = this;
                    this.props.searchPd(searchRequest, function () {
                        state.setState({ loadingMore: false });
                    });
                }, 100);
            })
        }
        
    }
    //#endregion

    componentDidUpdate() {
        if (this.props.selectedCourse) {
            window.location.hash = '#/' + this.props.selectedCourse.cpabcMeeting.toLowerCase() + '/' + this.props.selectedCourse.masterCode.toLowerCase() + '/';
        }
    }

    //#region get Course Subject Description
    getCourseSubjectDescription(parameter) {
        const { courseSubjects } = this.props;
        for (var i = 0; i < courseSubjects.length; i++) {
            if (courseSubjects[i].Code === parameter) {
                return courseSubjects[i].Description.toUpperCase();
            }
        }
        return null;
    }
    //#endregion

    //#region get Delivery Method
    getDeliveryMethod(parameter) {
        const { deliveryFormats } = this.props;
        for (let i = 0; i < deliveryFormats.length; i++) {
            if (parameter === "OL") {
                return "Online";
            }
            else if (deliveryFormats[i].Code === parameter) {
                return deliveryFormats[i].Description;
            }
        }
        return null;
    }
    //#endregion

    //#region onClick Course Details
    onClickCourseDetails = (item) => () => {

        const {allSearchCriteria} = this.props

        this.props.setIsLoadingPdDetails(true);
        this.props.setPdDetails(null);
        this.props.selectCourse(item);
        this.props.getPdDetails(item.masterCode, allSearchCriteria);
        this.setState({
            visiblePdDetails: true
        });
    };
    //#endregion

    onKeyDownCourseDetails = (item) => () => {
        if (event.keyCode === 13) {
            const { allSearchCriteria } = this.props

            this.props.setIsLoadingPdDetails(true);
            this.props.setPdDetails(null);
            this.props.selectCourse(item);
            this.props.getPdDetails(item.masterCode, allSearchCriteria);
            this.setState({
                visiblePdDetails: true
            });
        }
    }

    //#region onClick Course Details Bookmark
    onClickCourseDetailsBookmark = (item) => () => {

        const emptySearchRequest = getEmptySearchRequest();

        this.props.setIsLoadingPdDetails(true);
        this.props.setPdDetails(null);
        this.props.selectCourse(item);
        this.props.getPdDetails(item.masterCode, emptySearchRequest);
        this.setState({
            visiblePdDetails: true
        });
    };
    //#endregion

    //#region onClick Course Details Mobile
    onClickCourseDetailsMobile = (item) => () => {
        const { setIsLoadingPdDetails, selectCourse, getPdDetails, allSearchCriteria } = this.props;

        setIsLoadingPdDetails(true);
        selectCourse(item);

        const emptySearchRequest = getEmptySearchRequest();

        if (allSearchCriteria) {
            getPdDetails(item.masterCode, allSearchCriteria);
        }
        else {
            getPdDetails(item.masterCode, emptySearchRequest);
        }

        this.setState({
            visiblePdDetails: true
        });

        this.seeHeadPdDetails();

        document.body.className = "pd-details";
    };
    //#endregion

    //#region onClick Bookmark Mobile
    onClickBookmarkMobile = (item) => () => {
        const { setIsLoadingPdDetails, selectCourse, getPdDetails, setVisibleListBookmarks, setCourseFromHashVisible, allSearchCriteria } = this.props;

        const emptySearchRequest = getEmptySearchRequest();

        setVisibleListBookmarks(true);
        setCourseFromHashVisible(false);
        setIsLoadingPdDetails(true);
        selectCourse(item);
        getPdDetails(item.masterCode, emptySearchRequest);

        this.setState({
            visiblePdDetails: true
        });

        this.seeHeadPdDetails();

        document.body.className = "pd-details";


    };
    //#endregion

    //#region array Includes InObj bookmark
    arrayIncludesInObj = (arr, key, valueToCheck) => {
        return arr.some(value => value[key] === valueToCheck);
    }
    //#endregion

    //#region bookmark
    bookmark = () => {
        const { selectedCourse, selectedBookmark } = this.props;
        let arrayBookmark = [];
        arrayBookmark = this.props.selectedBookmark;
        if (this.arrayIncludesInObj(arrayBookmark, "cpabcMeeting", selectedCourse.cpabcMeeting)) {
            for (var i = 0; i < arrayBookmark.length; i++) {
                if (arrayBookmark[i].cpabcMeeting == selectedCourse.cpabcMeeting) {
                    arrayBookmark.splice(i, 1);
                    toast.info(
                        selectedCourse.title + " has been removed from bookmarks.",
                        {
                            position: "bottom-right",
                            autoClose: 4000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            transition: Zoom
                        });

                }
            }
        }
        else {
            arrayBookmark.push(this.props.selectedCourse);
            toast.info(selectedCourse.title + " has been bookmarked.", {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Zoom
            });
        }

        const finalArrayBookmark = JSON.stringify(arrayBookmark);

        try {
            localStorage.setItem("ngStorage-bookmarks", finalArrayBookmark);
        } catch (error) {
            console.log(error.name);
        }
        
        this.props.selectBookmark(arrayBookmark);
    }

    //#endregion

    //#region on Sort By
    onSortBy = (parameter) => () => {
        const { copySearchTagVisible, tags, searchTag } = this.props;

        if (copySearchTagVisible) {

            const sortByValue = (parameter === "Alpha") ? 3 : 1;

            const searchRequest = {
                StartDate: null,
                EndDate: null,
                PageIndex: 0,
                PageSize: 9,
                CpabcVenueId: [],
                CpabcCourseSubcategory: [],
                DurationHours: [],
                CpabcCourseDeliveryMethod: [],
                CpabcCourseProgram: [],
                EthicsHours: [],
                EthicsHoursGreaterThan0: null,
                CpabcCertificateProgram: [],
                CpabcSeminarLeaderId: [],
                PassportCredits: [],
                Keywords: "",
                Sort: sortByValue,
            };

            let listTags = [];

            for (var i = 0; i < tags.length; i++) {
                if (searchTag.toLowerCase() === tags[i].Description.toLowerCase()) {
                    listTags.push(tags[i].CpaBcCourseTagCd);
                    break;
                }
            }

            if (listTags.length > 0) {
                searchRequest.Tags = listTags;
            }

            setTimeout(() => {
                this.props.searchPd(searchRequest);
            },
                100);

            this.props.setSortByValue(parameter);
            this.props.setAllSearchCriteria(searchRequest);
            this.props.setLoadingFalse(true);
            this.props.selectCourse(null);
            this.props.resetSearchResultItems();
            this.props.setVisibleListBookmarks(false);
            this.props.setCourseFromHashVisible(false);
            this.props.setCopySearchTagVisible(true);
        }
        else {
            this.props.setSortByValue(parameter);

            const searchCriteria = this.props.allSearchCriteria;
            const sortByValue = (parameter === "Alpha") ? 3 : 1;
            const finalSearchCriteria = searchCriteria ? searchCriteria : [];

            finalSearchCriteria.Sort = sortByValue;

            const searchRequest = {
                StartDate: finalSearchCriteria.StartDate ? finalSearchCriteria.StartDate : null,
                EndDate: finalSearchCriteria.EndDate ? finalSearchCriteria.EndDate : null,
                PageIndex: 0,
                PageSize: finalSearchCriteria.PageSize ? finalSearchCriteria.PageSize : 9,
                CpabcVenueId: finalSearchCriteria.CpabcVenueId ? finalSearchCriteria.CpabcVenueId : [],
                CpabcCourseSubcategory: finalSearchCriteria.CpabcCourseSubcategory ? finalSearchCriteria.CpabcCourseSubcategory : [],
                DurationHours: finalSearchCriteria.DurationHours ? finalSearchCriteria.DurationHours : [],
                CpabcCourseDeliveryMethod: finalSearchCriteria.CpabcCourseDeliveryMethod ? finalSearchCriteria.CpabcCourseDeliveryMethod : [],
                CpabcCourseProgram: finalSearchCriteria.CpabcCourseProgram ? finalSearchCriteria.CpabcCourseProgram : [],
                EthicsHours: finalSearchCriteria.EthicsHours ? finalSearchCriteria.EthicsHours : [],
                EthicsHoursGreaterThan0: finalSearchCriteria.EthicsHoursGreaterThan0 ? finalSearchCriteria.EthicsHoursGreaterThan0 : null,
                CpabcCertificateProgram: finalSearchCriteria.CpabcCertificateProgram ? finalSearchCriteria.CpabcCertificateProgram : [],
                CpabcSeminarLeaderId: finalSearchCriteria.CpabcSeminarLeaderId ? finalSearchCriteria.CpabcSeminarLeaderId : [],
                PassportCredits: finalSearchCriteria.PassportCredits ? finalSearchCriteria.PassportCredits : [],
                Keywords: finalSearchCriteria.Keywords ? finalSearchCriteria.Keywords : "",
                FromInit: false,
                Sort: finalSearchCriteria.Sort,
                NewCourses: finalSearchCriteria.NewCourses,
                IncludeCancelledCourses: finalSearchCriteria.IncludeCancelledCourses,
                IncludePastCourses: finalSearchCriteria.IncludePastCourses,
                ViewCpaProCoursesOnly: finalSearchCriteria.ViewCpaProCoursesOnly
            }

            const sessionStorageSearchRequest = JSON.stringify(searchRequest);
            try {
                sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);
            } catch (error) {
                console.log(error.name);
            }
            
            this.props.setAllSearchCriteria(searchRequest);
            this.props.setSortByValue(parameter);

            let device = getDeviceTypeInfo();
            let deviceType;
            if (device) {
                deviceType = device.deviceType;
            };

            if (deviceType === "Mobile") {
                searchRequest.deviceTypeinfo = "Mobile";
            };


            this.props.setLoadingFalse(true);
            this.props.selectCourse(null);
            this.props.resetSearchResultItems();
            this.props.setVisibleListBookmarks(false);
            this.props.searchPd(searchRequest);
        }
    };
    //#endregion

    onSelectTag = (parameter) => () => {

        const searchRequest = {
            StartDate: null,
            EndDate: null,
            PageIndex: 0,
            PageSize: 9,
            CpabcVenueId: [],
            CpabcCourseSubcategory: [],
            DurationHours: [],
            CpabcCourseDeliveryMethod: [],
            CpabcCourseProgram: [],
            EthicsHours: [],
            EthicsHoursGreaterThan0: null,
            CpabcCertificateProgram: [],
            CpabcSeminarLeaderId: [],
            PassportCredits: [],
            Keywords: "",
            Sort: 3,
            Tags: [parameter]
        };

        const sessionStorageSearchRequest = JSON.stringify(searchRequest);
        try {
            sessionStorage.setItem("ngStorage-searchCriteria", sessionStorageSearchRequest);
        } catch (error) {
            console.log(error.name);
        }

        
        this.props.setAllSearchCriteria(searchRequest);

        let tagDescription = this.getDescriptionTag(parameter);

        this.props.setSearchTag(tagDescription);

        setTimeout(() => {
                    this.props.searchPd(searchRequest);
                },
            100);

        this.props.setAllSearchCriteria(searchRequest);
        this.props.setLoadingFalse(true);
        this.props.selectCourse(null);
        this.props.resetSearchResultItems();
        this.props.setVisibleListBookmarks(false);
        this.props.setCourseFromHashVisible(false);
        this.props.setCopySearchTagVisible(true);
        
    };

    getDescriptionTag = (parameter) => {

        const {tags} = this.props;

        for (let i = 0; i < tags.length; i++) {
            if (tags[i].CpaBcCourseTagCd === parameter) {
                return tags[i].Description;
            }
        }
        return "Unknown";
    }


    render() {
        const {
            selectedCourse,
            selectedBookmark,
            listBookmarksVisible,
            pdDetails,
            availableButtonLabel,
            availableButtonLink,
            cancelledButtonLabel,
            cancelledButtonLink,
            closedButtonLabel,
            closedButtonLink,
            fullButtonLabel,
            fullButtonLink,
            pastButtonLabel,
            pastButtonLink,
            seminarLevels,
            coursePrograms,
            courseFromHashVisible,
            ideasTitle,
            ideasAbstract,
            ideasIcon,
            ideasLink,
            leaders,
            tags,
            pldpImage,
            pldpHoverText,
            locations,
            isLoadingPdDetails,
            setPdDetails,
            selectCourse,
            setVisibleListBookmarks,
            setCourseFromHashVisible,
            getPdDetails,
            setIsLoadingPdDetails,
        } = this.props;

        const { searchResult } = this.props;

        let items = [];
        let itemsTestimonials = [];
        if (pdDetails) {
            if (pdDetails.currentCourse.testimonials) {
                items = pdDetails.currentCourse.testimonials.length > 0 ? pdDetails.currentCourse.testimonials : [];
                for (var i = 0; i < items.length; i++) {
                    itemsTestimonials.push({
                        id: i + 1,
                        caption: items[i]
                    })
                }
            }
        }

        return <div className="search-result bg-grey">
            <div className="row">
                <div className="col px-0">
                    <div className="d-flex flex-column flex-lg-row mb-4">
                        <div className="search-items bg-white" ref={this.searchItemsRef}>
                            <Responsive displayIn={["Mobile"]}>
                                {true &&
                                    <div className="bookmark-mobile">
                                        <BookmarkDropdown
                                            selectedBookmark={selectedBookmark}
                                            selectCourse={selectCourse}
                                            setVisibleListBookmarks={setVisibleListBookmarks}
                                            setCourseFromHashVisible={setCourseFromHashVisible}
                                            getPdDetails={getPdDetails}
                                            setIsLoadingPdDetails={setIsLoadingPdDetails}
                                            onClickBookmarkMobile={this.onClickBookmarkMobile}
                                        />
                                    </div>

                                }
                            </Responsive>
                            <a href={ideasLink}>
                                <div className="ideas-card mb-4">
                                    <div className="d-flex">
                                        <div className="mr-2 idea-icon">
                                            <i className={ideasIcon}></i>
                                        </div>
                                        <div className="eq">
                                            <div className="title">
                                                <div dangerouslySetInnerHTML={{ __html: ideasTitle }} />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    <div dangerouslySetInnerHTML={{ __html: ideasAbstract }} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            {!listBookmarksVisible && !courseFromHashVisible &&
                                <div className="search-result-title mb-2">
                                    Search Results
                            </div>
                            }
                            {!listBookmarksVisible && searchResult.isLoading && !courseFromHashVisible && !this.state.loadingMore &&
                                <LoadingIndicator />
                            }

                            {!listBookmarksVisible && !courseFromHashVisible &&
                                <React.Fragment>
                                    {!searchResult.isLoading && !courseFromHashVisible &&
                                        <div className="d-flex sort-by-and-result-count mb-3">
                                            <div className="founds">
                                                <span>Found {searchResult.totalCount} results</span>
                                            </div>
                                            <div className=" sortby">
                                                {searchResult.totalCount > 0 &&
                                                    <div className="d-flex">
                                                        <div className="sortby-label ">
                                                            <span className="lbl-uppercase va-bottom d-none d-sm-block">Sort By:</span>
                                                        </div>

                                                        <div className="dropdown filter-dropdown show">
                                                            <a className="btn btn-secondary dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                {this.props.sortBy} </a>

                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <a className="dropdown-item" href="javascript:void(0);" onClick={this.onSortBy('Alpha')}>Alpha</a>
                                                                <a className="dropdown-item" href="javascript:void(0);" onClick={this.onSortBy('Date')}>Date</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <Responsive displayIn={["Tablet", "Laptop"]}>
                                        <div className="cards" ref={this.cardsContainerRef}>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.loadMore}
                                                hasMore={this.props.searchResult.totalCount > this.props.searchResult.items.length && !this.state.loadingMore && !searchResult.isLoading}
                                                useWindow={false}
                                                className={"infinite-scroll"}>
                                                {searchResult.items
                                                    .map((item) => {
                                                        let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                        return (
                                                            <a role="button" tabindex="0" key={item.cpabcMeeting} className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item != null && selectedCourse != null && item.masterCode == selectedCourse.masterCode) ? " bg-grey active" : "")} onClick={this.onClickCourseDetails(item)} onKeyDown={this.onKeyDownCourseDetails(item)} id={item.cpabcMeeting}>
                                                                <div className="card-body">
                                                                    {item.cpabcCourseSubject &&
                                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>
                                                                    }
                                                                    {item.title &&
                                                                        <div className="course-title">{item.title}</div>
                                                                    }
                                                                    <div className="row pt-3">
                                                                        {item.cpdHours &&
                                                                            <div className="col-5">
                                                                                <span className="label-number">{item.cpdHours}</span>
                                                                                <span className="medium"> CPD </span>
                                                                                <span className="small">Hours</span>
                                                                            </div>
                                                                        }
                                                                        {item.beginDate >= '2020-09-01' &&
                                                                            <div className="col-7">
                                                                                {item.passportUnit === 0 &&
                                                                                    <span className="label-black">N/A Passport Credits</span>
                                                                                }
                                                                                {item.passportUnit != 0 &&
                                                                                    <>
                                                                                        <span className="label-number">{item.passportUnit}</span>
                                                                                        <span className="text-grey medium"> Passport </span>
                                                                                        <span className="small">Credits</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {selectedCourse &&
                                                                                <div className="prerequisite-container">
                                                                                    <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                    {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                        item.availableDates
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        {index === 0 &&
                                                                                            <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                                <div className="d-flex cell mr-3">
                                                                                                    <div className="date">
                                                                                                        {item.beginDate &&
                                                                                                            <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="time">
                                                                                                        {item.beginTime && item.endTime &&
                                                                                                            <span className="label-black">{item.beginTime} - {item.endTime}</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                    }

                                                                    {item.availableDates && item.availableDates.length > 0
                                                                        && item.availableDates[0].beginTime === null
                                                                            && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                        <div className="row pt-1">
                                                                            <div className="col">
                                                                                {item.cpabcCourseProgram === "FREE" &&
                                                                                    <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                                }
                                                                                {item.cpabcCourseProgram !== "FREE" &&
                                                                                   <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {item.availableDates && item.availableDates.length === 0 &&
                                                                        <div className="row pt-1">
                                                                            <div className="col">
                                                                                <div className="on-demande-container">
                                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                        {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                            <div className="delivery-method">
                                                                                {item.cpabcCourseDeliveryMethod &&
                                                                                    <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {item.availableDates && item.availableDates.length > 0 &&
                                                                            <div className="course-status">
                                                                                <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </Responsive>

                                    <Responsive displayIn={["Mobile"]}>
                                        <div className="cards" ref={this.cardsContainerRef}>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.loadMore}
                                                hasMore={this.props.searchResult.totalCount > this.props.searchResult.items.length && !this.state.loadingMore}
                                                className={"infinite-scroll"}>
                                                {searchResult.items
                                                    .map((item) => {
                                                        let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                        return (
                                                            <a role="button" tabindex="0" key={item.cpabcMeeting} className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item != null && selectedCourse != null && item.masterCode == selectedCourse.masterCode) ? " bg-grey active" : "")} onClick={this.onClickCourseDetailsMobile(item)} id={item.cpabcMeeting}>
                                                                <div className="card-body">
                                                                    {item.cpabcCourseSubject &&
                                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>
                                                                    }
                                                                    {item.title &&
                                                                        <div className="course-title">{item.title}</div>
                                                                    }
                                                                    <div className="row pt-3">
                                                                        {item.cpdHours &&
                                                                            <div className="col-5">
                                                                                <span className="label-number">{item.cpdHours}</span>
                                                                                <span className="medium"> CPD </span>
                                                                                <span className="small">Hours</span>
                                                                            </div>
                                                                        }
                                                                        {item.beginDate >= '2020-09-01' &&
                                                                            <div className="col-7">
                                                                                {item.passportUnit === 0 &&
                                                                                    <span className="label-black">N/A Passport Credits</span>
                                                                                }
                                                                                {item.passportUnit != 0 &&
                                                                                    <>
                                                                                        <span className="label-number">{item.passportUnit}</span>
                                                                                        <span className="text-grey medium"> Passport </span>
                                                                                        <span className="small">Credits</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {selectedCourse &&
                                                                                <div className="prerequisite-container">
                                                                                    <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                    {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                        item.availableDates
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        {index === 0 &&
                                                                                            <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                                <div className="d-flex cell mr-3">
                                                                                                    <div className="date">
                                                                                                        {item.beginDate &&
                                                                                                            <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="time">
                                                                                                        {item.beginTime && item.endTime &&
                                                                                                            <span className="label-black">{item.beginTime} - {item.endTime}</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                    }

                                                                    {item.availableDates && item.availableDates.length > 0
                                                                        && item.availableDates[0].beginTime === null
                                                                            && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                        <div className="row pt-1">
                                                                            <div className="col">
                                                                                {item.cpabcCourseProgram === "FREE" &&
                                                                                    <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                                }
                                                                                {item.cpabcCourseProgram !== "FREE" &&
                                                                                    <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {item.availableDates && item.availableDates.length === 0 &&
                                                                        <div className="row pt-1">
                                                                            <div className="col">
                                                                                <div className="on-demande-container">
                                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                        {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                            <div className="delivery-method">
                                                                                {item.cpabcCourseDeliveryMethod &&
                                                                                    <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {item.availableDates && item.availableDates.length > 0 &&
                                                                            <div className="course-status">
                                                                                <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </Responsive>

                                </React.Fragment>
                            }

                            {!listBookmarksVisible && searchResult.isLoading && !courseFromHashVisible && this.state.loadingMore &&
                                <LoadingIndicator />
                            }

                            {listBookmarksVisible && searchResult.isLoading &&
                                <>
                                    <span>Bookmarked seminars ...</span>
                                    <LoadingIndicator />
                                </>
                            }

                            {listBookmarksVisible && !searchResult.isLoading &&
                                <React.Fragment>
                                    <Responsive displayIn={["Tablet", "Laptop"]}>
                                        <span className="bookmarked mb-3 pt-1">Bookmarked seminars - {selectedBookmark.length}</span>
                                        <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>
                                            {selectedBookmark
                                                .map((item) => {
                                                    let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                    return (
                                                        <a role="button" tabindex="0" className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item == selectedCourse) ? " bg-grey" : "")} onClick={this.onClickCourseDetailsBookmark(item)} id={item.cpabcMeeting}>
                                                            <div className="card-body">
                                                                {item.cpabcCourseSubject &&
                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>
                                                                }
                                                                {item.title &&
                                                                    <div className="course-title">{item.title}</div>
                                                                }
                                                                <div className="row">
                                                                    {item.cpdHours &&
                                                                        <div className="col-5">
                                                                            <span className="label-number">{item.cpdHours}</span>
                                                                            <span className="medium"> CPD </span>
                                                                            <span className="small">Hours</span>
                                                                        </div>
                                                                    }
                                                                    {item.beginDate >= '2020-09-01' &&
                                                                        <div className="col-7">
                                                                            {item.passportUnit === 0 &&
                                                                                <span className="label-black">N/A Passport Credits</span>
                                                                            }
                                                                            {item.passportUnit != 0 &&
                                                                                <>
                                                                                    <span className="label-number">{item.passportUnit}</span>
                                                                                    <span className="text-grey medium"> Passport </span>
                                                                                    <span className="small">Credits</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="row pt-1">
                                                                    <div className="col">
                                                                        {selectedCourse &&
                                                                            <div className="prerequisite-container">
                                                                                <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                    item.availableDates
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    {index === 0 &&
                                                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                            <div className="d-flex cell mr-3">
                                                                                                <div className="date">
                                                                                                    {item.beginDate &&
                                                                                                        <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="time">
                                                                                                    {item.beginTime && item.endTime &&
                                                                                                        <span className="label-black">{item.beginTime} - {item.endTime}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                }

                                                                {item.availableDates && item.availableDates.length > 0
                                                                    && item.availableDates[0].beginTime === null
                                                                        && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {item.cpabcCourseProgram === "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                            }
                                                                            {item.cpabcCourseProgram !== "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {item.availableDates && item.availableDates.length === 0 &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            <div className="on-demande-container">
                                                                                No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                        <div className="delivery-method">
                                                                            {item.cpabcCourseDeliveryMethod &&
                                                                                <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {item.availableDates && item.availableDates.length > 0 &&
                                                                        <div className="course-status">
                                                                            <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Responsive>

                                    <Responsive displayIn={["Mobile"]}>
                                        <span className="bookmarked mb-3 pt-1">Bookmarked seminars - {selectedBookmark.length}</span>
                                        <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>
                                            {selectedBookmark
                                                .map((item) => {
                                                    let beginDate = moment(item.beginDate).format("MMM DD, YYYY");
                                                    return (
                                                        <a role="button" tabindex="0" className={"card search-pd" + " left-margin-color-" + (item.cpabcCourseSubject ? item.cpabcCourseSubject.toLowerCase() : "") + ((item == selectedCourse) ? " bg-grey" : "")} onClick={this.onClickCourseDetailsBookmark(item)} id={item.cpabcMeeting}>
                                                            <div className="card-body">
                                                                {item.cpabcCourseSubject &&
                                                                    <div className="course-subject text-grey">{this.getCourseSubjectDescription(item.cpabcCourseSubject)}</div>
                                                                }
                                                                {item.title &&
                                                                    <div className="course-title">{item.title}</div>
                                                                }
                                                                <div className="row">
                                                                    {item.cpdHours &&
                                                                        <div className="col-5">
                                                                            <span className="label-number">{item.cpdHours}</span>
                                                                            <span className="medium"> CPD </span>
                                                                            <span className="small">Hours</span>
                                                                        </div>
                                                                    }
                                                                    {item.beginDate >= '2020-09-01' &&
                                                                        <div className="col-7">
                                                                            {item.passportUnit === 0 &&
                                                                                <span className="label-black">N/A Passport Credits</span>
                                                                            }
                                                                            {item.passportUnit != 0 &&
                                                                                <>
                                                                                    <span className="label-number">{item.passportUnit}</span>
                                                                                    <span className="text-grey medium"> Passport </span>
                                                                                    <span className="small">Credits</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="row pt-1">
                                                                    <div className="col">
                                                                        {selectedCourse &&
                                                                            <div className="prerequisite-container">
                                                                                <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: item.prerequisites }}></div></Dotdotdot>}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                    item.availableDates
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    {index === 0 &&
                                                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                                            <div className="d-flex cell mr-3">
                                                                                                <div className="date">
                                                                                                    {item.beginDate &&
                                                                                                        <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="time">
                                                                                                    {item.beginTime && item.endTime &&
                                                                                                        <span className="label-black">{item.beginTime} - {item.endTime}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                }

                                                                {item.availableDates && item.availableDates.length > 0
                                                                    && item.availableDates[0].beginTime === null
                                                                        && item.cpabcCourseDeliveryMethod === "OD" &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            {item.cpabcCourseProgram === "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                            }
                                                                            {item.cpabcCourseProgram !== "FREE" &&
                                                                                <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {item.availableDates && item.availableDates.length === 0 &&
                                                                    <div className="row pt-1">
                                                                        <div className="col">
                                                                            <div className="on-demande-container">
                                                                                No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                                    {item.availableDates && item.availableDates.length > 0 && item.availableDates[0].beginTime != null &&
                                                                        <div className="delivery-method">
                                                                            {item.cpabcCourseDeliveryMethod &&
                                                                                <span className="label-black">{this.getDeliveryMethod(item.cpabcCourseDeliveryMethod)}</span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {item.availableDates && item.availableDates.length > 0 &&
                                                                        <div className="course-status">
                                                                            <span className={"label-black label-status " + (item.courseStatus ? item.courseStatus.toLowerCase() : "")}>{item.courseStatus}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Responsive>
                                </React.Fragment>

                            }

                            {courseFromHashVisible &&
                                <Responsive displayIn={["Tablet", "Laptop"]}>
                                    <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>
                                        {isLoadingPdDetails &&
                                            <div className="mt-4">
                                                <LoadingIndicator />
                                            </div>
                                        }

                                        {selectedCourse && !isLoadingPdDetails &&
                                            <a role="button" tabindex="0" className={"card search-pd" + " left-margin-color-" + (selectedCourse.cpabcCourseSubject ? selectedCourse.cpabcCourseSubject.toLowerCase() : "") + " bg-grey active"}>
                                                <div className="card-body">
                                                    {selectedCourse.cpabcCourseSubject &&
                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}</div>
                                                    }
                                                    {selectedCourse.title &&
                                                        <div className="course-title">{selectedCourse.title}</div>
                                                    }
                                                    <div className="row">
                                                        {selectedCourse.cpdHours > 0 &&
                                                            <div className="col-5">
                                                                <span className="label-number">{selectedCourse.cpdHours}</span>
                                                                <span className="medium"> CPD </span>
                                                                <span className="small">Hours</span>
                                                            </div>
                                                        }
                                                        {selectedCourse.beginDate >= '2020-09-01' &&
                                                            <div className="col-7">
                                                                {selectedCourse.passportUnit === 0 &&
                                                                    <span className="label-black">N/A Passport Credits</span>
                                                                }
                                                                {selectedCourse.passportUnit != 0 &&
                                                                    <>
                                                                        <span className="label-number">{selectedCourse.passportUnit}</span>
                                                                        <span className="text-grey medium"> Passport </span>
                                                                        <span className="small">Credits</span>
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row pt-1">
                                                        <div className="col">
                                                            <div className="prerequisite-container">
                                                                <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisites }}></div></Dotdotdot>}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&
                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                            <div className="d-flex cell mr-3">
                                                                <div className="date">
                                                                    {selectedCourse.beginDate &&
                                                                        <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>
                                                                    }
                                                                </div>
                                                                <div className="time">
                                                                    {selectedCourse.beginTime && selectedCourse.endTime &&
                                                                        <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {selectedCourse && selectedCourse.courseStatus !== "Past"
                                                        && selectedCourse.beginTime === null
                                                                && selectedCourse.cpabcCourseDeliveryMethod === "OD" &&
                                                        <div className="row pt-1">
                                                            <div className="col">
                                                                {selectedCourse.cpabcCourseProgram === "FREE" &&
                                                                    <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                }
                                                                {selectedCourse.cpabcCourseProgram !== "FREE" &&
                                                                    <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {selectedCourse && selectedCourse.courseStatus === "Past" &&
                                                        <div className="row pt-1">
                                                            <div className="col">
                                                                <div className="on-demande-container">
                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                            </div>
                                                        </div>
                                                    }


                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                        {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&
                                                            <div className="delivery-method">
                                                                {selectedCourse.cpabcCourseDeliveryMethod &&
                                                                    <span className="label-black">{this.getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>
                                                                }
                                                            </div>
                                                        }
                                                        {selectedCourse && selectedCourse.courseStatus != "Past" &&
                                                            <div className="course-status">
                                                                <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </a>
                                        }
                                    </div>
                                </Responsive>
                            }

                            {courseFromHashVisible &&
                                <Responsive displayIn={["Mobile"]}>
                                    <div className="cards" style={{ height: '1000px', overflowY: "auto" }}>
                                        {isLoadingPdDetails &&
                                            <div className="mt-4">
                                                <LoadingIndicator />
                                            </div>
                                        }

                                        {selectedCourse && !isLoadingPdDetails &&
                                            <a role="button" tabindex="0" className={"card search-pd" + " left-margin-color-" + (selectedCourse.cpabcCourseSubject ? selectedCourse.cpabcCourseSubject.toLowerCase() : "") + " bg-grey"} onClick={this.onClickCourseDetailsMobile(selectedCourse)}>
                                                <div className="card-body">
                                                    {selectedCourse.cpabcCourseSubject &&
                                                        <div className="course-subject text-grey">{this.getCourseSubjectDescription(selectedCourse.cpabcCourseSubject)}</div>
                                                    }
                                                    {selectedCourse.title &&
                                                        <div className="course-title">{selectedCourse.title}</div>
                                                    }
                                                    <div className="row">
                                                        {selectedCourse.cpdHours != 0 &&
                                                            <div className="col-5">
                                                                <span className="label-number">{selectedCourse.cpdHours}</span>
                                                                <span className="medium"> CPD </span>
                                                                <span className="small">Hours</span>
                                                            </div>
                                                        }
                                                        {selectedCourse.beginDate >= '2020-09-01' &&
                                                            <div className="col-7">
                                                                {selectedCourse.passportUnit === 0 &&
                                                                    <span className="label-black">N/A Passport Credits</span>
                                                                }
                                                                {selectedCourse.passportUnit != 0 &&
                                                                    <>
                                                                        <span className="label-number">{selectedCourse.passportUnit}</span>
                                                                        <span className="text-grey medium"> Passport </span>
                                                                        <span className="small">Credits</span>
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row pt-1">
                                                        <div className="col">
                                                            <div className="prerequisite-container">
                                                                <div className="checkbox-icon is-checked">Prerequisite</div>
                                                                {selectedCourse.prerequisites && <Dotdotdot clamp={1} className="prerequisite-abstract"><div dangerouslySetInnerHTML={{ __html: selectedCourse.prerequisites }}></div></Dotdotdot>}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&
                                                        <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                            <div className="d-flex cell mr-3">
                                                                <div className="date">
                                                                    {selectedCourse.beginDate &&
                                                                        <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>
                                                                    }
                                                                </div>
                                                                <div className="time">
                                                                    {selectedCourse.beginTime && selectedCourse.endTime &&
                                                                        <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {selectedCourse && selectedCourse.courseStatus !== "Past"
                                                        && selectedCourse.beginTime === null
                                                        && selectedCourse.cpabcCourseDeliveryMethod === "OD" &&
                                                        <div className="row pt-1">
                                                            <div className="col">
                                                                {selectedCourse.cpabcCourseProgram === "FREE" &&
                                                                    <div className="on-demande-container">Available on-demand. 60-day access from purchase date.</div>
                                                                }
                                                                {selectedCourse.cpabcCourseProgram !== "FREE" &&
                                                                    <div className="on-demande-container">Available on-demand. 180-day access from purchase date.</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {selectedCourse && selectedCourse.courseStatus === "Past" &&
                                                        <div className="row pt-1">
                                                            <div className="col">
                                                                <div className="on-demande-container">
                                                                    No upcoming dates based on your search criteria. Change your criteria, or click here to view all dates
                                                                                    </div>
                                                            </div>
                                                        </div>
                                                    }


                                                    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                                        {selectedCourse && selectedCourse.beginTime != null && selectedCourse.courseStatus != "Past" &&
                                                            <div className="delivery-method">
                                                                {selectedCourse.cpabcCourseDeliveryMethod &&
                                                                    <span className="label-black">{this.getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>
                                                                }
                                                            </div>
                                                        }
                                                        {selectedCourse && selectedCourse.courseStatus != "Past" &&
                                                            <div className="course-status">
                                                                <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>
                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            </a>
                                        }
                                    </div>
                                </Responsive>
                            }
                        </div>

                        {!searchResult.isLoading && searchResult.items.length === 0 && !courseFromHashVisible && !listBookmarksVisible &&
                            <span className="no-results pt-3">Sorry, no results found.</span>
                        }
                        {!searchResult.isLoading && searchResult.items.length !== 0 && !selectedCourse &&
                            <span className="course"></span>
                        }

                        {selectedCourse &&
                            <React.Fragment>
                                <Responsive displayIn={["Tablet", "Laptop"]}>
                                    <PDDetails
                                        selectedCourse={selectedCourse}
                                        pdDetails={pdDetails}
                                        itemsTestimonials={itemsTestimonials}
                                        selectedBookmark={selectedBookmark}
                                        getCourseSubjectDescription={this.getCourseSubjectDescription}
                                        onSelectTag={this.onSelectTag}
                                        getDeliveryMethod={this.getDeliveryMethod}
                                        coursePrograms={coursePrograms}
                                        seminarLevels={seminarLevels}
                                        availableButtonLink={availableButtonLink}
                                        cancelledButtonLink={cancelledButtonLink}
                                        closedButtonLink={closedButtonLink}
                                        fullButtonLink={fullButtonLink}
                                        pastButtonLink={pastButtonLink}
                                        availableButtonLabel={availableButtonLabel}
                                        cancelledButtonLabel={cancelledButtonLabel}
                                        closedButtonLabel={closedButtonLabel}
                                        fullButtonLabel={fullButtonLabel}
                                        pastButtonLabel={pastButtonLabel}
                                        leaders={leaders}
                                        locations={locations}
                                        tags={tags}
                                        pldpImage={pldpImage}
                                        pldpHoverText={pldpHoverText}
                                        bookmark={this.bookmark}
                                        isLoadingPdDetails={isLoadingPdDetails}
                                        isMobile={false}
                                    >
                                    </PDDetails>
                                </Responsive>

                                <Responsive displayIn={["Mobile"]}>
                                    <div className={"pd-details-mobile"} ref={this.headPdDetails}>
                                        {this.state.visiblePdDetails &&
                                            <React.Fragment>
                                                <div className="button-close">
                                                    <button className="btn btn-primary btn-close text-center" onClick={this.onClickButtonClosePdDetails()}></button>
                                                </div>
                                                <PDDetails
                                                    selectedCourse={selectedCourse}
                                                    pdDetails={pdDetails}
                                                    itemsTestimonials={itemsTestimonials}
                                                    selectedBookmark={selectedBookmark}
                                                    getCourseSubjectDescription={this.getCourseSubjectDescription}
                                                    onSelectTag={this.onSelectTag}
                                                    getDeliveryMethod={this.getDeliveryMethod}
                                                    coursePrograms={coursePrograms}
                                                    seminarLevels={seminarLevels}
                                                    availableButtonLink={availableButtonLink}
                                                    cancelledButtonLink={cancelledButtonLink}
                                                    closedButtonLink={closedButtonLink}
                                                    fullButtonLink={fullButtonLink}
                                                    pastButtonLink={pastButtonLink}
                                                    availableButtonLabel={availableButtonLabel}
                                                    cancelledButtonLabel={cancelledButtonLabel}
                                                    closedButtonLabel={closedButtonLabel}
                                                    fullButtonLabel={fullButtonLabel}
                                                    pastButtonLabel={pastButtonLabel}
                                                    leaders={leaders}
                                                    locations={locations}
                                                    tags={tags}
                                                    pldpImage={pldpImage}
                                                    pldpHoverText={pldpHoverText}
                                                    bookmark={this.bookmark}
                                                    isLoadingPdDetails={isLoadingPdDetails}
                                                    isMobile={true}
                                                >
                                                </PDDetails>
                                            </React.Fragment>
                                        }
                                    </div>
                                </Responsive>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
            
        </div>;
    }
}

SearchResult.propTypes = {
    searchPd: PropTypes.func.isRequired,
    getPdDetails: PropTypes.func.isRequired,
    searchResult: PropTypes.object.isRequired,
    selectedBookmark: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    cpabcMeeting: PropTypes.string.isRequired,
    cpabcCourseSubject: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    Description: PropTypes.string.isRequired,
    Code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cpdHours: PropTypes.number.isRequired,
    passportUnit: PropTypes.number.isRequired,
    beginDate: PropTypes.string.isRequired,
    beginTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    cpabcCourseDeliveryMethod: PropTypes.string.isRequired,
    overview: PropTypes.string.isRequired,
    specialAttribute: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    courseDescription: PropTypes.string.isRequired,
    attendees: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    specialNotes: PropTypes.string.isRequired,
    ethicHours: PropTypes.number.isRequired,
    cpabcCourseLevelTooltip: PropTypes.string.isRequired,
    memberPrice: PropTypes.number.isRequired,
    nonMemberPrice: PropTypes.number.isRequired,
    passportValid: PropTypes.bool.isRequired,
    cpabcCourseProgram: PropTypes.string.isRequired,
    availableDates: PropTypes.array.isRequired,
    courseStatus: PropTypes.string.isRequired,
    cpabcVenueId: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    requiredCaption: PropTypes.string.isRequired,
    sortBy: PropTypes.number.isRequired,
    masterCode: PropTypes.string.isRequired,
    speakerIds: PropTypes.array.isRequired,
    setAllSearchCriteria: PropTypes.func.isRequired,
    setSortByValue: PropTypes.func.isRequired,
    resetSearchResultItems: PropTypes.func.isRequired,
    isLoadingPdDetails: PropTypes.bool.isRequired,
    setIsLoadingPdDetails: PropTypes.func.isRequired,
    setCopySearchTagVisible: PropTypes.func.isRequired,
    setSearchTag: PropTypes.func.isRequired,
};

export default SearchResult;



//{
//    item.availableDates && item.availableDates[0].beginTime != null &&
//    <div className="d-flex flex-row align-items-center period mt-2 w-100">
//        <div className="d-flex cell mr-3">
//            <div className="date">
//                <span className="label-black">{getDatesFormat(item.beginDate, item.endDate)}</span>
//            </div>
//            <div className="time">
//                {item.beginTime && item.endTime &&
//                    <span className="label-black">{item.beginTime} - {item.endTime}</span>
//                }
//            </div>
//        </div>
//    </div>
//}

                                            //    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                            //        <div className="d-flex cell mr-3">
                                            //            <div className="date">
                                            //                {moment(selectedCourse.beginDate).format("MMM DD, YYYY") &&
                                            //                <span className="label-black">{getDatesFormat(selectedCourse.beginDate, selectedCourse.endDate)}</span>
                                            //                }
                                            //            </div>
                                            //            <div className="time">
                                            //                {selectedCourse.beginTime && selectedCourse.endTime &&
                                            //                    <span className="label-black">{selectedCourse.beginTime} - {selectedCourse.endTime}</span>
                                            //                }
                                            //            </div>
                                            //        </div>
                                            //    </div>
                                            //    <div className="d-flex flex-row align-items-center period mt-2 w-100">
                                            //        <div className="delivery-method">
                                            //        {selectedCourse.cpabcCourseDeliveryMethod &&
                                            //            <span className="label-black">{this.getDeliveryMethod(selectedCourse.cpabcCourseDeliveryMethod)}</span>
                                            //        }
                                            //        </div>
                                            //        <div className="course-status">
                                            //            <span className={"label-black label-status " + (selectedCourse.courseStatus ? selectedCourse.courseStatus.toLowerCase() : "")}>{selectedCourse.courseStatus}</span>
                                            //        </div>
                                            //</div>

