import React from 'react';

export default function SelectedLocations(props) {
    const { selectedLocations, onRemoveLocation} = props;

    return (
        <>
            {
                selectedLocations.length > 0 &&
                <div className="d-flex mr-4 mb-2 locations-filters">
                    <div className="option-select">
                        Locations: &nbsp;
                            {selectedLocations.length > 0 && selectedLocations.map((location, index) => {
                        return (
                            <div className="d-flex flex-row align-items-center">
                                <span>{location.City}</span>
                                <a href="javascript:void(0)">
                                    <span aria-hidden="true" onClick={onRemoveLocation(location, "City", "Location")}> &times;</span>
                                </a>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </>
    );
}
