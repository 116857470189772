import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function PassportCreditsDropdown(props) {
    const { selectedPassportCredits, onSelectAnyOrAll, onSelectItem, getActiveClassName, passportCredits } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-passport-credits">
            <DropdownToggle caret>
                {selectedPassportCredits.length === 0 &&
                    <span>Passport Credits</span>}
                {selectedPassportCredits.length > 0 && selectedPassportCredits
                    .sort((a, b) => a.Value - b.Value)
                    .map((item, index) => {
                        return <span>{(index ? ", " : "") + item.Text.toLowerCase()}</span>;
                    })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedPassportCredits, "Text", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("Text", "Value", "PassportCredits")}>Any or All</DropdownItem>
                    {passportCredits
                        .map((item) => {
                            let isActive = getActiveClassName(selectedPassportCredits, "Text", item.Text);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(item, "Text", "Value", "PassportCredits")}>
                                    {item.Text}
                                </DropdownItem>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
