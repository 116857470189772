import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';


const CarouselTestimonials = (props) => {
    const items = props.itemsTestimonials;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    function itemCaption(text){
        return (
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
        );
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                className="custom-tag"
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="carousel-caption">
                    {itemCaption(item.caption)}
                </div>
            </CarouselItem>
        );
    });

    return (
        <div className="testimonials-carousel">
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
            </Carousel>
        </div>
    );
}

export default CarouselTestimonials;


//<CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption} />
//                <CarouselCaption className="text-danger" captionText={item.caption} />

