//import "react-app-polyfill/ie11";
//import "react-app-polyfill/stable";

import * as jQuery from "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap";

import "./styles/flickity.css";
import "./styles/lity.css";
import "./styles/pdcalendar.css";
import "./sass/main.scss";

import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from "react-redux";

import SampleReduxComponent from "./components/SampleReduxComponent";
import FormComponent from "./components/formBuilder/formComponent";

import configureStore from "./redux/configureStore";
import SearchPdPage from "./components/search/pdSearch/SearchPdPage";
import SearchPdHomePage from "./components/search/pdHomePage/SearchPdHomePage";
import SearchPdCalendar from "./components/search/pdCalendar/SearchPdCalendar";
import NewsListing from "./components/news/NewsListing";
import SiteSearch from "./components/search/site/SiteSearch";
import LoadingIndicator from "./components/search/common/LoadingIndicator";

const store = configureStore();

global.jQuery = jQuery;
global.React = React;
global.ReactDOM = ReactDOM;

global.SampleReduxComponent = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><SampleReduxComponent {...props} /></ReduxProvider></Suspense>);
global.SearchPdPage = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><SearchPdPage {...props} /></ReduxProvider></Suspense>);
global.SearchPdHomePage = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><SearchPdHomePage {...props} /></ReduxProvider></Suspense>);
global.SearchPdCalendar = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><SearchPdCalendar {...props} /></ReduxProvider></Suspense>);
global.NewsListing = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><NewsListing {...props} /></ReduxProvider></Suspense>);
global.SiteSearch = (props) => (<Suspense fallback={<LoadingIndicator />}><ReduxProvider store={store}><SiteSearch {...props} /></ReduxProvider></Suspense>);
global.FormComponent = (props) => (<Suspense fallback={<LoadingIndicator />}><FormComponent {...props} /></Suspense>);

export default global;