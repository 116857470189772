import * as types from "../actions/actionTypes";
import initialState from "./initialState";


export default function filtersPdReducer(state = initialState.filtersSearch, action) {

    switch (action.type) {

        case types.SET_START_DATE:
            return {
                ...state,
                startDate: action.startDate
            };

        case types.SET_END_DATE:
            return {
                ...state,
                endDate: action.endDate
            };

        case types.SET_SELECTED_LOCATIONS:
            return {
                ...state,
                selectedLocations: action.selectedLocations
            };

        case types.SET_SELECTED_SUBJECTS:
            return {
                ...state,
                selectedSubjects: action.selectedSubjects
            };

        case types.SET_SELECTED_SEMINAR_LENGTH:
            return {
                ...state,
                selectedSeminarLength: action.selectedSeminarLength
            };

        case types.SET_SELECTED_DELIVERY_FORMATS:
            return {
                ...state,
                selectedDeliveryFormats: action.selectedDeliveryFormats
            };

        case types.SET_SELECTED_COURSE_PROGRAMS:
            return {
                ...state,
                selectedCoursePrograms: action.selectedCoursePrograms
            };

        case types.SET_SELECTED_ETHICS_HOURS:
            return {
                ...state,
                selectedEthicsHours: action.selectedEthicsHours
            };

        case types.SET_SELECTED_CERTIFICATE_PROGRAMS:
            return {
                ...state,
                selectedCertificatePrograms: action.selectedCertificatePrograms
            };

        case types.SET_SELECTED_LEADERS:
            return {
                ...state,
                selectedLeaders: action.selectedLeaders
            };

        case types.SET_SELECTED_PASSPORT_CREDITS:
            return {
                ...state,
                selectedPassportCredits: action.selectedPassportCredits
            };

        case types.SET_KEYWORDS_SEARCH_VALUE:
            return {
                ...state,
                keywordsSearchValue: action.keywordsSearchValue
            };

        case types.SELECT_COURSE:
            return {
                ...state,
                selectedCourse: action.selectedCourse
            };

        case types.SELECT_BOOKMARK:
            return {
                ...state,
                selectedBookmark: action.selectedBookmark
            };

        case types.SET_VISIBLE_LIST_BOOKMARKS:
            return {
                ...state,
                listBookmarksVisible: action.listBookmarksVisible
            };

        case types.SET_SORT_BY_VALUE:
            return {
                ...state,
                sortBy: action.sortBy
            };

        case types.SET_SEARCH_NEW_COURSES:
            return {
                ...state,
                newCourses: action.newCourses
            };

        case types.BEGIN_API_CALL_PD_DETAILS:
            return {
                ...state,
                pdDetails: state.pdDetails
            };


        case types.GET_DETAILS_PD_SUCCESS:
            return {
                ...state,
                pdDetails: action.pdDetails
            };

        case types.RESET_FILTERS_SEARCH:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                selectedLocations: action.selectedLocations,
                selectedSubjects: action.selectedSubjects,
                selectedSeminarLength: action.selectedSeminarLength,
                selectedDeliveryFormats: action.selectedDeliveryFormats,
                selectedCoursePrograms: action.selectedCoursePrograms,
                selectedEthicsHours: action.selectedEthicsHours,
                selectedCertificatePrograms: action.selectedCertificatePrograms,
                selectedLeaders: action.selectedLeaders,
                selectedPassportCredits: action.selectedPassportCredits,
                keywordsSearchValue: action.keywordsSearchValue,
                selectedCourse: action.selectedCourse,
                //selectedBookmark: action.selectedBookmark,
                listBookmarksVisible: action.listBookmarksVisible,
                pdDetails: action.pdDetails
            };

        case types.SET_ALL_SEARCH_CRITERIA:
            return {
                ...state,
                allSearchCriteria: action.allcriteria
            };

        case types.SET_COURSE_FROM_HASH_VISIBLE:
            return {
                ...state,
                courseFromHashVisible: action.courseFromHashVisible
            };

        case types.SET_NEW_COURSES_ONLY_VISIBLE:
            return {
                ...state,
                newCoursesOnlyVisible: action.newCoursesOnlyVisible
            };

        case types.SET_HASH_ALL_SEARCH_CRITERIA:
            return {
                ...state,
                hashAllSearchCriteria: action.hashAllSearchCriteria
            };

        case types.SET_IS_LOADING_PD_DETAILS:
            return {
                ...state,
                isLoadingPdDetails: action.isLoadingPdDetails
            };

        case types.SET_PD_DETAILS:
            return {
                ...state,
                pdDetails: action.pdDetails
            };

        case types.SET_COPY_SEARCH_TAG_VISIBLE:
            return {
                ...state,
                copySearchTagVisible: action.copySearchTagVisible
            };

        case types.SET_SEARCH_TAG:
            return {
                ...state,
                searchTag: action.searchTag
            };

        default:
            return state;
    }
}