import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function DeliveryFormatsDropdown(props) {
    const { selectedDeliveryFormats, onSelectAnyOrAll, onSelectItem, getActiveClassName, deliveryFormats } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-delivery-format">
            <DropdownToggle caret>
                {selectedDeliveryFormats.length === 0 &&
                    <span>Delivery format</span>}
                {selectedDeliveryFormats.length > 0 && selectedDeliveryFormats
                    .map((item, index) => {
                        return <span>{(index ? ', ' : '') + item.Description}</span>;
                    })}
            </DropdownToggle>
            <DropdownMenu>
                <div className="items">
                    <DropdownItem toggle={false}
                        className={(getActiveClassName(selectedDeliveryFormats, "Description", "Any or All") ? " active" : "")}
                        onClick={onSelectAnyOrAll("Description", "Code", "DeliveryFormats")}>Any or All</DropdownItem>
                    {deliveryFormats
                        .map((item) => {
                            let isActive = getActiveClassName(selectedDeliveryFormats, "Description", item.Description);
                            return (
                                <DropdownItem
                                    toggle={false}
                                    className={(isActive ? " active" : "")}
                                    onClick={onSelectItem(item, "Description", "Code", "DeliveryFormats")}>
                                    {item.Description}
                                </DropdownItem>
                            );
                        })
                    }
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
