import * as React from "react";
import { useReducer, useEffect } from "react";

import InfiniteScroll from 'react-infinite-scroller';

const initialState = {
    items: [],
    totalItemsCount: 0,
    isNoResults: false,
    isLoading: false,
    canLoadMore: false,
    searchFilter: undefined
}

const NewsListingItem = (props) => {
    return <div className="news-listing-item">
        {props.item.url && props.item.url.length > 0 && <a href={props.item.url} title={props.item.title} className="img-container float-right">
            <img src={props.item.thumbnail} alt={props.item.thumbnailAlt} className="img-fluid" />
        </a>}
        <div className="news-body">
            <a href={props.item.url} className="event-title" title={props.item.title}>{props.item.title}</a>
            <div className="summary" dangerouslySetInnerHTML={{ __html: props.item.summary }}></div>
            <div className="event-bottom" title={props.item.title}>
                <span className="date">{props.item.dateAsString}</span>
            </div>
        </div>

        <div className="clearfix"></div>
    </div>;
}

export default function NewsListingResult(props) {
    const [state, setState] = useReducer(
        (prevState, newState) => ({ ...prevState, ...newState }),
        initialState
    );

    const serviceCall = (sf, offset) => {
        const payload = { ...sf, offset, pageSize: 10 };

        return fetch("/api/news", {
            method: "post",
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(payload)
        }).then(res => res.json());
    };

    const search = () => {
        if (!props.searchFilter)
            return;

        setState({ isLoading: true, canLoadMore: false });

        serviceCall(props.searchFilter, 0)
            .then(x => {
                    setState({
                        items: x.items,
                        isNoResults: x.items.length === 0,
                        canLoadMore: x.totalItemsCount > x.items.length,
                        isLoading: false
                    });
                }
            );
    };

    const loadMore = () => {

        setState({ isLoading: true, canLoadMore: false });

        serviceCall(props.searchFilter, state.items.length)
            .then(x => {

                    const updatedItems = state.items.concat(x.items);

                    setState({
                        items: updatedItems,
                        canLoadMore: x.totalItemsCount > updatedItems.length,
                        isLoading: false
                    });
                }
            );
    }

    // update on new search filter
    useEffect(() => {
        search();
    }, [props.searchFilter]);

    return (<InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={state.canLoadMore && !state.isLoading}>
        {state.items.map(x => <NewsListingItem key={x.NodeId} item={x}/>)}
    </InfiniteScroll>);
}