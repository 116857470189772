import * as React from 'react';
import { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function GenericDropdown(props) {

    const itemByValue = (props.selectedValue != undefined)
        ? props.dataSource.find(x => props.selectedValue === props.getId(x))
        : undefined;

    const [selectedItem, setSelectedItem] = useState(itemByValue);

    useEffect(() => {

        if (props.selectedValue != undefined) {
            var itemByValue = props.dataSource.find(x => props.selectedValue === props.getId(x));
            setSelectedItem(itemByValue);

        } else {
            setSelectedItem(undefined);
        }

    },
        [props.selectedValue, props.dataSource]);

    return (<React.Fragment>
        <UncontrolledDropdown className={props.className} onBlur={props.onBlur}>
            <DropdownToggle caret>
                <span>{props.selectionPreDecoration}{selectedItem ? props.renderSelectedItem(selectedItem) : props.nullSelectionText}</span>
            </DropdownToggle>
            {
                props.dataSource != null && props.dataSource.length > 0
                    ? <DropdownMenu>
                        <div className="dropdown-menu-inner">
                            {props.allowNullSelection === true &&
                                <DropdownItem key="null-selection" onClick={x => {
                                    props.onSelectedItemChanged && props.onSelectedItemChanged(undefined);
                                    setSelectedItem(undefined);
                                }} className="clear-selection">
                                    {props.nullSelectionText || "Select"}
                                </DropdownItem>
                            }
                            {props.dataSource.map(
                                (item) => {
                                    if (props.customItemWrapperRender)
                                        return props.customItemWrapperRender(item, props.getId(item).toString(), props.renderItem(item));

                                    return <DropdownItem key={props.getId(item).toString()} onClick={x => {
                                        if (props.onSelectedItemChanged)
                                            props.onSelectedItemChanged(item);
                                        else
                                            setSelectedItem(item);
                                    }}>
                                        {props.renderItem(item)}
                                    </DropdownItem>;
                                })}
                        </div>
                    </DropdownMenu>
                    : null
            }
        </UncontrolledDropdown>
    </React.Fragment>);
}